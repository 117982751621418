import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';


@Injectable({providedIn:'root'})
export class DashboardHomeResolver implements Resolve<CreditReport> {

  constructor(private creditReportService: CreditReportService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.creditReportService.get();
  }
}
