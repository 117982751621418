import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadindicatorComponent} from './components/loadindicator/loadindicator.component';
import {LoadIndicatorInterceptor} from './interceptor/loadindicator.interceptor';
import {AuthJwtInterceptor} from './interceptor/auth-jwt.interceptor';
import {LogoutOnUnauthInterceptor} from './interceptor/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from './interceptor/server-error.interceptor';
import {AlertComponent} from './components/alert/alert.component';
import {ErrorComponent} from './components/error/error.component';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {AuthGuard} from './guards/auth.guard';
import {LoginGuard} from './guards/login.guard';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {MaterialModule} from '../lib/material/material.module';
import {LineChartComponent} from './components/line-chart/line-chart.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {PieChartComponent} from './components/pie-chart/pie-chart.component';
import {OrderModule} from 'ngx-order-pipe';
import {EllipsisPipe} from './pipes/EllipsisPipe';
import {ToastComponent} from './components/toasts/toast/toast.component';
import {DateTimeFormatPipe} from './pipes/DateTimeFormatPipe';
import {MomentModule} from 'ngx-moment';
import {RouterModule} from '@angular/router';
import {DateTimePickerComponent} from './components/form/date-time-picker/date-time-picker.component';
import {CallbackPipe} from 'src/app/core/pipes/CallbackPipe';


@NgModule({
    declarations: [
        AlertComponent,
        ConfirmationModalComponent,
        ErrorComponent,
        LoadindicatorComponent,
        LineChartComponent,
        PieChartComponent,
        EllipsisPipe,
        DateTimeFormatPipe,
        CallbackPipe,
        ToastComponent,
        DateTimePickerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        MomentModule,
        NgbModule,
        NgbModalModule,
        NgxWebstorageModule.forRoot(),
        MDBBootstrapModule,
        OrderModule,
        RouterModule
    ],
    providers: [
        AuthGuard,
        LoginGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadIndicatorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthJwtInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogoutOnUnauthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        }
    ],
    exports: [
        ConfirmationModalComponent,
        ErrorComponent,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        MomentModule,
        NgbModule,
        NgbModalModule,
        OrderModule,
        LineChartComponent,
        PieChartComponent,
        AlertComponent,
        LoadindicatorComponent,
        EllipsisPipe,
        DateTimeFormatPipe,
        CallbackPipe,
        ToastComponent,
        DateTimePickerComponent,
    ]
})
export class CoreModule {}
