<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>
        Credit Card Utilisation
      </h3>
      <div class="flex-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Summary</h5>
            <div class="spacer"></div>
            <div class="pie-background">
              <div class="pie-label">
                BALANCE USE
              </div>
              <div class="pie-dish">
                <pie-chart [data]="getData()" [labels]="getLabels()"></pie-chart>
              </div>
              <div class="pie-number">
                {{getPercent()}}%
              </div>
            </div>
            <div class="numbers">
              <div class="side">
                <div class="number-label">
                  TOTAL BALANCE
                </div>
                <div class="number">
                  {{summaryStats.revolvingDebtAmount | currency:'GBP':'symbol':'1.0-0'}}
                </div>
              </div>
              <div class="side">
                <div class="number-label">
                  TOTAL LIMIT
                </div>
                <div class="number">
                  {{summaryStats.creditLimit | currency:'GBP':'symbol':'1.0-0'}}
                </div>
              </div>
            </div>
            <div class="text-center">
              <a [routerLink]="['/credit-utilization']">View Details</a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">What is Credit Card Utilisation?</h5>
            <div class="spacer"></div>
            <p class="card-text">
              Credit card utilisation measures the amount of available credit you are using to your credit card limits.
              For example, if your total credit card balances are &pound;1,000 and your credit limit in total is &pound;10,000, then your credit utilisation is 10%.
              If your total balance is &pound;5,000 and your total credit limit is &pound;10,000, then your credit utilisation is 50%.
            </p>
            <p>
              <a [routerLink]="['/edu/content/credit-utilisation']">Read More &gt;&gt;</a>
            </p>
          </div>
        </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">
              Review How You Are Using Your Credit
            </h5>
            <p>
              Take a look at how you are trending in your use of credit over time.  Are you borrowing more?  Are you borrowing less?   How do your debts break down by category?
            </p>
            <p>
              <a [routerLink]="['/credit-utilization/home']">Borrowing Heath Details  &gt;&gt;</a>
            </p>
            <h5 class="card-title">
              Good Borrowing Behaviours
            </h5>
            <p>
              Good habits lead to good scores.
              Find ideas for good borrowing behaviours by continuing to read here.
            </p>
            <p>
              <a [routerLink]="['/edu/content/good-habits']">More on Good Habits  &gt;&gt;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
