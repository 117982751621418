export class JsonLoader {
  /**
   * Populate object from json response
   * @param json
   */
  fromJson(json: any) {
    const myObjectKeys = Object.keys(json);

    myObjectKeys.forEach((key: any) => {
      this[key] = json[key];
    });
  }
}
