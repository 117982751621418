import {Injectable} from '@angular/core';
import {ProductInterface} from '../models/product.interface';
import {Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import {SessionState} from 'src/app/shared/state/session/session.state';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  // private products = {
  //   '1': {
  //     'url': 'ukcreditratings.com',
  //     'name': 'UKCreditRatings',
  //     'site': 'UKCreditRatings.com',
  //     'logo': 'https:\/\/ukcreditratings.com\/img\/headers\/UKCR_LGO.png',
  //     'number': '0161 250 7700',
  //     'cs_email': 'info@ukcreditratings.com',
  //     'portal_url': 'https:\/\/member.ukcreditratings.com',
  //     'sms_sender_id': 'UKCredRate'
  //   }
  //   ,
  //   '2': {
  //     'url': 'myukcreditreport.co.uk',
  //     'name': 'MyUKCreditReport',
  //     'site': 'MyUKCreditReport.co.uk',
  //     'logo': 'https:\/\/myukcreditreport.co.uk\/img\/headers\/ADV_MUC_LGO_UKC1130_Blue_20181108.png',
  //     'number': '0161 250 3399',
  //     'cs_email': 'info@myukcreditreport.co.uk',
  //     'portal_url': 'https:\/\/member.myukcreditreport.co.uk',
  //     'sms_sender_id': 'MyUkCredit'
  //   }
  //   ,
  //   '3': {
  //     'url': 'studentcreditchecker.com',
  //     'name': 'StudentCreditChecker',
  //     'site': 'StudentCreditChecker.com',
  //     'logo': 'https:\/\/studentcreditchecker.co.uk\/img\/headers\/ADV_SCC_LGO_310x43_20181019.png',
  //     'number': '0161 250 3350',
  //     'cs_email': 'info@studentcreditchecker.com',
  //     'portal_url': 'https:\/\/member.studentcreditchecker.com',
  //     'sms_sender_id': 'StudentCred'
  //   }
  //   ,
  //   '4': {
  //     'url': 'creditscorepro.co.uk',
  //     'name': 'CreditScorePRO',
  //     'site': 'CreditScorePRO.co.uk',
  //     'logo': 'https:\/\/creditscorepro.co.uk\/img\/headers\/LGO_CSP_Orange.png',
  //     'number': '0161 250 3390',
  //     'cs_email': 'info@creditscorepro.co.uk',
  //     'portal_url': 'https:\/\/member.creditscorepro.co.uk',
  //     'sms_sender_id': 'CredScorPro'
  //   }
  // };

  constructor(private http: HttpClient, private store: Store) {

  }

  /**
   * Return products from state
   */
  get(): Observable<ProductInterface[]> {
    return this.store.select(SessionState.getProducts);
  }

  getById(productId: number): Observable<ProductInterface> {
    return this.store
      .select(SessionState.getProducts)
      .pipe(
        map(prods => prods[productId])
      );
  }

  /**
   * Hits server and grabs new products
   */
  fetch(): Observable<ProductInterface[]> {
    return this.http
      .get<ProductInterface[]>('/api/product')
      .pipe(
        share(),
        tap(
          products => {
            this.store.dispatch(new SessionActions.UpdateProducts(products));
          },
        ),
      );
  }
}
