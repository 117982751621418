import {Component, OnInit} from '@angular/core';
// import moment = require('moment');
import * as moment from 'moment';
import {BrandService} from '../../../services/brand.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public brandDomain = '';
  public csEmail = '';
  public currentYear: number = moment().year();

  constructor(
  ) { }

  ngOnInit() {
    this.brandDomain = BrandService.getDomain();
    this.csEmail = BrandService.getCsEmail();

  }

}
