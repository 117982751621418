import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AlertService} from 'src/app/core/components/alert/alert.service';
import {Observable} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class MustFinishResetPasswordGuard implements CanDeactivate<unknown> {
  constructor(private accountService: AccountService,
              private alertService: AlertService) {
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(sub => {
      // Check the account settings to see if forcePasswordReset is still set.  If not, don't
      // let us deactivate
      this.accountService.getAccount()
        .subscribe(acct => {
          if (acct) {
            // Show some kind of notice as to why they can't navigate
            if (acct.settings.forcePasswordReset) {
              this.alertService.error('Must complete password reset before continuing');
            }

            sub.next(!acct.settings.forcePasswordReset);
          } else {
            // The page this is on is protected by AuthGuard which means they should be logged in at this point.  However
            // some people are still getting a null acct object.  In that case, just let them go.
            sub.next(true);
          }

          sub.complete();
        });
    });
  }

}
