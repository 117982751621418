import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, share, tap} from 'rxjs/operators';
import {ReportHistroyInterface} from '../models/report-history.interface';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';


@Injectable({
  providedIn: 'root'
})
export class ReportHistoryService {

  protected reportHistory: Array<ReportHistroyInterface> = null;


  /**
   * Track if we are fetching already to prevent repeat calls to backend
   * @type {boolean}
   */
  protected isFetching = false;

  /**
   * Observable for current fetch
   */
  protected reportObservable: Observable<Array<ReportHistroyInterface>> = null;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    // Clear out local cache when we logout
    this.store.select(SessionState.getToken)
      .subscribe(token => {
        if (!token) {
          this.reportHistory = undefined;
        }
      });
  }

  /**
   * Get user from cache if available or fetch from server
   * @param force When TRUE, Force cache deletion and refetch from sever
   * @returns Observable<User>
   */
  public get(force: boolean = false): Observable<Array<ReportHistroyInterface>> {

    if (force === true) {
      this.reportHistory = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.reportHistory) {
      return of(this.reportHistory)
        .pipe(
          map(
            resp => resp as Array<ReportHistroyInterface>
          )
        );
    }

    return this.fetch();
  }


  /**
   * Fetch User Entity from server and cache locally
   * @returns {Observable<any>}
   */
  private fetch(): Observable<Array<ReportHistroyInterface>> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.reportObservable = this.http
        .get('/api/report/history')
        .pipe(
          share(),
          map(
            resp => resp as Array<ReportHistroyInterface>
          ),
          tap(
            reportHistory => {
              this.isFetching = false;
              this.reportHistory = reportHistory;
            }

          )
        );
    }

    return this.reportObservable;
  }

  public getReportHistory(){
    return this.reportHistory;
  }


}
