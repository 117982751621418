<app-alert></app-alert>

<app-loadindicator></app-loadindicator>

<!-- Header Top Bar -->
<div class="top-bar" id="top-bar">

  <a id="navbar-brand" class="navbar-brand ms-3 mt-3 float-start" [routerLink]="['/dashboard/home']" >
    <img class="mx-auto d-inline-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" alt="Portal Home" />
  </a>

  <button id="nav-icon" class="btn btn-sm btn-primary rounded-pill float-end mt-1 me-3 px-2" (click)="toggleSideNav()">
    <i class="fas fa-bars me-1"></i> MENU
  </button>

  <app-trial-countdown></app-trial-countdown>
</div>

<mat-sidenav-container class="ukc-sidenav">

  <!-- MENU -->
  <mat-sidenav #sidenav mode="side" position="end" (click)="toggleSideNav()" [fixedInViewport]="true" role="navigation">
    <app-nav></app-nav>
  </mat-sidenav>

  <!-- CONTENT -->
  <mat-sidenav-content (click)="closeSideNav()" role="main">

    <!-- Category Nav
    <div class="category-nav-container">
      <app-offer-nav-links></app-offer-nav-links>
    </div>
-->
    <!--<router-outlet name="header"></router-outlet>-->
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    <!--<router-outlet name="footer"></router-outlet>-->

    <app-footer></app-footer>

<!--    <app-secondary-nav></app-secondary-nav>-->

    <!--<app-toast-container></app-toast-container>-->

  </mat-sidenav-content>

</mat-sidenav-container>

<app-toast></app-toast>

<!--<app-smart-offers-button></app-smart-offers-button>-->
