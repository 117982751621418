<div *ngIf="employments.length > 0">
  <div [formGroup]="formGroup">
    <div formArrayName="employments">

      <div *ngFor="let emp of employments.controls; let i=index">
          <div [formGroupName]="i" class="employer-row py-1 mb-1">

            <div class="row mb-1" *ngIf="i>0>0">
              <div class="col-sm-12 p-1 d-flex justify-content-end">
                <div><button type="button" class="btn-sm btn-close clickable" aria-label="Close" ngbTooltip="Remove Employer" (click)="removeEmployer(i)"></button></div>
              </div>
            </div>

            <div class="row">
                <div class="col-sm-6 mb-2">
                  <!-- Employer -->
                  <input
                    aria-label="Employer"
                    type="text"
                    class="form-control form-control-sm position-static"
                    formControlName="employer"
                    id="employer"
                    placeholder="Employer Name"
                    [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i,'employer') }">
                  <div class="invalid-feedback" *ngIf="hasError(i,'employer', 'required')">Required</div>
                </div>

              <div class="col-sm-4 mb-2">
                <!-- Employment Status -->
                <select id="employmentStatus" class="form-control form-control-sm" formControlName="employmentStatus"
                        [ngClass]="{ 'is-invalid': errors.hasOwnProperty('employmentStatus') || (isSubmitted && isInvalid(i, 'employmentStatus'))}">
                  <option value="" disabled selected>Employment Status</option>
                  <option *ngFor="let employmentStatus of employmentStatuses; let i = index" [ngValue]="employmentStatus">
                    {{employmentStatus}}
                  </option>
                </select>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('employment')">{{errors?.employmentStatus}}</div>
              </div>

                <div class="col-sm-2 mb-2">
                  <!-- Employer Postcode-->
                  <input
                    aria-label="Post Code"
                    type="text"
                    class="form-control form-control-sm position-static"
                    formControlName="postCode"
                    id="postCode"
                    placeholder="Postcode"
                    [ngClass]="{ 'is-invalid': errors.hasOwnProperty('postCode') || isSubmitted && isInvalid(i,'postCode') }">
                  <div class="invalid-feedback" *ngIf="hasError(i,'postCode', 'required')">Required</div>
                </div>
            </div>


              <div class="row">
                <div class="col-sm-5 mb-2">
                  <!-- Job Title -->
                  <input
                    aria-label="Job Title"
                    type="text"
                    class="form-control form-control-sm position-static"
                    formControlName="jobTitle"
                    id="jobTitle"
                    placeholder="Job Title"
                    [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i,'jobTitle') }">
                  <div class="invalid-feedback" *ngIf="hasError(i,'jobTitle', 'required')">Required</div>
                </div>

                <div class="col-sm-2 mb-2">
                  <input
                    aria-label="Years"
                    type="number"
                    class="form-control form-control-sm position-static"
                    formControlName="timeAtEmployerYears"
                    id="timeAtEmployerYears"
                    placeholder="years"
                    [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i,'timeAtEmployerYears')}">
                  <div class="invalid-feedback" *ngIf="hasError(i,'timeAtEmployerYears', 'required')">Required</div>
                </div>

                <div class="col-sm-2 mb-2">
                  <input
                    aria-label="Years"
                    type="number"
                    class="form-control form-control-sm position-static"
                    formControlName="timeAtEmployerMonths"
                    id="timeAtEmployerMonths"
                    placeholder="months"
                    [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i,'timeAtEmployerMonths')}">
                  <div class="invalid-feedback" *ngIf="hasError(i,'timeAtEmployerMonths', 'required')">Required</div>
                </div>

                <div class="col-sm-3 mb-2">
                  <input
                    aria-label="Net Monthly Income"
                    type="number"
                    class="form-control form-control-sm position-static"
                    formControlName="netMonthlyIncome"
                    id="netMonthlyIncome"
                    placeholder="net monthly income"
                    [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i,'netMonthlyIncome') }">
                  <div class="invalid-feedback" *ngIf="hasError(i,'netMonthlyIncome', 'required')">Required</div>
                </div>
              </div>
          </div>
      </div>

      <!-- Add Employer -->
        <div class="row">
          <div class="col-12 d-flex justify-content-end">
            <p class="small clickable" (click)="addEmployer()"><i class="far fa-plus-square"></i> Add Employer</p>
          </div>
        </div>

    </div>
  </div>
</div>
