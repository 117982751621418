<div class="cloud-container">

  <div class="content">
    <div class="top-layer">
      <router-outlet></router-outlet>
<!--      <div class="line">-->
<!--        <img src="/assets/images/GRA_Airport.svg">-->
<!--      </div>-->
    </div>
    <div class="clouds"></div>

  </div>


</div>
<app-footer></app-footer>
<app-loadindicator></app-loadindicator>
<app-alert></app-alert>
<app-toast></app-toast>
