<div class="container">
  <div class="row">

    <div class="login-sec">

      <div class="pt-5 pb-5">
        <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
      </div>
      <h5>Please update your password</h5>
      <app-password (passwordUpdated)="passwordUpdated()"></app-password>
    </div>
  </div>
</div>
