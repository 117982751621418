import {Component, HostListener} from '@angular/core';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';
import {CalendlyService} from 'src/app/shared/services/calendly.service';
import {Observable} from 'rxjs';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';


@Component({
  selector: 'app-credit-consultation-box',
  templateUrl: './credit-consultation-box.component.html',
  styleUrls: ['./credit-consultation-box.component.scss'],
})
export class CreditConsultationBoxComponent {

  public event$: Observable<CalendlyEvent> = this.calendlyService.get();
  public hasEvent$: Observable<boolean> = this.calendlyService.hasEvent();
  public eventExists = false;

  constructor(
    private calendlyService: CalendlyService,
    private consultModalService: CreditConsultationIaoModalService
  ) {
    this.calendlyService.fetch().subscribe((calendlyEvent: CalendlyEvent) => {
        if (calendlyEvent) {
          this.eventExists = true;
        }
      }
    );
  }

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    if (!this.eventExists) {
      MixpanelService.track('Click Dashboard credit consultation');
      this.book();
    }
  }

  private book(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }
}
