import {Injectable} from '@angular/core';
import {ToastService} from '../components/toasts/toast.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

    constructor(private notificationService: ToastService) {
    }

    /**
     * Handle any http error that comes back from Server side
     * @param err
     */
    handleError(err: HttpErrorResponse) {
      if (err.error) {
        if (err.error.hasOwnProperty('errors')) {
          // Assume we have an array
          this.handleErrorArray(err);

        } else if (typeof err.error === 'object') {
          this.handleErrorObject(err.error);

        } else {
          // Assuming error is just a string
          this.notificationService.error(err.error);
        }
      }else{
        console.log(err);
      }
    }


    /**
     * Handle an error that is an object
     * @param error
     */
    handleErrorObject(error) {
        if (this.isApiError(error)) {
            this.notificationService.error('<strong>' + error.code + ' ' + error.title + '</strong>: ' + error.detail);
        } else if (error.hasOwnProperty('error')) {
            this.notificationService.error(error.error);
        }
        // This is what laravel comes through as with a super generic exception
        else if (error.hasOwnProperty('message')) {
          this.notificationService.error(error.message);

        } else if (error.hasOwnProperty('msg')) {
            this.notificationService.error(error.msg);
        }
    }

    /**
     * Handle array of errors
     * @param err
     */
    handleErrorArray(err) {
        err.error.errors.map(error => {
            // Are the errors an array of API Error Objects or an array of strings
            if (typeof error === 'string') {
                this.notificationService.error(error);
            } else {
                this.notificationService.error('<strong>' + error.code + ' ' + error.title + '</strong>: ' + error.detail);
            }
        });
    }

    /**
     * Returns true if error is our internal Api Error Object
     * @param error
     * @returns {boolean}
     */
    isApiError(error) {
        return error.hasOwnProperty('code') && error.hasOwnProperty('detail');
    }
}
