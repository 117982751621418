<div class="alert-container">
  <div class="container">
    <div class="alerts" role="alert">
      <div *ngFor="let alert of alerts" [ngClass]="{'alert.position': true}">
        <ngb-alert
          *ngIf="alert && alert.type && alert.msg"
          [type]="alert.type"
          (close)="alert.close(alerts)">
            <div [innerHTML]="alert.msg"></div>
        </ngb-alert>
      </div>
    </div>
  </div>
</div>
