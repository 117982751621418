import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastService} from '../../../../../core/components/toasts/toast.service';
import {AccountService} from '../../../../../shared/services/account.service';
import {JsonResponseMsg} from '../../../../../core/models/json-response-msg';
import {HttpErrorResponse} from '@angular/common/http';

/**
 * PasswordResetRequestComponent
 *
 * This is step 1 of the password reset process.  This is the form to request a password reset email be sent to a user
 */
@Component({
  selector: 'app-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['./password-reset-request.component.scss']
})
export class PasswordResetRequestComponent {

  public passwordResetForm: UntypedFormGroup;
  public isSubmitted = false;

  /**
   * Server form validation errors TODO: need to add some rules on the server side
   */
  public errors = {};

  constructor(
    private accountService: AccountService,
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastService
  ) {
    this.initializeForm();
  }

  /**
   * Create initial form object with validators
   */
  private initializeForm(): void {
    this.passwordResetForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
    });
  }

  sendPasswordResetRequest(): void {
    this.isSubmitted = true;

    if (this.passwordResetForm.dirty) {

      const request = {
        email: this.passwordResetForm.controls['email'].value
      };

      this.accountService
        .requestPasswordResetEmail(request)
        .subscribe({
          next: (resp: JsonResponseMsg) => {
            this.doGenericResponse();
          },
          error: (resp: HttpErrorResponse) => {
            // Rate limit request will be handled elsewhere
            if (resp.status === 429) {
              return;
            }

            this.doGenericResponse();
          },
        });
    }
  }

  /**
   * Provide a generic response message to the user regardless of success/fail
   */
  private doGenericResponse(): void {
    this.toastService.success(
      'If that address is valid, an email will be dispatched with further instructions',
      null,
      true,
      30000,
      true
    );
  }

  /**
   * getter for GUI access of validators
   */
  get formControls() {
    return this.passwordResetForm.controls;
  }
}
