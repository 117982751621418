import {Component, OnInit} from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {CreditReport} from 'src/app/shared//models/credit-report';

@Component({
  selector: 'app-summary-negative-marks',
  templateUrl: './summary-negative-marks.component.html',
  styleUrls: ['./summary-negative-marks.component.scss'],
})
export class SummaryNegativeMarksComponent implements OnInit {

  public negativeMarks = {
    missed_payments: 0,
    judgements: 0,
    insolvencies: 0
  };

  constructor(
    private reportService: CreditReportService,
  ) {
  }

  ngOnInit() {
    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.refreshStats();
      });
  }

  private refreshStats() {
    const marks = this.reportService.getNegativeMarks();

    for (let i = 0; i < marks.length; i++) {
      if (marks[i].type == 'missed_payment') {
        this.negativeMarks.missed_payments++;
      }
      if (marks[i].type == 'judgement') {
        this.negativeMarks.judgements++;
      }
      if (marks[i].type == 'insolvency') {
        this.negativeMarks.insolvencies++;
      }
    }
  }

}
