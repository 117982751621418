import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {DarkwebScan} from '../models/darkweb-scan';
import {DarkWebEmailInterface} from '../models/dark-web-email-interface';
import {Confidence, DarkwebBreach} from '../models/darkweb-breach';
import {Store} from '@ngxs/store';
import {DarkwebActions} from '../state/darkweb.actions';
import {DarkwebHit} from 'src/app/modules/dark-web/models/darkweb-hit';
import {DarkWebSearchCriterionInterface} from 'src/app/modules/dark-web/models/dark-web-search-criterion-interface';
import {SearchCriterionResponse} from 'src/app/modules/dark-web/models/search-criterion-response';
import {DarkwebState} from 'src/app/modules/dark-web/state/darkweb.state';
import {DarkWebSearchCriterionType} from 'src/app/modules/dark-web/models/DarkWebSearchCriterion.enum';
import {DarkwebSummary} from 'src/app/modules/dark-web/models/darkweb-summary';
import UpdateScan = DarkwebActions.UpdateScan;
import UpdateSummary = DarkwebActions.UpdateSummary;
import UpdateSearchCriterion = DarkwebActions.UpdateSearchCriterion;


@Injectable({
  providedIn: 'root'
})
export class DarkWebService {

  /**
   * Most recent update time of all emails monitored
   */
  public lastScanDate = new Date('1970-01-01 00:00:00');

  constructor(
    private http: HttpClient,
    private store: Store
  ) {}

  fetchScan(): Observable<DarkwebScan> {
    return this.http
      .get<DarkwebScan>('/api/dark-web')
      .pipe(
        tap(scan => {
          scan.hits = this.getHitsAsArray(scan);
          this.fixConfidenceValues(scan);
          this.store.dispatch(new UpdateScan(scan));
        })
      );
  }

  fetchSummary(): Observable<DarkwebSummary> {
    return this.http
      .get<DarkwebSummary>('/api/dark-web/summary')
      .pipe(
        tap(resp => {
          this.store.dispatch(new UpdateSummary(resp));
        })
      );
  }

  getSummary() {
    return this.store.select(DarkwebState.getSummary);
  }

  getSearchCriterion() {
    return this.store.select(DarkwebState.getCriterion);
  }

  fetchSearchCriterion(): Observable<SearchCriterionResponse> {
    return this.http
      .get<SearchCriterionResponse>('/api/dark-web/search-criterion')
      .pipe(
        tap(resp => {
          this.store.dispatch(new UpdateSearchCriterion(resp));
          this.findLatestScanDateForCriterion(resp.criterion);
        })
      );
  }


  fetchBreachDetail(sourceId: number): Observable<DarkwebBreach> {
    return this.http.get<DarkwebBreach>('/api/dark-web/breach/' + sourceId);
  }

  /**
   * Convert hits object to array
   */
  private getHitsAsArray(scan: DarkwebScan): DarkwebHit[] {
    const hits: DarkwebHit[] = [];

    if (scan && scan?.hits) {
      const keys = Object.keys(scan.hits);

      keys.forEach(k => {
        hits.push(scan.hits[k]);
      });
    }

    return hits;
  }

  /**
   * Seeing some confidence values as object and some as just numerical value
   *
   * confidence: {name: "UNVERIFIED", value: 3}
   * vs
   * confidence: 3
   */
  private fixConfidenceValues(scan: DarkwebScan): void {

    if (scan && scan?.breach_catalog) {
      scan.breach_catalog.forEach( breach => {
        if (typeof breach.confidence !== 'object') {
          breach.confidence = {
            name: Confidence[breach.confidence],
            value: breach.confidence
          };
        }
      });
    }
  }

  private findLatestScanDate(emails: DarkWebEmailInterface[]): void {
    emails.forEach(e => {
      if (e.last_scan) {
        const date = new Date(e.last_scan);

        if (date.getTime() > this.lastScanDate.getTime()) {
          this.lastScanDate = date;
        }
      }
    });
  }

  private findLatestScanDateForCriterion(criteria: DarkWebSearchCriterionInterface[]): void {
    criteria.forEach(c => {
      if (c.last_scan) {
        const date = new Date(c.last_scan);

        if (date.getTime() > this.lastScanDate.getTime()) {
          this.lastScanDate = date;
        }
      }
    });
  }

  sendVerificationEmail(email: string): Observable<any> {
    return this.http.post('/api/dark-web/send-email-verification', {email});
  }

  sendVerification(darkWebSearchCriterion: DarkWebSearchCriterionInterface): Observable<any> {
    let url = '/api/dark-web/send-email-verification';
    if (darkWebSearchCriterion.type  === DarkWebSearchCriterionType.PHONE) {
      url = '/api/dark-web/send-text-verification';
    }

    return this.http.post(url, darkWebSearchCriterion);
  }

  /**
   * Clicking on the link in the verification email should take you to a page with both email and
   * code.  Call this to then verify those params.
   */
  verifySearchCriterionAndCode(criterion: string, type: string, code: string): Observable<any> {
    return this.http.post('/api/dark-web/verify-criterion', { criterion, type, code });
  }

  toggleCriterionStatus(darkwebSearchCriterion: DarkWebSearchCriterionInterface): Observable<any> {
    return this.http.post('/api/dark-web/toggle-criterion-status', darkwebSearchCriterion);
  }
}
