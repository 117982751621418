import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';
import {GoCardlessComponent} from './go-cardless.component';
import {Store} from '@ngxs/store';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GoCardlessService implements Chainable {

  private account: IAccount;

  constructor(
    private accountService: AccountService,
    private store: Store,
    private modalService: NgbModal,
  ) {}

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService
        .getAccount()
        .pipe(take(1))
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return (this.account.settings.hasSeenOfferGoCardless !== true
      && this.account.user.proc_id !== 19);
  }

  run() {
    const modalRef = this.modalService.open(GoCardlessComponent, {size: 'md'});

    return modalRef.result;
  }

  onIaoClose() {
    this.accountService
      .updateSetting('hasSeenOfferGoCardless', true)
      .subscribe(() => {});
  }
}
