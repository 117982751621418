/**
 * Created by josephreymann on 08/14/19.
 */
import {JsonLoader} from './json-loader';

export class SummaryStats extends JsonLoader {
  public creditCardUtilization = 0;
  public creditLimit = 0;
  public negativeMarks = 0;
  public hardInquiries = 0;
  public paymentHistory = 0;
  public revolvingDebtAmount = 0;
  public score = 0;
  public totalAccounts = 0;
  public totalDebt = 0;
  public numberOfCreditors = 0;
}
