import {Component, OnDestroy} from '@angular/core';
import {CreditLadderComponent} from 'src/app/modules/offers/components/credit-ladder/credit-ladder.component';
import {ChangeCtaStatusRequest} from 'src/app/shared/models/cta/change-cta-status-request.interface';
import {CtaStatus} from 'src/app/shared/models/cta/cta-status.enum';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {CtaService} from 'src/app/shared/services/cta.service';
import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-nav-credit-ladder. []',
  templateUrl: './nav-credit-ladder.component.html',
  styleUrls: ['../../nav.component.scss'],
})
export class NavCreditLadderComponent implements OnDestroy {
  private readonly creditLadderCta = 39;

  cta: CTA = null;
  private ctaSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private ctaService: CtaService,
  ) {
    this.ctaSubscription = ctaService.getCta(this.creditLadderCta).subscribe({
      next: cta => this.cta = cta,
    });
  }

  /**
   * Opens the Credit Ladder CTA Offer Modal from main menu.
   * And thus we do CTA status change as well.
   */
  openModal(): void {
    const modalRef = this.modalService.open(CreditLadderComponent, {size: 'md'});

    // Record a CLICKED event on the CTA
    const changeCtaStatusRequest: ChangeCtaStatusRequest = {
      cta_users_id: this.cta.cta_users_id,
      status: CtaStatus.CLICKED,
    };

    this.ctaService
      .updateStatus(changeCtaStatusRequest)
      .subscribe(genericErrorObserver);
  }

  ngOnDestroy(): void {
    this.ctaSubscription.unsubscribe();
  }
}
