import {EventEmitter, Injectable, Output} from '@angular/core';
import {LoadIndicatorStatus} from './load-indicator-status.enum';

@Injectable({
  providedIn: 'root',
})
export class LoadIndicatorService {

  pList = [];
  @Output() pushPopEvent = new EventEmitter();

  constructor() { }

  push(procName) {
    // Only add if not in list
    if (this.pList.indexOf(procName) === -1) {
      this.pList.push(procName);
      this.notify();
    }
  }

  pop(procName) {
    const index = this.pList.indexOf(procName);

    if (index !== -1) {
      this.pList.splice(index, 1);
    }

    this.notify();
  }

  notify() {
    if (this.pList.length > 0) {
      this.pushPopEvent.emit(LoadIndicatorStatus.SHOW);
    } else {
      this.pushPopEvent.emit(LoadIndicatorStatus.HIDE);
    }
  }
}
