import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';
import {EventActions} from 'src/app/shared/state/calendly/events.actions';
import UpdateEvent = EventActions.UpdateEvent;
import ClearState = EventActions.ClearState;


export interface CalendlyEventStateModel {
  event: CalendlyEvent | null;
}

const stateDefaults: CalendlyEventStateModel = {
  event: null,
};


@State<CalendlyEventStateModel>({
  name: 'event',
  defaults: stateDefaults,
})


@Injectable()
export class EventState {

  @Selector([EventState])
  static getEvent(state: CalendlyEventStateModel): CalendlyEvent {
    return state.event;
  }

  @Selector([EventState])
  static hasEvent(state: CalendlyEventStateModel): boolean {
    return !!(state.event);
  }

  @Action(UpdateEvent)
  updateEvent(ctx: StateContext<CalendlyEventStateModel>, action: EventActions.UpdateEvent) {
    // Get existing state
    const state: CalendlyEventStateModel  = {...ctx.getState()};
    state.event = action.event;
    ctx.setState(state);
  }

  @Action(ClearState)
  clearState(ctx: StateContext<CalendlyEventStateModel >) {
    ctx.setState(stateDefaults);
  }
}
