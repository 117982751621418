import {Component, Input} from '@angular/core';
import {Rating} from 'src/app/shared/models/rating';

@Component({
  selector: 'app-credit-score-rating-bar',
  templateUrl: './credit-score-rating-bar.component.html',
  styleUrls: ['./credit-score-rating-bar.component.scss']
})
export class CreditScoreRatingBarComponent {

  @Input()
  public rating: Rating = {
    RatingValue: 0,
    Score: 0
  };


  constructor() {
  }

}
