export const environment = {
  production: false,
  staging: true,
  backendUrl: 'https://api.stage.member.ukcreditratings.com',
  checkoutUrl: 'https://checkout.ukcreditratings.com',

  // This is set during the build process, using replace-in-file package.  See pipelines for usage.
  release: '{BUILD_VERSION}',

  mixpanelKey: '',
};
