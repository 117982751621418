<div class="article">
  <div style="display: none">
    <button ngbAutofocus>this keeps it from scrolling to the bottom</button>
  </div>

  <h4 id="top" class="mt-5 text-center">
    You're Almost There!
  </h4>

  <p>
    We have matched you to your credit profile, but you don't currently have enough of a credit history to generate a full file. You are now
    in thin file status. We're here to help! <br>
    <br>
    You may have some questions.
  </p>
  <h5 class="mt-5">
    What exactly does it mean to have a "thin file"?
  </h5>
  <p>
    You likely have never taken out any loans or credit cards before and have never had to pay off any debt.
    You could have a thin file if you:
  </p>
  <ul>
    <li>Are young and new to the credit world</li>
    <li>Are a new immigrant who hasn't established a credit history in the UK yet</li>
    <li>Haven't used credit in a long time</li>
    <li>Have very few credit accounts that you haven't kept active</li>
    <li>Have been recently widowed or divorced</li>
    <li>Avoid using credit and mainly use cash</li>
  </ul>
  <h5>Why don't I have any credit history?</h5>
  <p>
    You have to make a financial footprint in order to start building credit history. When you take out loans, credit cards, cell
    phone contracts and more, you start building a trackable, financial footprint.
  </p>
  <h5>Why do I need credit history?</h5>
  <p>
    Lenders will look at your credit history to determine if you're a good candidate. They will often favor someone with a history of
    managing their credit well vs someone who has never borrowed before. There are some simple ways you can start building credit
    history that don't even involve taking out any debts! <br>
    <br>
    We'll coach you through a checklist of items you can complete to start your credit journey.
  </p>

  <p>
    If you have any questions please call our <strong>Customer Services Team</strong> for free on
    <a target="_blank" href="tel:{{productPhone}}">{{ productPhone }}</a>,
    or
    email us at <a target="_blank" href="mailto:{{productEmail}}">{{productEmail}}</a>. Opening hours are <strong>{{officeHours}}</strong>.
  </p>
  <div class="text-center">
    <button class="btn btn-primary btn-lg" (click)="close()">
      Go to Home
    </button>
  </div>
</div>
