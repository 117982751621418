import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {IAccount} from 'src/app/shared//models/account';

@Component({
  selector: 'app-thin-file',
  templateUrl: './thin-file.component.html',
  styleUrls: ['./thin-file.component.scss']
})
export class ThinFileComponent implements OnInit, OnDestroy {

  public productName = '';
  public officeHours = '';
  public productPhone = '';
  public productEmail = '';
  private selectAccount: Subscription;

  constructor(private activeModal: NgbActiveModal,
              private store: Store) {
  }

  ngOnInit(): void {
    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((resp: IAccount) => {
        this.officeHours = resp.office.hours;
        this.productName = resp.product.name;
        this.productPhone = resp.product.number;
        this.productEmail = resp.product.cs_email;
      });
  }

  close(): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
