import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CreditReportService} from '../services/credit-report.service';

/**
 * This should return true for active accounts, and false for manual verify & thin file accounts
 */

@Injectable({
  providedIn: 'root'
})
export class HasCreditReportGuard implements CanActivate, CanLoad {

  constructor(
    private creditReportService: CreditReportService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(sub => {
      this.creditReportService
        .get()
        .subscribe(report => {
          sub.next(!report.isEmpty());
          sub.complete();
        });
    });
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    return new Observable<boolean>(sub => {
      this.creditReportService
        .get()
        .subscribe(report => {
          sub.next(!report.isEmpty());
          sub.complete();
        });
    });
  }
}
