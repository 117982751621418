import {Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {Store} from '@ngxs/store';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';

@Injectable()
export class SentryErrorHandler {

  static navigationDataFilters = {
    'auth/google': '[auth/google]',       // /auth/google?code={google-auth-code}
    'auth/store': '[auth/store/{token}]', // /auth/store/{customer-jwt}
    'dark-web-verify-email': '[dark-web-verify-email]'  // /dark-web-verify-email?email={customer-email}&code={verify-code}
  };

  constructor(
    //private store: Store,
    private accountService: AccountService
  ) {
    // if (environment.production || environment.staging) {
      this.configureScope();
    // }
  }

  /**
   * If available, make sure sentry has the account details
   * @private
   */
  private configureScope(): void {
    this.accountService.getAccount()
      .subscribe((account: IAccount) => {

        Sentry.configureScope(scope => {
          if (account) {
            scope.setUser({
              id: account.user.guid,
              email: account.user.email,
            });
          } else {
            // scope.clear() ?
            scope.setUser({});
          }
        });

      });
  }
}
