import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SummaryStats} from 'src/app/shared/models/summary-stats';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {CalendlyService} from 'src/app/shared/services/calendly.service';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';
import {Observable, Subscription} from 'rxjs';
import {EventState} from 'src/app/shared/state/calendly/events.state';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {IAccount} from 'src/app/shared/models/account';
import {UserStatus} from 'src/app/shared/models/user-status';
import {Router} from '@angular/router';

@Component({
  selector: 'app-car-giant',
  templateUrl: './car-giant.component.html',
  styleUrls: ['./car-giant.component.scss']
})
export class CarGiantComponent implements OnInit, OnDestroy {

  @ViewChild('accepted', {static: true}) acceptedTemplate: TemplateRef<any>;
  @ViewChild('declined', {static: true}) declinedTemplate: TemplateRef<any>;
  @ViewChild('noscoremv', {static: true}) noscoreMvTemplate: TemplateRef<any>;
  @ViewChild('noscorebe', {static: true}) noscoreBeTemplate: TemplateRef<any>;
  @ViewChild('noscorethin', {static: true}) noscoreThinTemplate: TemplateRef<any>;
  @ViewChild('noscoreother', {static: true}) noscoreOtherTemplate: TemplateRef<any>;

  public templateName: TemplateRef<any>;

  public summaryStats: SummaryStats;

  public event$: Observable<CalendlyEvent> = this.store.select(EventState.getEvent);
  public hasEvent$: Observable<boolean> = this.store.select(EventState.hasEvent);

  private selectAccount: Subscription;
  public status: string;


  constructor(
    private router: Router,
    private reportService: CreditReportService,
    public activeModal: NgbActiveModal,
    private store: Store,
    private consultModalService: CreditConsultationIaoModalService
  ) {}

  ngOnInit(): void {
    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((resp: IAccount) => {
        this.status = resp.user.status;
        this.setTemplate();
      });
  }

  private setTemplate() {
    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.summaryStats = this.reportService.getSummaryStats();
        // Set Template based on score
        if (this.summaryStats.score >= 300) {
          this.templateName = this.acceptedTemplate;
        }else if (this.summaryStats.score >= 300) {
          this.templateName = this.declinedTemplate;
        }else{
          // Messaging for MV, BE, and Thin
          if (this.status === UserStatus.Manual) {
            this.templateName = this.noscoreMvTemplate;
          } else if (this.status === UserStatus.BrokenEnrollment) {
            this.templateName = this.noscoreBeTemplate;
          } else if (this.status === UserStatus.ThinFile) {
            this.templateName = this.noscoreThinTemplate;
          } else {
            this.templateName = this.noscoreOtherTemplate;
          }
        }
      });
  }

  navigate(to: string) {
    this.close();
    this.router.navigate([to]);
  }

  close() {
    this.activeModal.close();
  }

  public book(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
