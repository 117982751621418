import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  public hasHeader = true;
  public yesLabel = 'Yes';
  public noLabel = 'No';
  public title: string;
  public message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }

  yes() {
    this.activeModal.close(true);
  }

  no() {
    this.activeModal.dismiss();
  }
}
