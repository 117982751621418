import { Component, Input } from '@angular/core';

@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent {
  public chartType = 'pie';

  public chartDatasets: Array<any> = [
    { data: [0, 0], label: 'hotdogs' }
  ];

  public chartLabels: Array<any> = ['Utilised', 'Available'];

  @Input()
  set data(data: Array<any>) {
    this.chartDatasets = data;
  }
  @Input()
  set labels(labels: Array<any>) {
    // this.chartLabels = labels;
  }



  public chartColors: Array<any> = [
    {
      backgroundColor: [ '#00CCFF', '#FFFFFF', '#FF0000'],
      borderColor: ['#000000', '#000000', '#000000'],
      borderWidth: 2,
    }

  ];

  public chartOptions: any = {
    responsive: true,
    legend : {
      display : false
    },
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
}
