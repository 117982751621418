<p *ngIf="!risks">Dark Web Scan not complete or no search criterion are active.</p>

<div class="risk-container" [class.limit-to-6]="limit6" *ngIf="risks">

  <!-- Username -->
  <div class="risk" *ngIf="canShow(risks.usernames)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskLogin" [popoverTitle]="popTitleRiskLogin" container="body">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
            <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"/>
          </svg>
        </div>
        <div><span  class="badge" [ngClass]="risks.usernames > 0 ? 'badge-alert' : 'badge-normal'">{{risks.usernames}}</span></div>
        <label>Usernames</label>
    </div>
  </div>

  <ng-template #popContentRiskLogin>
    Breached usernames and passwords allow hackers to gain access to your accounts.  Not just the account that was hacked, but possibly other accounts you may have you used the same password. Please see the Password Help section below.
  </ng-template>
  <ng-template  #popTitleRiskLogin><span class="help-title">Exposed Login Credentials</span></ng-template>


  <!-- Passwords -->
  <div  class="risk" *ngIf="canShow(risks.passwords)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskLogin" [popoverTitle]="popTitleRiskLogin" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-key" viewBox="0 0 16 16">
          <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
          <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.passwords > 0 ? 'badge-alert' : 'badge-normal'">{{risks.passwords}}</span></div>
      <label>Passwords</label>
    </div>
  </div>


  <!-- Emails -->
  <div class="risk" *ngIf="canShow(risks.emails)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskEmails" [popoverTitle]="popTitleRiskEmails" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
          <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
        </svg>
      </div>
      <div><span class="badge" [ngClass]="risks.emails > 0 ? 'badge-alert' : 'badge-normal'">{{risks.emails}}</span></div>
      <label>Emails</label>
    </div>
  </div>

  <ng-template #popContentRiskEmails>
    Knowing your email address, a hacker could target you with phishing emails, spam and fraudulent activities.  They could attempt to access online accounts, steal your idenity and/or impersonate you to scam other contacts.
  </ng-template>
  <ng-template  #popTitleRiskEmails><span class="help-title">Exposed Emails</span></ng-template>


  <!-- IP Addresses -->
  <div class="risk" *ngIf="canShow(risks.ips)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskIps" [popoverTitle]="popTitleRiskIps" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-router" viewBox="0 0 16 16">
          <path d="M5.525 3.025a3.5 3.5 0 0 1 4.95 0 .5.5 0 1 0 .707-.707 4.5 4.5 0 0 0-6.364 0 .5.5 0 0 0 .707.707Z"/>
          <path d="M6.94 4.44a1.5 1.5 0 0 1 2.12 0 .5.5 0 0 0 .708-.708 2.5 2.5 0 0 0-3.536 0 .5.5 0 0 0 .707.707ZM2.5 11a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm4.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2.5.5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1.5-.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Z"/>
          <path d="M2.974 2.342a.5.5 0 1 0-.948.316L3.806 8H1.5A1.5 1.5 0 0 0 0 9.5v2A1.5 1.5 0 0 0 1.5 13H2a.5.5 0 0 0 .5.5h2A.5.5 0 0 0 5 13h6a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5h.5a1.5 1.5 0 0 0 1.5-1.5v-2A1.5 1.5 0 0 0 14.5 8h-2.306l1.78-5.342a.5.5 0 1 0-.948-.316L11.14 8H4.86L2.974 2.342ZM14.5 9a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h13Z"/>
          <path d="M8.5 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/>
        </svg>
      </div>
      <div><span class="badge" [ngClass]="risks.ips > 0 ? 'badge-alert' : 'badge-normal'">{{risks.ips}}</span></div>
      <div><label>IP Addresses</label></div>
    </div>
  </div>

  <ng-template #popContentRiskIps>
    A hacker can derive your geographic location from your IP, determine who owns and operates the network, discover additional resources on the network and potentially reveal your activities/online history which could reveal additional information about you.
  </ng-template>
  <ng-template  #popTitleRiskIps><span class="help-title">Exposed IP Addresses</span></ng-template>


  <!-- Phone Numbers -->
  <div class="risk" *ngIf="canShow(risks.phone_numbers)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskPhone" [popoverTitle]="popTitleRiskPhone" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.phone_numbers > 0 ? 'badge-alert' : 'badge-normal'">{{risks.phone_numbers}}</span></div>
      <label>Phone Numbers</label>
    </div>
  </div>

  <ng-template #popContentRiskPhone>
    Similarly to being targeted by email, hackers can target your phone via text messages that impersonate legitimate organisations or persons to trick you into giving up personal details or into becoming a victim of their fraudulent schemes.
  </ng-template>
  <ng-template  #popTitleRiskPhone><span class="help-title">Exposed Phone Numbers</span></ng-template>


  <!-- Infected User -->
  <div class="risk" *ngIf="canShow(risks.infected_user)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskInfectedSys" [popoverTitle]="popTitleRiskInfectedSys" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-pc-display" viewBox="0 0 16 16">
          <path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z"/>
        </svg>
      </div>
      <div><span class="badge" [ngClass]="risks.infected_user > 0 ? 'badge-alert' : 'badge-normal'">{{risks.infected_user}}</span></div>
      <label>Infected System</label>
    </div>
  </div>

  <ng-template #popContentRiskInfectedSys>
    If a breach is related to a system infected with malicious software, additional details may appear in the breach records below about the infected system. Malware can harvest user credentials, cookies, browser fingerprint data to hijack a session, access other systems, and launch attacks.
  </ng-template>
  <ng-template  #popTitleRiskInfectedSys><span class="help-title">Infected User/System</span></ng-template>


  <!-- Account -->
  <div class="risk" *ngIf="canShow(risks.account)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskAccounts" [popoverTitle]="popTitleRiskAccounts" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.account > 0 ? 'badge-alert' : 'badge-normal'">{{risks.account}}</span></div>
      <label>Accounts</label>
    </div>
  </div>

  <ng-template #popContentRiskAccounts>
    Breached account data varies by account and the specific breach but may include name, address, phone, email, date of birth, account signup dates, NIS number, answers to specific questions related to the site which could be names of family memebers, pets, information about your residence and time you've lived there, political affiliation, vehicle information and so on.
  </ng-template>
  <ng-template  #popTitleRiskAccounts><span class="help-title">Exposed Account Information</span></ng-template>

  <!-- Company -->
  <div class="risk" *ngIf="canShow(risks.company)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskCompany" [popoverTitle]="popTitleRiskCompany" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
          <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
          <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.company > 0 ? 'badge-alert' : 'badge-normal'">{{risks.company}}</span></div>
      <label>Company</label>
    </div>
  </div>

  <ng-template #popContentRiskCompany>
    Breached employee data may include info about a users workplace, job, income, etc.  This information can make it easier for criminals to impersonate you.
  </ng-template>
  <ng-template  #popTitleRiskCompany><span class="help-title">Exposed Company Information</span></ng-template>

  <!-- Financial Info -->
  <div class="risk" *ngIf="canShow(risks.financial_info)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskFinancial" [popoverTitle]="popTitleRiskFinancial" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
          <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.financial_info > 0 ? 'badge-alert' : 'badge-normal'">{{risks.financial_info}}</span></div>
      <label>Financial Info</label>
    </div>
  </div>

  <ng-template #popContentRiskFinancial>
    Breached financial data may include bank account, credit card information and tax identification.  Review your accounts periodically for suspicious transactions.
  </ng-template>
  <ng-template  #popTitleRiskFinancial><span class="help-title">Exposed Financial Information</span></ng-template>


  <!-- Geolocation -->
  <div class="risk" *ngIf="canShow(risks.geolocation)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskGeolocation" [popoverTitle]="popTitleRiskGeolocation" container="body" popoverClass="dw-popover">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.geolocation > 0 ? 'badge-alert' : 'badge-normal'">{{risks.geolocation}}</span></div>
      <label>Geolocation</label>
    </div>
  </div>

  <ng-template #popContentRiskGeolocation>
    Breached data that reveals anything about your location may help criminals in targeting you or others.  Location also may help criminals avoid legal action by specifically targeting victims in other countries.
  </ng-template>
  <ng-template  #popTitleRiskGeolocation><span class="help-title">Exposed Geolocation Information</span></ng-template>


  <!-- PII -->
  <div class="risk" *ngIf="canShow(risks.pii)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskPii" [popoverTitle]="popTitleRiskPii" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
          <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z"/>
          <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.pii > 0 ? 'badge-alert' : 'badge-normal'">{{risks.pii}}</span></div>
      <label>Personal Information</label>
    </div>
  </div>

  <ng-template #popContentRiskPii>
    Breached data may include data that reveals personal details about you.  This could be name, address, phone, email, your interests, religious and political affiliations, home and family, etc.
  </ng-template>
  <ng-template  #popTitleRiskPii><span class="help-title">Exposed Personal Information</span></ng-template>


  <!-- Social Account -->
  <div class="risk" *ngIf="canShow(risks.social)">
    <div class="risk-item clickable" [ngbPopover]="popContentRiskSocial" [popoverTitle]="popTitleRiskSocial" container="body">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
          <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
        </svg>
      </div>
      <div><span  class="badge" [ngClass]="risks.social > 0 ? 'badge-alert' : 'badge-normal'">{{risks.social}}</span></div>
      <label>Social Accounts</label>
    </div>
  </div>

  <ng-template #popContentRiskSocial>
    Breached social account identities may help criminals impersonate you and target other contacts.
  </ng-template>
  <ng-template  #popTitleRiskSocial><span class="help-title">Exposed Social Information</span></ng-template>
</div>
