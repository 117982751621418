import { Component, Input } from '@angular/core';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent {
  public chartType = 'line';

  public chartDatasets: Array<any> = [
    { data: [0,0,0,0] }
  ];
  public chartLabels: Array<any> = ['January', 'February', 'March', 'April'];

  @Input()
  set data(data: Array<any>) {
    this.chartDatasets = data;
  }

  @Input()
  set labels(labels: Array<any>) {
    this.chartLabels = labels;
  }

  @Input()
  set options(options: Object) {
    this.chartOptions = options;
  }

  public chartColors: Array<any> = [
    {
      backgroundColor: 'rgba(0,204,255, .2)',
      borderColor: 'rgba(0,204,255, .7)',
      borderWidth: 2,
    },

  ];

  // defaults
  public chartOptions: any = {
    responsive: true,
    legend : {
      display : false
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    },
    hover: {
      animationDuration: 0 // duration of animations when hovering an item
    },
    scales: {
      yAxes: [{
        reverse:true,
        ticks: {
        }
      }],
      xAxes: [{
        reverse:false,
        type: 'time',
        distribution: 'linear'
      }]
    }

  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
}
