import {Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {SmartOfferService} from 'src/app/shared/services/smart-offer-service';
import {Subscription} from 'rxjs';
import {CtaCategory} from 'src/app/shared/models/cta/cta-category.enum';
import {CtaCategoryService} from 'src/app/shared/services/cta-category.service';
import {CtaCategoryInterface} from 'src/app/shared/models/cta/cta-category.interface';


@Component({
  selector: 'app-offer-category-ctas',
  templateUrl: './offer-category-ctas.component.html',
  styleUrls: ['./offer-category-ctas.component.scss'],
})
export class OfferCategoryCtasComponent implements OnDestroy {

  public categories: Array<CtaCategoryInterface> = [];

  private readonly ctaCategorySubscription: Subscription;

  // Slider properties
  @ViewChild('window') container: ElementRef;
  private currentWindowWidth = 480;
  private iconsToDisplay = 4;
  private numberOfSlides = 1;
  private currentSlide = 1;
  public start = 0;
  public end = 4;
  private minCatItemWidth = 100;  // should match CSS .cat-item width

  constructor(
    public smartOfferService: SmartOfferService,
    private ctaCategoryService: CtaCategoryService,
  ) {
    // Offer Categories are a count of the subset of CTA's that are offers.  So they rely on CTA processing to be complete
    this.ctaCategorySubscription = this.ctaCategoryService.get().subscribe({
      next: cats => {
        this.categories = cats;
        this.resetIconDisplay();
      },
    });
  }

  /**
   * Watch for browser resizing and reset Icon Display Width
   */
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.resetIconDisplay();
  }

  /**
   * Figures out slide container width and how many icons can fit per slide
   */
  private resetIconDisplay(): void {
    if (this.hasCategories() && this.container && this.container.nativeElement) {
      this.currentWindowWidth = this.container.nativeElement.offsetWidth;
      this.iconsToDisplay = Math.floor(this.currentWindowWidth / this.minCatItemWidth);
      this.numberOfSlides = Math.ceil(this.categories.length / this.iconsToDisplay);

      this.start = 0;
      if (this.iconsToDisplay < this.categories.length) {
        this.end = this.iconsToDisplay;
      } else {
        this.end = this.categories.length;
      }
    }
  }

  hasCategories(): boolean {
    return (this.categories && this.categories.length > 1);
  }

  /**
   * View previous slide or circle around to last slide if already on first slide.
   */
  navLeft(): void {
    this.currentSlide = (this.currentSlide > 1) ? this.currentSlide - 1 : this.numberOfSlides;
    this.setSliderRange();
  }

  /**
   * View next slide or circle around to first slide if already on last slide.
   */
  navRight(): void {
    this.currentSlide = (this.currentSlide < this.numberOfSlides) ? this.currentSlide + 1 : 1;
    this.setSliderRange();
  }

  /**
   * Set display range of sliders ngFor loop
   */
  setSliderRange(): void {
    this.start = (this.currentSlide - 1) * this.iconsToDisplay;
    this.end = this.currentSlide * this.iconsToDisplay;
  }

  /**
   * Hide Nav Arrows if there is only one slide
   */
  canDisplayNav(): boolean {
    return this.numberOfSlides > 1;
  }

  ngOnDestroy(): void {
    if (this.ctaCategorySubscription) {
      this.ctaCategorySubscription.unsubscribe();
    }
  }

  /**
   * Open smart offer modal filtered to the selected category
   */
  async openSmartOffers(ctaCategory: CtaCategory) {
    MixpanelService.track('SecondaryNav - Click smart offers');

    await this.smartOfferService.open(ctaCategory);
  }
}
