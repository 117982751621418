import {Component, HostListener, OnDestroy} from '@angular/core';
import {CtaActionService} from '../../services/cta-action.service';
import {Subscription} from 'rxjs';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {AccountService} from 'src/app/shared/services/account.service';


@Component({
  selector: 'app-fast-perks-status',
  templateUrl: './fast-perks-status.component.html',
  styleUrls: ['./fast-perks-status.component.scss'],
})
export class FastPerksStatusComponent implements OnDestroy{

  public fastPerksUrl = '';
  public isActive = false;

  private accountSub: Subscription;

  @HostListener('click', ['$event.target'])
  onClick(btn) {
    MixpanelService.track('Click Dashboard Fast perks box');
    window.open(this.fastPerksUrl, '_blank');
  }

  constructor(
    accountService: AccountService,
    ctaActionService: CtaActionService,
  ) {
    this.accountSub = accountService.getAccount().subscribe(acct => {
      this.fastPerksUrl = ctaActionService.getAffiliateLink(
        'https://<product_name>.fast-perks.com/signin-auto?emailaddress=<email>',
        acct
      );

      this.isActive = acct.settings.hasConsentedSmartOffers;
    });
  }

  ngOnDestroy(): void {
    this.accountSub.unsubscribe();
  }
}
