import {CreditReport} from 'src/app/shared/models/credit-report';
import {CreditAlertModel} from 'src/app/modules/alerts/models/credit-alert.model';

export class UpdateReport {
  static readonly type = '[CreditReport] Update';

  constructor(public readonly report: CreditReport) {
  }
}

export class UpdateCreditAlerts {
  static readonly type = '[CreditReport] Update Alerts';

  constructor(public readonly alerts: CreditAlertModel[]) {
  }
}
