import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

/**
 * Remembers the last visited route.  Typically set on logout, or route guards
 * and used on login or after guard is satisfied to take user to where they were
 * originally trying to go.
 */
@Injectable({
  providedIn: 'root',
})
export class HistoryService {

  constructor(private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService) {
  }

  clearLastVisitedRoute(): void {
    this.$localStorage.clear('lastVisitedRoute');
    this.$sessionStorage.clear('lastVisitedRoute');
  }

  getLastVisitedRoute(): string|null {
    // Using local storage means they could end up on a page they were heading to days ago.  I feel session storage is the way to go.
    // return this.$localStorage.retrieve('lastVisitedRoute') || this.$sessionStorage.retrieve('lastVisitedRoute');
    return this.$sessionStorage.retrieve('lastVisitedRoute');
  }

  consumeLastVisitedRoute() {
    const url = this.getLastVisitedRoute();
    this.clearLastVisitedRoute();
    return url;
  }

  setLastVisitedRoute(route: string): void {
    this.$localStorage.store('lastVisitedRoute', route);
    this.$sessionStorage.store('lastVisitedRoute', route);
  }

}
