<div class="ukc-sidenav-container pt-5 pb-2">

  <div class="greeting">Welcome!</div>

  <nav class="nav nav-alt flex-column m-4">

    <a class="nav-link" (click)="showConsultationModal()" id="nav-consultation">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Customer.svg"/>
      </div>
      Personalised Credit Review
    </a>
    <hr />

    <a class="nav-link" routerLink="/dark-web" id="nav-dark-web">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_DarkWeb.svg"/>
      </div>
      Dark Web Summary
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/account']" id="nav-account">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Profile.svg"/>
      </div>
      Member Profile
    </a>
    <hr />

    <a class="nav-link" routerLink="/alerts" id="nav-alerts">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Alerts.svg"/>
      </div>
      Alerts
    </a>
    <hr />

    <ng-container *ngIf="accountService.hasAccessScope('download-report') | async">
    <a class="nav-link" (click)="downloadReport()" id="nav-download-report">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Download.svg"/>
      </div>
      Download Full Credit Report
    </a>
    <hr />
    </ng-container>

    <a class="nav-link" (click)="viewReport()" id="nav-print-report">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Print.svg"/>
      </div>
      View/Print Credit Report
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/account/email-report']" id="nav-email-report">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Email.svg"/>
      </div>
      Email Credit Report
    </a>
    <hr />

    <ng-container *ngIf="accountService.hasAccessScope('send-report') | async">
    <a class="nav-link" [routerLink]="['/account/web-post-report']" id="nav-post-report">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_PaperPlane.svg"/>
      </div>
      Send Your Report
    </a>
    <hr />
    </ng-container>

<!--    <a class="nav-link" (click)="beginIntro()" id="nav-intro-slides">-->
<!--      <div class="icon-container">-->
<!--        <img src="/assets/images/menu/ICN_Line_Intro.svg"/>-->
<!--      </div>-->
<!--      Intro / How To-->
<!--    </a>-->
<!--    <hr />-->

    <a class="nav-link" routerLink="/edu" id="nav-credit-education">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Education.svg"/>
      </div>
      Credit Education
    </a>
    <hr />

    <app-nav-fast-perks></app-nav-fast-perks>

    <app-nav-credit-ladder></app-nav-credit-ladder>

  </nav>

  <nav class="nav nav-alt flex-column m-4 mt-5">
    <a class="nav-link" [routerLink]="['/public-private-data']" id="nav-public-private-data">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Data.svg"/>
      </div>
      Public & Private Data
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/financial-data/home']" id="nav-financial-accounts">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_FinancialAccounts.svg"/>
      </div>
      Financial Accounts
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/enquiries/list']" id="nav-credit-searches">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_CreditEnquiries.svg"/>
      </div>
      Credit Searches
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/credit-utilization/home']" id="nav-credit-utilization">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_BorrowingHealth.svg"/>
      </div>
      Borrowing Health
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/disputes/list']" id="nav-disputes">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_DisputeCentre.svg"/>
      </div>
      Disputes Centre
    </a>
    <hr />

    <a class="nav-link" [routerLink]="['/corrections']" id="nav-corrections">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_CorrectionsCentre.svg"/>
      </div>
      Corrections Centre
    </a>
    <hr />
  </nav>

  <nav class="nav nav-alt flex-column m-4 mt-5">
    <a class="nav-link" routerLink="/customer-service" id="nav-customer-service">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Customer.svg"/>
      </div>
      Customer Support
    </a>
    <a class="nav-link" (click)="fakeClientError()" *ngIf="!isProduction()">
      <div class="icon-container">

      </div>
      Simulate error
    </a>
    <a class="nav-link" (click)="trustpilotService.open()" *ngIf="!isProduction()">
      <div class="icon-container">

      </div>
      Trustpilot modal
    </a>
    <a class="nav-link" (click)="logout()" id="nav-logout">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_Line_Logout.svg"/>
      </div>
      Logout
    </a>
  </nav>

  <nav class="nav nav-alt flex-column m-4 mt-5">
    <a class="nav-link" [href]="signPostingLink()" id="nav-singposting-service" target="_blank">
      <div class="icon-container">
        <i class="fa-solid fa-hand-holding-hand" style="color: #01c9fc; font-size: 20px;"></i>
      </div>
      Struggling to cope?<br />
      <div id="signposting"><span>Signposting to Support Services</span></div>
    </a>
  </nav>

</div>
