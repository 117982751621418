import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {Chainable} from '../models/chainable';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';
import {DarkWebComponent} from './dark-web.component';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';

import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filterNilVal} from 'src/app/shared/utils/filters';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkWebIaoModalService implements Chainable {

  private account: IAccount;

  constructor(private accountService: AccountService,
              private modalService: NgbModal,
              private store: Store) {
  }

  canDisplay(): boolean {
    return !this.account.settings.hasSeenDarkwebModal;
  }

  onIaoClose(): void {
    this.accountService
      .updateSetting('hasSeenDarkwebModal', true)
      .subscribe(genericErrorObserver);
  }

  resolveDependencies(): Promise<any> {
    const obs = this.store.select(SessionState.getAccount)
      .pipe(filterNilVal(),
        take(1)
    );
    obs.subscribe((account: IAccount) => this.account = account);
    return firstValueFrom(obs);
  }

  run(): Promise<IaoModalResultEnum> {
    const modalRef = this.modalService.open(DarkWebComponent, {size: 'md'});

    return modalRef.result;
  }
}
