<ng-template #dashPlaceholder xmlns="http://www.w3.org/1999/html">
  <span class="text-muted">&nbsp;</span>
</ng-template>

<!-- Normal Active User -->
<ng-template #scoredPlaceholder>
  <div class="title">Credit Score</div>

  <div class="score font-numbers text-center">
    <span
      *ngIf="rating?.Score; else dashPlaceholder"
      [countUp]="rating?.Score"
      [reanimateOnClick]="false">
            0
    </span>
  </div>

  <app-credit-score-progress-bar [ratingBar]="ratingBar" [reportDate]="today"></app-credit-score-progress-bar>

  <app-credit-score-rating-bar [rating]="rating"></app-credit-score-rating-bar>
</ng-template>

<!-- No Report (MV) -->
<ng-template #noReportManualVerify>
  <div class="text-center pb-2">
    <h3>Your Credit Score<br>is Unavailable</h3>

    <a [routerLink]="['/info/manual-verify']">(Further documents are required)<br>Manual Verification Status</a>
  </div>
</ng-template>

<!-- No Report (Thin) -->
<ng-template #noReportThinFile>
  <div class="text-center pb-2">
    <h3>Your Credit Score<br>is Unavailable</h3>

    <a [routerLink]="['/edu/thin']">(Thin File Status)</a>
  </div>
</ng-template>

<!-- No Report (BE) -->
<ng-template #noReportBrokenEnrollment>
  <div class="text-center pb-2">
    <h3>Your Credit Score<br>is Unavailable</h3>

    <a (click)="completeRegistration()" class="clickable">Complete Your Registration</a>
  </div>
</ng-template>


<!-- Common No Report (Active User)  -->
<ng-template #noReportActive>
  <div class="text-center pb-2">

    <!-- TODO: Show previous score -->
    <h3>Your Credit Score<br>is Unavailable</h3>

    <ng-template #popContentNoReportHelp>
      <div style="min-width:275px;">
        <div class="row">
          <div class="col-1 text-end"><i class="fa-solid fa-phone-flip"></i></div>
          <div class="col-11"><strong>Telephone</strong> - <a href="tel:{{ (account$ | async)?.product?.number }}" target="_blank"
                                                              style="font-family: sans-serif">{{ (account$ | async)?.product?.number }}</a><br/>
            <span class="small">({{ (account$ | async)?.office?.hours }})</span>
          </div>
        </div>

        <div class="row">
          <div class="col-1 text-end"><i class="fa-solid fa-envelope"></i></div>
          <div class="col-11"><strong>Email</strong> -
            <a href="mailto:{{ (account$ | async)?.product?.cs_email }}" target="_blank">
              {{ (account$ | async)?.product?.cs_email }}</a></div>
        </div>

        <div class="row">
          <div class="col-1 text-end"><i class="fa-solid fa-comment-dots"></i></div>
          <div class="col-11"><strong>Chat</strong> - (Click the chat logo)</div>
        </div>
      </div>
    </ng-template>

    <p>
      Your credit file is in the process of being updated.  This can take up to 24 hours to complete.  Please contact our customer support team for assistance
      <span class="clickable font-ukc-blue" [ngbPopover]="popContentNoReportHelp" container="body">here</span>.
    </p>
  </div>
</ng-template>


<!-- TODO: No Report (Active User) Occurs sometimes when existing user changes address or other PII -->
<ng-template #noReportActiveExistingCustomer>
  <div class="text-center pb-2">

    <!-- TODO: Show previous score -->
    <h1>Your Credit Score<br>is Unavailable</h1>

    <h5 ngSwitch="userStatus">
      <p>Your most recent credit report and score cannot be updated at this time. Please contact our customer support team for assistance HERE</p>
    </h5>
  </div>
</ng-template>

<!-- TODO: New User, Completed KBA, Cannot get first report -->
<ng-template #noReportActiveNewCustomer>
  <div class="text-center pb-2">
    <h1>Your Credit Score<br>is Unavailable</h1>

    <h5 ngSwitch="userStatus">
      <p>Your credit score and report is currently unavailable - Please contact our customer support team HERE</p>
    </h5>
  </div>
</ng-template>



<div class="dash-score-widgit">
  <ng-template [ngTemplateOutlet]="this.scoreViewTemplate"></ng-template>
</div>


