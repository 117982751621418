import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {share, tap} from 'rxjs/operators';
import {CtaCategoryInterface} from '../models/cta/cta-category.interface';
import {Store} from '@ngxs/store';
import {CtaState} from 'src/app/shared/state/cta/cta.state';
import {UpdateCtaCategories} from 'src/app/shared/state/cta/cta.actions';


@Injectable({
  providedIn: 'root',
})
export class CtaCategoryService {

  constructor(private http: HttpClient, private store: Store) {
  }

  get(): Observable<CtaCategoryInterface[]> {
    return this.store.select(CtaState.getCategories);
  }

  /**
   * Ask server for a new list of CTA categories, then store in the state.
   */
  fetch(): Observable<CtaCategoryInterface[]> {
    return this.http
      .get<CtaCategoryInterface[]>('/api/cta/categories')
      .pipe(
        share(),
        tap(
          categories => {
            this.store.dispatch(new UpdateCtaCategories(categories));
          },
        ),
      );
  }
}
