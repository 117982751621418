
<div class="offer-categories" id="cta-categories">

  <p class=" text-center font-color-alt" *ngIf="hasCategories()">Smart Offers:</p>

  <div class="cat-slider">
    <div class="cat-nav cat-nav-left">
      <i class="fa-solid fa-lg font-ukc-blue fa-chevron-left" (click)="navLeft()" *ngIf="canDisplayNav()"></i>
    </div>
    <div #window class="window">

        <div  class="cat-item clickable" (click)="openSmartOffers(category.id)" *ngFor="let category of categories | slice:start:end">
          <div class="icon-container">
            <img class="" [src]="category.icon" />
          </div>
          <div class="badge-container" *ngIf="smartOfferService.hasConsentedSmartOffers()">
            <span class="badge rounded-pill text-bg-primary text-white px-2">{{category.count}}</span>
          </div>
          <div class="label-container">
            <label>{{category.name}}</label>
          </div>
        </div>

    </div>
    <div class="cat-nav cat-nav-right" (click)="navRight()">
      <i class="fa-solid fa-lg font-ukc-blue fa-chevron-right" *ngIf="canDisplayNav()"></i>
    </div>
  </div>

</div>
