import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';

@Component({
  selector: 'app-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
  standalone: true,
})
export class TrustpilotComponent {

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  click() {
    MixpanelService.track('Click Trust Pilot link in modal');
    window.open('https://uk.trustpilot.com/review/www.rsdatatech.com', '_blank');
    this.close();
  }

  close() {
    this.activeModal.close();
  }
}
