<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>
        Hard Searches
      </h3>

      <div class="flex-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Accounts</h5>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>COMPANY</th>
                <th>DATE</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let account of inquiries">
                <td>
                  {{account.Company}}
                </td>
                <td>
                  {{account.SearchDate | amDateFormat:'DD/MM/YYYY'}}
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <a [routerLink]="['/enquiries']">View All Searches</a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Not All Searches Impact Your Score</h5>
            <p class="card-text">
              There are 2 types of credit searches, typically referred to as hard searches and soft searches.
              Hard searches  are usually from applications for credit when a lender or financial institution is actually checking your credit.
              Whereas soft searches may be searches you do on yourself, for example checking your own credit, or quotation searches.
              Searches do not impact your TransUnion credit score in the UK, but a high  number of credit applications may impact your ability to obtain credit from lenders.
            </p>
            <a [routerLink]="['/edu/content/credit-searches']">Read More &gt;&gt;</a>
          </div>
        </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">Try And Avoid Unnecessary Hard Searches</h5>
            <p>
              While searches do not impact your TransUnion credit score in the UK, a high number of credit applications may impact your ability to obtain credit from lenders.
            </p>
            <p>
              <a [routerLink]="['/enquiries/list']">Review Hard Searches &gt;&gt;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
