import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CtaService} from 'src/app/shared/services/cta.service';
import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {animate, query, stagger, style, transition, trigger} from '@angular/animations';
import {delay, Observable} from 'rxjs';

@Component({
  selector: 'app-cta-grid',
  templateUrl: './cta-grid.component.html',
  styleUrls: ['./cta-grid.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          // This causes the tiles to slowly disappear when leaving the dashboard page.  With 15+ tiles, navigating away from the dashboard
          // takes a long time.  Disable this to be more resopnsive.
          /*stagger(100, [
            animate('1.0s', style({ opacity: 0 }))
          ])*/
        ], { optional: true }),
        query(':enter', [
          style({opacity: 0}),
          stagger(100, [
            animate('1.0s', style({opacity: 1})),
          ]),
        ], {optional: true}),
      ])
    ])
  ]
})
export class CtaGridComponent {

  @Input() limit: number;
  @Output() ctaAnimationDoneEvent = new EventEmitter<AnimationEvent>();

  ctaItem$: Observable<Array<CTA>>;

  constructor(ctaService: CtaService) {
    this.ctaItem$ = ctaService.get().pipe(
      // map(ctas => ctas.slice(3)),
      delay(1000),
    );
  }

  animationDone($event) {
    this.ctaAnimationDoneEvent.emit($event);
  }
}
