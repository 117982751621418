import {Component, Inject} from '@angular/core';
import {DarkWebService} from 'src/app/modules/dark-web/services/dark-web.service';
import {Observable} from 'rxjs';
import {DarkwebSummary} from 'src/app/modules/dark-web/models/darkweb-summary';
import {map} from 'rxjs/operators';
import {APP_CONFIG} from 'src/app/shared/config/app.config';
import {IAppConfig} from 'src/app/core/config/app-config.interface';

@Component({
  selector: 'app-darkweb-summary',
  templateUrl: './darkweb-summary.component.html',
  styleUrls: ['./darkweb-summary.component.scss'],
})
export class DarkwebSummaryComponent {

  summary$: Observable<DarkwebSummary>;
  hasSummaryData$: Observable<boolean>;

  constructor(
    darkwebService: DarkWebService,
    @Inject(APP_CONFIG) public config: IAppConfig,
  ) {
    // All summary data
    this.summary$ = darkwebService.getSummary();

    // Have we completed a scan (has scan date) and were there at least 1 dark web hit (risk accumulation)
    this.hasSummaryData$ = darkwebService.getSummary().pipe(map(summary => {
      if (!summary || !summary.lastScanDate || Object.keys(summary).length === 0) {
        return false;
      }

      return Object.values(summary.risks).find(v => v > 0);
    }));
  }
}
