import {Injectable} from '@angular/core';
import {TrustpilotComponent} from './trustpilot.component';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app//shared/mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class TrustpilotService {

  private modalOptions: NgbModalOptions = {
    size: 'md',
    scrollable: true,
    windowClass: 'trustpilot-modal',
    backdropClass: 'trustpilot-backdrop'
  };

  constructor(
    private modalService: NgbModal,
  ) { }

  public open() {
    MixpanelService.track('ShowModal Trust Pilot');

    this.modalService
      .open(TrustpilotComponent, this.modalOptions)
      .result
      .then(
        () => {

        }
      ).catch(
        () => {

      }
    );
  }
}
