<div class="container">
  <div class="row">

    <div class="col-md-6 mx-auto">
      <div class="login-sec">

        <div class="pt-5 pb-5 login-logo">
          <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
        </div>

        <form class="login-form" (submit)="login()">

          <div class="">
            <label for="email" class="col-form-label">Email</label>
            <div class="">
              <input type="email" class="form-control" name="email" id="email" placeholder="Email" [(ngModel)]="loginRequest.email">
            </div>
          </div>

          <div class="">
            <label for="password" class="col-form-label">Password</label>
            <div class="">
              <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="loginRequest.password" #passwordInput>
            </div>
          </div>

          <div class="mt-3 form-button">
            <button type="submit" class="btn btn-primary rounded-pill" [disabled]="submitting">Submit</button>
          </div>
        </form>

      </div>
    </div>

  </div>
</div>
