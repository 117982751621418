import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-credit-alerts-box',
  templateUrl: './credit-alerts-box.component.html',
  styleUrls: ['./credit-alerts-box.component.scss'],
})
export class CreditAlertsBoxComponent {

  amount$: Observable<number>;

  constructor(reportService: CreditReportService) {
    this.amount$ = reportService.getAlerts()
      .pipe(
        map(alerts => alerts.length),
      );
  }

}
