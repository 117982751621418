import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {CtaCategoryInterface} from 'src/app/shared/models/cta/cta-category.interface';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import {UpdateCta, UpdateCtaCategories, UpdateCtas} from 'src/app/shared/state/cta/cta.actions';
import ClearState = SessionActions.ClearState;


export interface CTAStateModel {
  ctas: Array<CTA> | null;
  ctaCategories: Array<CtaCategoryInterface> | null;
}


const stateDefaults: CTAStateModel = {
  ctas: null,
  ctaCategories: null,
};


@State<CTAStateModel>({
  name: 'cta',
  defaults: stateDefaults,
})


@Injectable()
export class CtaState {

  @Selector([CtaState])
  static getCtas(state: CTAStateModel): CTA[] {
    return state.ctas;
  }

  @Selector([CtaState])
  static getCategories(state: CTAStateModel): CtaCategoryInterface[] {
    return state.ctaCategories;
  }

  static getCta(ctaId: number) {
    return createSelector([CtaState], (state: CTAStateModel): CTA | null => {
      if (!state.ctas) {
        return null;
      }

      return state.ctas.filter(cta => cta.id === ctaId)[0] ?? null;
    });
  }

  @Action(UpdateCtas)
  updateCtas(ctx: StateContext<CTAStateModel>, action: UpdateCtas) {
    // Get existing state
    const state: CTAStateModel = {...ctx.getState()};

    // Set new list of CTAs
    state.ctas = action.ctas;

    // Sort by priority
    state.ctas = this.sortCtas(state.ctas);

    ctx.setState(state);
  }

  @Action(UpdateCta)
  updateCta(ctx: StateContext<CTAStateModel>, action: UpdateCta) {
    // Get existing state
    const state: CTAStateModel = {...ctx.getState()};
    // Ensure we have an array of ctas to work with
    if (state.ctas == null) {
      state.ctas = [];
    }

    // Remove existing entry for CTA to be updated
    state.ctas = state.ctas.filter(cta => cta.id !== action.cta.id);
    state.ctas.push(action.cta);

    // Sort by priority
    state.ctas = this.sortCtas(state.ctas);

    ctx.setState(state);
  }

  @Action(UpdateCtaCategories)
  updateCtaCategories(ctx: StateContext<CTAStateModel>, action: UpdateCtaCategories) {
    ctx.patchState({ctaCategories: action.categories});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<CTAStateModel>) {
    ctx.setState(stateDefaults);
  }

  private sortCtas(ctas: CTA[]): CTA[] {
    return ctas.sort((a, b) => {
      if (a.priority > b.priority) {
        return -1;
      } else if (a.priority < b.priority) {
        return 1;
      }

      return 0;
    });
  }
}
