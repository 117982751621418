import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, share, tap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';


@Injectable({
  providedIn: 'root'
})
export class DisputesService {

  protected disputes: Array<any> = null;


  /**
   * Track if we are fetching already to prevent repeat calls to backend
   */
  protected isFetching = false;

  /**
   * Observable for current fetch
   */
  protected reportObservable: Observable<any> = null;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    // Clear out local cache when we logout
    this.store.select(SessionState.getToken)
      .subscribe(token => {
        if (!token) {
          this.disputes = undefined;
        }
      });
  }


  /**
   * Get user from cache if available or fetch from server
   * @param force When TRUE, Force cache deletion and refetch from sever
   * @returns Observable<any>
   */
  public get(force: boolean = false): Observable<any> {

    if (force === true) {
      this.disputes = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.disputes) {
      return of(this.disputes)
        .pipe(
          map(
            resp => resp as Array<any>
          )
        );
    }

    return this.fetch();
  }


  /**
   * Fetch User Entity from server and cache locally
   */
  private fetch(): Observable<any> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.reportObservable = this.http
        .get('/api/disputes')
        .pipe(
          share(),
          map(
            resp => resp as Array<any>
          ),
          tap(
            disputes => {
              this.isFetching = false;
              this.disputes = disputes;
            }

          )
        );
    }

    return this.reportObservable;
  }

  public getDisputes(){
    return this.disputes;
  }
}
