import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav, MatSidenavContent} from '@angular/material/sidenav';
import {HomeComponent} from '../../../modules/dashboard/pages/home/home.component';
import {ScrollService} from '../../services/scroll.service';


/**
 * This is the layout for LOGGED IN USERS.  If you try and apply this layout to a logged out user, LogoutOnUnauthInterceptor
 * will catch it and redirect you to the logout page.  This happens because this.loadAccount() hits the server to fetch the
 * Account object, prepping it for the rest of the app.
 */
@Component({
  selector: 'app-ecce-layout',
  templateUrl: './ecce-layout.component.html',
  styleUrls: ['./ecce-layout.component.scss']
})
export class EcceLayoutComponent implements OnInit {

  @ViewChild(MatSidenavContent, {static: true}) sidenavContent: MatSidenavContent;
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  public navbarHeight = 52;
  public isSideNavCollapsed = true;

  constructor(private scrollService: ScrollService) { }

  ngOnInit() {
    this.scrollService.scrollAction.subscribe((xPos: number) => {
      this.scrollToPos(xPos);
    });
  }

  closeSideNav() {
    this.sidenav.close();
  }

  toggleSideNav() {

    this.sidenav.toggle().then(event => {
      this.isSideNavCollapsed = event.toString() === 'close';
    });
  }

  /**
   * Make sure all pages are scrolled to top after navigation.  We exclude Dashboard Home because it
   * has a different use case and handles its own scrolling
   */
  onRouterOutletActivate(event) {
    if (!(event instanceof HomeComponent)) {
      this.scrollToTop();
    }
  }

  /**
   * Scroll to top of the page.  Our scroll bar is actually on the material sidenav content, so
   * we have to hook into that via a ViewChild Reference to gain control.
   */
  private scrollToTop(): void {
    this.sidenavContent.scrollTo({ top: 0});
  }

  /**
   * Converts pixel offset to viewport to a scroll position and scrolls the mail container to that position.
   */
  private scrollToPos(xPos: number): void {
    const currentScrollPos = this.sidenavContent.getElementRef().nativeElement.scrollTop;
    const scrollXPos = Math.round(currentScrollPos) + Math.round(xPos) - this.navbarHeight;
    this.sidenavContent.scrollTo({top: scrollXPos, behavior: 'smooth'});
  }
}
