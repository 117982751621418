  <div id="blackfoots" class="animate-show ng-scope footer-fixed" aria-hidden="false">
    <div class="container">

      <div class="float-start pt-3 foot-logo">
        <img appBrandImage="assets/images/-brand-/LGO_UKC_Grey.svg" >
      </div>

      <div class="float-end ps-3 pt-2">
        <a [href]="'https://' + brandDomain + '/privacy.html'" title="Privacy Policy" target="_blank">Privacy</a><span> • </span>
        <a [href]="'https://' + brandDomain + '/terms.html'" title="Terms and Conditions" target="_blank">Terms of Use</a><span> • </span>
        <a [href]="'https://' + brandDomain + '/refundpolicy.html'" title="Refund Policy" target="_blank">Refund Policy</a>
        <br>

        <p class="copyright">Copyright {{brandDomain}} <span id="yearnow" class="">{{currentYear}}</span></p>
      </div>

      <div id="socials" class="float-end pt-2">
        <div class="social float-start ps-2" *ngIf="brandDomain.includes('ukcreditratings.com')" aria-hidden="false">
          <a href="https://www.facebook.com/UkCreditRatings?fref=ts" target="_blank">
            <img appBrandImage="assets/images/-brand-/facebook.png" alt="Facebook">
          </a>
        </div>
        <div class="social float-start ps-2">
          <a [href]="'mailto:' + csEmail" target="_blank">
            <img appBrandImage="assets/images/-brand-/mail.png" alt="Email">
          </a>
        </div>
      </div>

      <div class="clearfix"></div>
    </div>
  </div>
