import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HistoryService} from 'src/app/core/services/history.service';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/core/services/auth.service';

import {AccountService} from 'src/app/shared/services/account.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {filterNilVal} from 'src/app/shared/utils/filters';
import {IAccount} from 'src/app/shared/models/account';

@Component({
  selector: 'app-store-token',
  templateUrl: './store-token.component.html',
  styleUrls: ['./store-token.component.scss']
})
export class StoreTokenComponent implements OnInit, OnDestroy {
  private selectAccount: Subscription;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private historyService: HistoryService,
              private router: Router,
              private store: Store,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    // Get token from the route
    this.route
      .paramMap
      .subscribe((map: Params) => {
        // Store JWT in our token service
        this.authService.storeAuthenticationToken(map.params.token);

        // When this completes, the selectAccount$ in gotoLandingPage() will fire and the redirection will happen.
        this.accountService.fetch().subscribe(genericErrorObserver);

        // Go to home page
        this.gotoLandingPage();
      });
  }

  /**
   * Check if last visited route exists and go there, else default to dashboard.
   */
  private gotoLandingPage() {
    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {

        this.accountService.hasAccessScope('change-password').subscribe(canUpdatePassword => {
          let url = '/dashboard';

          // If we are set to force a password change, only do it if we have scope permissions.
          if (account.settings.forcePasswordReset && canUpdatePassword) {
            url = '/auth/reset-password';
          }

          this.router.navigateByUrl(url);
        });
      });
    // const lastVisitedRoute = this.historyService.getLastVisitedRoute();


    // if ( lastVisitedRoute ) {
    //   console.log('navigate to last visited route: ' + lastVisitedRoute);
    //   this.router.navigateByUrl(lastVisitedRoute);
    // } else {
    //   console.log('navigate to dashboard');
    //   this.router.navigateByUrl('/dashboard');
    // }

    this.historyService.clearLastVisitedRoute();
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
