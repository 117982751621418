import {ElementRef, EventEmitter, Injectable, Output} from '@angular/core';

/**
 * ScrollService
 *
 * A simple scroll service to emit a scroll position to be consumed by the material container of the Ecce Layout so that anywhere
 * in the app we can scroll to some position of the child view content. To use is, you can create a ViewChild Reference.   If you
 * don't have a template ref already, you can just hook into to a DOM element like:
 *
 *    <div #myDivThing>...</div>
 *
 *    @ViewChild('myDivThing') myDiv: ElementRef;
 *
 * Then to scroll, get the position of the element and pass to the scroll service:
 *
 *     const xPos = this.myDiv.nativeElement.getBoundingClientRect().top;
 *     this.scrollService.scroll(xPos);
 */
@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  public elementRefs: Array<ElementRef> = [];

  @Output() scrollAction = new EventEmitter();

  constructor() { }

  /**
   * Emits scroll event for main layout
   */
  scroll(xPos: number) {
    this.scrollAction.emit(xPos);
  }

  /**
   * Adds elementRefs that we wish to be able to scroll to in the main layout
   */
  register(name: string, elementRef: ElementRef) {
    this.elementRefs[name] = elementRef;
  }

  /**
   * Gets the current position of the view child if registered and emits scroll event
   */
  scrollToViewChild(name: string) {
    // xPos is current position of bounding rectangle with respect to the Viewport.
    if (this.elementRefs[name] !== undefined) {
      const xPos = this.elementRefs[name].nativeElement.getBoundingClientRect().top;
      this.scroll(xPos);
    }
  }
}
