
export enum ResidentialStatusEnum {
  'Homeowner'= 'HO',
  'Tenant - Private' = 'TP',
  'Tenant - Housing Association' = 'THA',
  'Tenant - Council' = 'TC',
  'Living With Family' = 'LWF',
  'Military Accommodation' = 'MA',
  'Work Accommodation' = 'WA',
  'Student Accommodation' = 'SA',
  'Other' = 'O'
}
