<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>
        Payment History
      </h3>

      <div class="flex-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Accounts</h5>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>NAME</th>
                <th>STATUS</th>
                <th>PAYMENT HISTORY</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let account of financialAccounts">
                <td>
                  {{account.lenderName}}
                </td>
                <td>
                  {{account.status}}
                </td>
                <td>
                  {{account.paymentHistoryPercentage}}%
                </td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <a routerLink="/financial-data">View All Accounts</a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">What Is Payment History?</h5>
            <p class="card-text">
              Payment history in general, is a record of the monthly payments you have made since the time you opened the account.
              This history is reflected as a percentage of payments made on time over total number of payments.
              So if you have had 10 payments due since you opened your account and made all payments on time, then the system will reflect 100%.
              If however you made a late payment once, the system will show 90% as 9 out of 10 payments were made on time.
              Having a strong and reliable payment history will help you in the future to potentially secure loans and lines of credit.
            </p>
            <a [routerLink]="['/edu/content/payment-history']">Read More &gt;&gt;</a>
          </div>
        </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">Keep Your Payment History Accurate</h5>
            <p>
              Whenever a payment is late or missed you will get what is called a "derogatory mark" or a "negative mark."
              These are items that represent a risk to lenders and can prevent you from obtaining new credit in some circumstances.
              Review these items for accuracy and while mistakes are rare, if you feel the information displayed is not correct you have the right to dispute that information.
              Request to have the item corrected by using the "See a mistake?" button found on the page with the item you feel is in error.
            </p>
            <p>
              <a [routerLink]="['/negative-marks/list']">Review Any Late Payments &gt;&gt;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
