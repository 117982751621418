import {Component} from '@angular/core';
import {AuthService} from 'src/app/core/services/auth.service';
import {Router} from '@angular/router';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {genericErrorObserver} from '../../../observables/generic-error.observer';
import {FileSaverService} from 'ngx-filesaver';
import {MixpanelService} from '../../../mixpanel/mixpanel.service';
import {AccountService} from '../../../services/account.service';
import {TrustpilotService} from 'src/app/modules/trustpilot/trustpilot.service';
import {HowToService} from 'src/app/modules/iao-chain/intro/how-to/how-to.service';
import {BrandService} from '../../../services/brand.service';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  constructor(private authService: AuthService,
              private howToService: HowToService,
              private router: Router,
              private loadIndicatorService: LoadIndicatorService,
              private http: HttpClient,
              private fileSaverService: FileSaverService,
              public accountService: AccountService,
              public trustpilotService: TrustpilotService,
              private consultModalService: CreditConsultationIaoModalService,
  ) { }

  beginIntro() {
    this.loadIndicatorService.push('intro-wait');

    this.router
      .navigate(['/dashboard/home'])
      .then(() => {
        setTimeout(() => {
          this.loadIndicatorService.pop('intro-wait');
          this.howToService.run();
        }, 500);
      });
  }

  downloadReport(): void {
    // If this service is supported by the browser, we can use our progress bar page.
    if (this.fileSaverService.isFileSaverSupported) {
      this.router.navigateByUrl('/account/download-report');
    } else {
      // Otherwise, just work like it always has.
      MixpanelService.track('Click Direct download report');
      this.postToken('/api/report/pdf');
    }
  }

  viewReport(): void {
    MixpanelService.track('Click View printable report nav item');

    this.postToken('/api/report/print');
  }

  private postToken(action: string): void {
    // create a POST form with the action URL provided
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', action);
    form.setAttribute('target', '_blank');

    // use the token as a hidden input field
    const token = document.createElement('input');
    token.setAttribute('type', 'hidden');
    token.setAttribute('name', 'token');
    token.setAttribute('value', this.authService.getToken());
    form.appendChild(token);
    document.body.appendChild(form);

    // submit the form to new tab and then delete the form
    form.submit();
    document.body.removeChild(form);
  }

  /**
   * Logout (and go to specific logout page)
   */
  logout() {
    MixpanelService.track('Click logout menu item');

    this.authService.logout(false, true);
  }

  isProduction(): boolean {
    return environment.production;
  }

  /**
   * Cause a 404 error.  This is strictly so we can test any interceptors, like sentry.io modals.
   */
  fakeClientError(): void {
    this.http
      .get('/missing-page')
      .subscribe(genericErrorObserver);

    throw new Error('this is a fake error');
  }

  signPostingLink(){
    return BrandService.getSignPostingLink();
  }

  showConsultationModal(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }
}
