import {Component, OnInit} from '@angular/core';
import {ToastService} from '../toast.service';
import {ToastType} from '../toast-type';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  public toastType = ToastType;

  // ToastService is used in the view
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {
  }

}
