import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {PasswordResetRequestComponent} from './pages/password/password-reset-request/password-reset-request.component';
import {PasswordResetComponent} from './pages/password/password-reset/password-reset.component';
import {StoreTokenComponent} from './pages/store-token/store-token.component';
import {GoogleAuthenticateComponent} from './pages/google-authenticate/google-authenticate.component';
import {OneTimePasswordLoginComponent} from './pages/one-time-password-login/one-time-password-login.component';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    PasswordResetRequestComponent,
    PasswordResetComponent,
    StoreTokenComponent,
    GoogleAuthenticateComponent,
    OneTimePasswordLoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    LoginComponent
  ]
})
export class AuthModule { }
