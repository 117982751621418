<span class="nav-alt" *ngIf="!!cta">
    <a class="nav-link" (click)="openModal()" id="nav-credit-ladder">
      <div class="icon-container">
        <img src="/assets/images/menu/ICN_ReportRent.svg"/>
      </div>
      Report Rent - Improve Score
    </a>
    <div class="dropdown-divider"></div>
</span>


