<div class="exit-survey">
  <div class="icon">
    <img src="/assets/images/survey.webp" alt="">
  </div>

  <div class="body">
    <p>Did you find what you were looking for today?</p>

    <button (click)="clickButton()" class="btn btn-large btn-primary">Click here &amp; let us know</button>

    <p class="mt-3"><a href="javascript:void(0)" (click)="activeModal.close()">Skip - I found what I'm looking for</a></p>
  </div>
</div>
