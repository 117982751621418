import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {BtnAction} from 'src/app/shared/models/cta/btn-action';
import {BtnActionType} from 'src/app/shared/models/cta/btn-action-type.enum';
import {offerComponentMap} from '../../offers/models/offer-component-map';
import {Runnable} from '../../iao-chain/models/runnable';
import {runnableServiceMap} from '../../iao-chain/runnable-service-map';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAccount} from 'src/app/shared/models/account';


/**
 * When a CTA button is clicked, this will do what needs to be done.  This was made because
 * there are special placeholders for certain CTAs, so it can get complicated what is happening.
 */
@Injectable({
  providedIn: 'root'
})
export class CtaActionService {

  constructor(
    private injector: Injector,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  handleAction(btnAction: BtnAction, account: IAccount): void {
    if (btnAction === null) {
      return;
    }

    switch (btnAction.type) {
      case BtnActionType.LINK:
        this.router.navigateByUrl(btnAction.action);
        break;
      case BtnActionType.MODAL:
        this.openModal(btnAction);
        break;
      case BtnActionType.SERVICE:
        this.runServiceProvider(btnAction.action);
        break;
      case BtnActionType.EXT_LINK:
        window.open(btnAction.action, '_blank');
        break;
      case BtnActionType.AFF_LINK:
        const link = this.getAffiliateLink(btnAction.action, account);
        window.open(link, '_blank');
        break;
      default:
        throw new Error('Unhandled button action: "' + btnAction.type + '"');
    }
  }

  /**
   * Load Component Modal dynamically.  Component must be in the component map
   */
  private openModal(btnAction: BtnAction): Promise<any> {

    // Validate Component Name is in the dictionary
    if (!offerComponentMap.hasOwnProperty(btnAction.action)) {
      throw new Error('Component ' + btnAction.action + ' not found in dictionary');
    }

    let options = btnAction.options;

    if (options === undefined || options === null) {
      options = {size: 'md'};
    }

    const modal = offerComponentMap[btnAction.action];
    const modalRef = this.modalService.open(modal, options);

    // TODO: Modal args vs having the modal get its own
    // if (modalRef && args) {
    //   for (const [k, v] of Object.entries(args)) {
    //     modalRef.componentInstance.k = v;
    //   }
    // }

    return modalRef.result;
  }

  /**
   * Runs a service dynamically.  Service must implement Runnable and be added to the service map
   */
  private runServiceProvider(providerName: string): void {
    const service = this.injector.get<Runnable>(runnableServiceMap[providerName]);
    service.run().then();
  }

  /**
   * Create affiliate specific link to affiliate site
   *
   * e.g. https://ukcreditratings.fast-perks.com/signin-auto?emailaddress=test@rsdatatech.com
   *
   * Note: we may need to move var replacement to its own service if we start adding more variables.  Also, to
   * make this a little nicer in the future, these AFF_LINK types are an EXT_LINK but with variable replacements.
   * So could we just put this in the account service and thus not have to pass the account object around...
   *
   * @param action
   * @param account
   * @private
   */
  public getAffiliateLink(action: string, account: IAccount): string {
    return action.replace('<product_name>', account.product.name)
      .replace('<email>', account.user.email)
      .toLowerCase();
  }
}
