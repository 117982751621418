/**
 * Created by josephreymann on 08/12/19.
 */
import {SummaryStats} from './summary-stats';
import {Report} from './report/report';
import {CreditReportInterface} from './credit-report.interface';
import {AccountType} from './report/account-type.enum';
import {SearchInquiry} from './search-inquiry';

/**
 * This is the full data file we have on the customer.  That includes the "Report" which is merely
 * the unmodified data from Transunion.  It also includes a lot of summary and analaysis along side
 * it.
 */
export class CreditReport implements CreditReportInterface {
  guid = '';
  report: Report;
  summary: SummaryStats = new SummaryStats();
  financialAccounts: Array<any> = [];
  negativeMarks: Array<any> = [];
  inquiries: Array<SearchInquiry> = [];
  softInquiries: Array<SearchInquiry> = [];
  reportUnavailable = false;

  constructor() {}

  fromJson(json: any) {

    const myObjectKeys = Object.keys(json);

    myObjectKeys.forEach((key: any) => {

      if (key === 'report') {
        const r = new Report();
        r.fromJson(json[key]);
        this[key] = r;
      } else {
        this[key] = json[key];
      }
    });
  }

  /**
   * Credit report can be empty if thin file or manual verify
   */
  isEmpty(): boolean {
    return this.guid === '';
  }

  getGuid(): string {
    return this.guid;
  }

  getReport() {
    return this.report;
  }

  getSummaryStats() {
    return this.summary;
  }

  getFinancialAccountInformation(accountType: AccountType): Array<any> {
      return this.report.getFinancialAccountInformation(accountType);
  }

  /**
   * Find and return the account by account type & account id
   * @param accountType
   * @param accountId
   */
  public getAccount(accountType: AccountType, accountId: number) {

    const accounts = this.report.getFinancialAccountInformation(accountType);

    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].AccountId === accountId) {
        return accounts[i];
      }
    }
  }

  /**
   * Checks each type of account, looking for a specific id.  If found, returns which
   * type of account it is.
   * @param accountId
   */
  getAccountTypeById(accountId: number): AccountType {
    for (let i = 0; i < 5; i++) {
      const acct = this.getAccount(i, accountId);

      if (acct) {
        return i;
      }
    }
  }

  getAccountById(accountId: number) {
    for (let i = 0; i < 5; i++) {
      const acct = this.getAccount(i, accountId);

      if (acct) {
        return acct;
      }
    }
  }

  getInsolvency(insolvencyOrderId: number) {
    let data = null;

    this.report
      .getInsolvencies()
      .forEach(value => {
        if (value['InsolvencyOrderId'] == insolvencyOrderId) {
          data = value;
        }
      });

    return data;
  }

  getJudgment(casePerId: number) {
    let data = null;

    this.report
      .getJudgments()
      .forEach(value => {
        if (value['CasePerId'] == casePerId) {
          data = value;
        }
      });

    return data;
  }

  getOtherName(AliasId: number) {
    let data = null;

    this.report
      .getOtherNames()
      .forEach(value => {
        if (value['AliasId'] == AliasId) {
          data = value;
        }
      });

    return data;
  }

  getFinancialConnection(AssociateId: number) {
    let data = null;

    this.report
      .getFinancialConnections()
      .forEach(value => {
        if (value['AssociateId'] == AssociateId) {
          data = value;
        }
      });

    return data;
  }

  getCifa(CaseId: number) {
    let data = null;

    this.report
      .getCifas()
      .forEach(value => {
        if (value['CaseId'] == CaseId) {
          data = value;
        }
      });

    return data;
  }
}
