import {Component, OnDestroy, OnInit} from '@angular/core';

import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {IAccount} from 'src/app/shared/models/account';
import * as moment from 'moment/moment';
import {Subscription} from 'rxjs';
import {filterNilVal} from 'src/app/shared/utils/filters';
import {AccountService} from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-trial-countdown',
  templateUrl: './trial-countdown.component.html',
  styleUrls: ['./trial-countdown.component.scss'],
})
export class TrialCountdownComponent implements OnInit, OnDestroy{
  public displayed = false;
  public text = '';
  public days = null;
  private account: IAccount;
  private selectAccount: Subscription;

  constructor(
    //private store: Store,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {
        this.account = account;
        this.updateView();
      });
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }

  /**
   * deferred_auth is the flag saying they didn't run their processor during sign up.
   */
  private updateView(): void {
    if (this.account.user.deferred_auth === 1 && this.account.user.deferred_auth_complete === 0)
    {
      const trialEnd = moment(this.account.user.trial_end_date, 'YYYY-MM-DD');
      const today = moment();
      this.days = trialEnd.diff(today, 'days');
      this.displayed = true;
    }

    // TEST
    // this.days = 0;
    // this.text = 'blah';
    // this.displayed = true;
  }
}
