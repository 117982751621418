import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AuthModule} from './modules/auth/auth.module';
import {AuthGuard} from './core/guards/auth.guard';
import {LoginGuard} from './core/guards/login.guard';
import {ConfirmationModalComponent} from './core/components/confirmation-modal/confirmation-modal.component';
import {ErrorComponent} from './core/components/error/error.component';
import * as Sentry from '@sentry/angular';
import {TraceModule} from '@sentry/angular';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {NgxsModule} from '@ngxs/store';
import {SessionState} from './shared/state/session/session.state';
import {DarkwebState} from './modules/dark-web/state/darkweb.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {CtaState} from 'src/app/shared/state/cta/cta.state';
import {CreditReportState} from 'src/app/shared/state/credit-report/credit-report-state.service';
import {EventState} from 'src/app/shared/state/calendly/events.state';
import {CommonModule} from '@angular/common';
import {IaoModule} from 'src/app/modules/iao-chain/iao.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    CoreModule,
    IaoModule,
    HammerModule,
    TraceModule,

    NgxsModule.forRoot([
      SessionState, DarkwebState, CtaState, CreditReportState, EventState
    ], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'session.token'
    }),
    // Load Dev Only Modules
    // !environment.production
    //   ? [NgxsLoggerPluginModule.forRoot(),
    //     NgxsReduxDevtoolsPluginModule.forRoot()]
    //   : [],
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    {provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: false})},
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  exports: [
    BrowserAnimationsModule,
    ConfirmationModalComponent,
    CoreModule,
    ErrorComponent,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
