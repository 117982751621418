import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {EventActions} from 'src/app/shared/state/calendly/events.actions';
import {EventState} from 'src/app/shared/state/calendly/events.state';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';

@Injectable({
  providedIn: 'root'
})
export class CalendlyService {

  constructor(private http: HttpClient, private store: Store) {

  }

  /**
   * Return products from state
   */
  get(): Observable<CalendlyEvent> {
    return this.store.select(EventState.getEvent);
  }

  hasEvent(): Observable<boolean> {
    return this.store.select(EventState.hasEvent);
  }

  /**
   * Hits server and grabs new products
   */
  fetch(): Observable<CalendlyEvent> {
    return this.http
      .get<CalendlyEvent>('/api/calendly')
      .pipe(
        share(),
        tap(
          event => {
            this.store.dispatch(new EventActions.UpdateEvent(event));
          },
        ),
      );
  }

  public addEvent(ev: MessageEvent) {
    this.http
      .post<CalendlyEvent>('/api/calendly/', ev.data.payload.event)
      .pipe(
        share(),
        tap(
          event => {
            console.log(event);
            this.store.dispatch(new EventActions.UpdateEvent(event));
          },
        ),
      ).subscribe();
  }
}
