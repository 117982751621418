import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {CtaCategoryInterface} from 'src/app/shared/models/cta/cta-category.interface';

export class UpdateCtas {
  static readonly type = '[CTA] Update All';

  constructor(public readonly ctas: Array<CTA>) {
  }
}

export class UpdateCtaCategories {
  static readonly type = '[CTA] Categories';

  constructor(public readonly categories: Array<CtaCategoryInterface>) {
  }
}

export class UpdateCta {
  static readonly type = '[CTA] Update Single';

  constructor(public readonly cta: CTA) {
  }
}
