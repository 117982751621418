import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, share, tap} from 'rxjs/operators';
import {CarMoneyApplicationRequest} from '../models/car-money-application-request';
import {CarMoneyApplicationResponse} from '../models/car-money-application-response';




@Injectable({
  providedIn: 'root'
})
export class CarMoneyService {

  constructor(private http: HttpClient) {}

  postApplication(carMoneyApplicationRequest: CarMoneyApplicationRequest): Observable<any> {
    return this.http.post('/api/offers/car-money/apply', carMoneyApplicationRequest);
  }
}
