<ng-template #template_schedule_review>
  <div class="small">
    <span class="fake-link">Click here</span> to book your one-to-one credit review with our customer service team
<!--    <a (click)="book()" class="clickable">Click here</a> to book your one-to-one credit review with our customer service team-->
  </div>
</ng-template>


<div class="title">Personalised Credit Review</div>

<img class="icon" src="assets/images/ICN_CallCenter.svg" alt="Consultation call"/>

<div *ngIf="(hasEvent$ | async) else template_schedule_review">
  <label>Your review has been scheduled for:</label>
  <p class="pt-2 fw-bold">{{(event$ | async)?.start_time | date:	'd MMM Y, HH:mm:ss a'}}</p>
</div>

