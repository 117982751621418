import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IAccount} from 'src/app/shared/models/account';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NgbActiveModal, NgbCalendar, NgbInputDatepickerConfig} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {Store} from '@ngxs/store';
import {SummaryStats} from 'src/app/shared/models/summary-stats';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {MoneyAdvisorRequest} from './money-advisor-request';


export interface MoneyAdvisorResponse {
  code: string;
  body: string;
  common: Array<any>;
  success: boolean;
}

@Component({
  selector: 'app-money-advisor',
  templateUrl: './money-advisor.component.html',
  styleUrls: ['./money-advisor.component.scss']
})
export class MoneyAdvisorComponent implements OnInit, OnDestroy{

  @ViewChild('leadpost_confirmation_view', {static: true}) leadPostTemplate: TemplateRef<any>;
  @ViewChild('leadpost_response_view') responseTemplate: TemplateRef<any>;
  @ViewChild('error_response_view') errorResponseTemplate: TemplateRef<any>;
  @ViewChild('in_progress_view') inProgressTemplate: TemplateRef<any>;


  /**
   * View state
   */
  public templateName;

  public summaryStats: SummaryStats;
  private selectAccount: Subscription;
  public account: IAccount;

  private negativeMarks = {
    missed_payments: 0,
    judgements: 0,
    insolvencies: 0,
  };

  /**
   * NgbTimePicker Spinner increment for minutes
   */
  public minuteStep = 5;


  public form: MoneyAdvisorRequest = {
    contact_preference: {
      date: null,
      time: null
    },
    insolvencies: null,
    judgements: null,
    missed_payments: null,
    totalDebt: null,
  };

  public datePickerStatus;

  constructor(
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    private accountService: AccountService,
    private store: Store,
    private reportService: CreditReportService,
    private calendar: NgbCalendar,
    public config: NgbInputDatepickerConfig
  ) {
      this.initContactDateTime();
  }

  ngOnInit(): void {
    this.templateName = this.leadPostTemplate;

    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {
      this.account = account;
    });

    this.summaryStats = new SummaryStats();

    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.summaryStats = this.reportService.getSummaryStats();
        this.tallyNegativeMarks();
      });
  }

  initContactDateTime() {

    // Disallow past dates
    this.config.minDate = this.calendar.getToday();
    this.form.contact_preference.date = this.calendar.getToday();

    const now = new Date();

    // Set maximum datepicker date
    this.config.maxDate = {
      year:  now.getFullYear() + 1,
      month: now.getMonth(),
      day: 1
    };

    const minute = Math.ceil((now.getMinutes() + this.minuteStep) / 10) * 10;

    // Set default contact time nearest 10 minute interval in the future
    this.form.contact_preference.time = {
      hour: (minute >= 60) ? now.getHours() + 1 : now.getHours(),
      minute: (minute >= 60) ? 0 : minute,
      second: 0
    };
  }

  private tallyNegativeMarks() {
    const marks = this.reportService.getNegativeMarks();

    for (let i = 0; i < marks.length; i++) {
      if (marks[i].type === 'missed_payment') {
        this.negativeMarks.missed_payments++;
      }
      if (marks[i].type === 'judgement') {
        this.negativeMarks.judgements++;
      }
      if (marks[i].type === 'insolvency') {
        this.negativeMarks.insolvencies++;
      }
    }
  }

  onSubmit() {
      MixpanelService.track('Submit Money Advisor offer');
      this.postLead();
  }

  private postLead(): void {

    this.templateName = this.inProgressTemplate;

    this.form.totalDebt = this.summaryStats.totalDebt;
    this.form.insolvencies = this.negativeMarks.insolvencies;
    this.form.missed_payments = this.negativeMarks.missed_payments;
    this.form.judgements = this.negativeMarks.judgements;

    this.http.post('/api/offers/moneyadvisor/leadpost', this.form)
      .subscribe((resp: MoneyAdvisorResponse) => {
          if (resp.success) {
            this.templateName = this.responseTemplate;
            MixpanelService.track('SuccessfulSubmit Money Advisor offer');
          } else {
            this.templateName = this.errorResponseTemplate;
          }
        },
        error => {
          this.templateName = this.errorResponseTemplate;
        });
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
