import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {HistoryService} from '../services/history.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
      private authService: AuthService,
      private router: Router,
      private historyService: HistoryService
  ) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.authService.isAuthenticated()) {
      this.historyService.setLastVisitedRoute(state.url);
      this.router.navigateByUrl('/auth/login');
    }

    return this.authService.isAuthenticated();
  }
}
