import PCAAddress from './PCAAddress';
import {UkcAddress} from './UkcAddress';

/**
 * Translates address from Loqate to our system.  There is a mirrored copy of this in checkout.
 */
export default class AddressFormatter {
  static translateLoqateToUkc(addy: PCAAddress): UkcAddress {
    return {
      // This seems to be the only field with the flat number / abode number
      abodenumber: addy.SubBuilding,

      // Back in 2020 we found an address of Loqate that had extra values on the building number.  We expect this to be only
      // a number, so just pull the first value.  If you find an example of this, please paste it here.
      straddr: addy.BuildingNumber.split(' ')[0],

      // No idea why we did this district option
      street: addy.Street.length > 0 ? addy.Street : addy.District,

      // No idea why we did this company option
      building_name: addy.BuildingName.length > 0 ? addy.BuildingName : addy.Company,

      city: addy.City,
      zip: addy.PostalCode,
    };
  }

  static formatLoqate(addy: PCAAddress): string {
    return AddressFormatter.formatOurAddress({
      abodenumber: addy.SubBuilding,
      straddr: addy.BuildingNumber,
      street: addy.Street,
      building_name: addy.BuildingName,
      city: addy.City,
      zip: addy.PostalCode,
    });
  }

  static formatOurAddress(addy: UkcAddress) : string {
    let result = '';

    let abodenumber = addy.hasOwnProperty('abodenumber') ? addy.abodenumber : '';
    let straddr = addy.hasOwnProperty('straddr') ? addy.straddr : '';
    let street = addy.hasOwnProperty('street') ? addy.street : '';
    let building_name = addy.hasOwnProperty('building_name') ? addy.building_name : '';
    let city = addy.hasOwnProperty('city') ? addy.city : '';
    let zip = addy.hasOwnProperty('zip') ? addy.zip : '';

    if (abodenumber || building_name) {
      result += abodenumber + ' ' + building_name + ', ';
    }

    if (straddr || street) {
      result += straddr + ' ' + street + ', ';
    }

    result += city + ', ' + zip;

    return result;
  }
}
