import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import { MatRadioGroup } from '@angular/material/radio';
import { MatRadioButton } from '@angular/material/radio';
import { MatSidenavContent } from '@angular/material/sidenav';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/**
 * A place to put all Google Material Components being used by the application
 */

@NgModule({
  declarations: [
    // MatSidenavContainer,
    // MatSidenav,
    // MatSidenavContent,
    // MatRadioButton,
    // MatRadioGroup,
  ],
  imports: [
    CommonModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSidenavModule,
    MatSlideToggleModule
  ],
  exports: [
    // MatSidenavContainer,
    // MatSidenav,
    // MatSidenavContent,
    // MatRadioButton,
    // MatRadioGroup,
    MatNativeDateModule,
    MatRippleModule,
    MatSidenavModule,
    MatSlideToggleModule
  ]
})
export class MaterialModule { }
