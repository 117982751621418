import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {IAccount} from 'src/app/shared/models/account';
import {PasswordCreateComponent} from './password-create.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from 'src/app/shared/services/account.service';
import {take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IaoPasswordCreateService implements Chainable {

  private account: IAccount;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
  ) {
  }

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService
        .getAccount()
        .pipe(take(1))
        .subscribe(account => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return this.account.settings.forceCreatePassword === true;
  }

  run() {
    const modalRef = this.modalService.open(PasswordCreateComponent, {
      size: 'md',
      keyboard: this.isDevelopment(),
      beforeDismiss: () =>  this.isDevelopment()
    });

    return modalRef.result;
  }

  private isDevelopment(): boolean {
    return (!environment.production && !environment.staging);
  }


  onIaoClose() {
    // this.accountService
    //   .updateSetting('hasSeenOfferGoCardless', true)
    //   .subscribe(() => {});
  }
}
