import { Component, OnInit } from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {CreditReport} from 'src/app/shared//models/credit-report';
import * as moment from 'moment';

@Component({
  selector: 'app-summary-hard-inquiries',
  templateUrl: './summary-hard-inquiries.component.html',
  styleUrls: ['./summary-hard-inquiries.component.scss'],
})
export class SummaryHardInquiriesComponent implements OnInit {

  constructor(
    private reportService: CreditReportService
  ) { }

  public inquiries : Array<any>;

  /**
   * Sort inquiries by date
   * @param a
   * @param b
   */
  private static sortSearches(a, b): number {
    if (a.SearchDate > b.SearchDate) {
      return -1;
    } else if (a.SearchDate < b.SearchDate) {
      return 1;
    }
    return 0;
  }

  ngOnInit() {
    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.refreshStats();
      });
  }

  /**
   * Refresh displayed stats
   */
  private refreshStats() {
    let obj = this.reportService.getInquiries();
    obj = Object.keys(obj)
      .map(key => {
        obj[key].SearchDate = moment(obj[key].SearchDate);
        return obj[key];
      })
      .sort(SummaryHardInquiriesComponent.sortSearches)
      .slice(0, 3);

    this.inquiries = obj;
  }

}
