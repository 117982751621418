<div class="trustpilot">

  <div class="outer">


    <div class="main text-center">
      <p class="mt-4">
        These offers were powered by RS Data Tech.
      </p>
      <p class="mt-3">
        Please Leave a Review if you found this convenient.
      </p>
      <div class="butts mt-4">
        <button class="btn btn-trustpilot" (click)="click()">
          Review us on &nbsp;&nbsp;<img class="img-fluid" src="/assets/images/trustpilot_logo.svg" />
        </button>
        <div class="text-center mt-3">
          <a href="#" (click)="click()">
            <img src="/assets/images/rsdt_logo.png">
          </a>
        </div>
          
      </div>

    </div>
  </div>


</div>

