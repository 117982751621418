import { IReport} from './i-report';
import { JsonLoader } from '../json-loader';
import { IFinancialAccountInformation, IFinancialAccount } from './i-financial-account-information';
import {AccountType} from './account-type.enum';
import {SearchInquiry} from '../search-inquiry';
import {NoticeOfCorrection} from './notice-of-correction';

/**
 * This is the unmodified report from transunion.
 */
export class Report extends JsonLoader implements IReport  {

  constructor() {
    super();
  }

  // TODO: Add Members for key portions and break them out as their own objects/interfaces as needed
  public AddressLinks: any;
  public Cifas: any;
  public Details: any;
  public ElectoralRoll: any;
  public FinancialAccountInformation: IFinancialAccountInformation;
  public FinancialConnections: any;
  public Moda: any;
  public NoticesOfCorrection: any;
  public OtherNames: any;
  public PersonalInformation: any;
  public PublicInformation: any;
  public ReportDetails: any;
  public Rating: any;
  public SearchHistory: any;

  /**
   * Sort inquiries by date
   * @param a
   * @param b
   */
  private static sortSearches(a, b): number {
    if (a.SearchDate > b.SearchDate) {
      return -1;
    } else if (a.SearchDate < b.SearchDate) {
      return 1;
    }
    return 0;
  }

  getFinancialAccountInformation(accountType: AccountType): Array<any> {
    let accts;

    switch (accountType) {
      case AccountType.ClosedAccounts:
        accts = this.getClosedAccounts();
        break;
      case AccountType.CreditCards:
        accts = this.getCreditCards();
        break;
      case AccountType.Mortgages:
        accts = this.getMortgages();
        break;
      case AccountType.PersonalLoans:
        accts = this.getPersonalLoans();
        break;
      case AccountType.OtherAccounts:
        accts = this.getOtherAccounts();
        break;
      default:
        throw new Error('Invalid account type');
    }

    if (accts.Account) {
      accts = accts.Account;
    }

    return accts;
  }

  getClosedAccounts(): IFinancialAccount {
    return this.FinancialAccountInformation.ClosedAccounts;
  }

  getCreditCards(): IFinancialAccount {
    return this.FinancialAccountInformation.CreditCards;
  }

  getMortgages(): IFinancialAccount {
    return this.FinancialAccountInformation.Mortgages;
  }

  getPersonalLoans(): IFinancialAccount {
    return this.FinancialAccountInformation.PersonalLoans;
  }

  getOtherAccounts(): IFinancialAccount {
    return this.FinancialAccountInformation.OtherAccounts;
  }

  /**
   * Get all insolvencies in the report in array form
   */
  getInsolvencies(): Array<any> {
    const data = this.PublicInformation.Insolvencies;

    if (data.hasOwnProperty('Insolvency')) {
      return data.Insolvency;
    }

    return [];
  }

  /**
   * Get all judgments in the report in array form
   */
  getJudgments(): Array<any> {
    const data = this.PublicInformation.Judgments;

    if (data.hasOwnProperty('Judgment')) {
      return data.Judgment;
    }

    return [];
  }

  getOtherNames(): Array<any> {
    const data = this.OtherNames.Records;

    if (data.hasOwnProperty('OtherName')) {
      return data.OtherName;
    }

    return [];
  }

  getFinancialConnections(): Array<any> {
    const data = this.FinancialConnections.Records;

    if (data.hasOwnProperty('FinancialConnection')) {
      return data.FinancialConnection;
    }

    return [];
  }

  getCifas(): Array<any> {
    const data = this.Cifas.CifasCases;

    if (data.hasOwnProperty('CifasCase')) {
      return data.CifasCase;
    }

    return [];
  }

  /**
   * Inquiries / searches on one's credit file, made by the personal in question.  "Your" searches
   * vs "other" searches.  Considered soft searches.
   */
  getPersonalSearches(): Array<SearchInquiry> {
    let data = [];

    if (this.SearchHistory.CurrentAddressUserSearches.SearchRecord) {
      data = data.concat(this.SearchHistory.CurrentAddressUserSearches.SearchRecord);
    }

    if (this.SearchHistory.PreviousAddressUserSearches.SearchRecord) {
      data = data.concat(this.SearchHistory.PreviousAddressUserSearches.SearchRecord);
    }

    return data.sort(Report.sortSearches);
  }

  getNoticesOfCorrection(): Array<NoticeOfCorrection> {
    let notices: Array<NoticeOfCorrection> = []
    if (this.NoticesOfCorrection.hasOwnProperty('Records') && this.NoticesOfCorrection.Records.hasOwnProperty('Notice')) {
      notices = this.NoticesOfCorrection.Records.Notice;
    }
    return notices;
  }
}
