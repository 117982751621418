import {AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {SummaryStats} from 'src/app/shared/models/summary-stats';
import {ActivatedRoute, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {DisputesService} from 'src/app/shared/services/disputes.service';
import {SummaryNavEvent} from '../../events/summary-nav-event';
import {IAccount} from 'src/app/shared/models/report/i-account';
import {filter, map, tap} from 'rxjs/operators';
import {Location} from '@angular/common';


@Component({
  selector: 'app-summary-section-nav',
  templateUrl: './summary-section-nav.component.html',
  styleUrls: ['./summary-section-nav.component.scss']
})
export class SummarySectionNavComponent implements OnInit {

  public summaryStats: SummaryStats;
  public hasValues = false;
  public openDisputes = [];

  @Input('activeSummary') active: string;
  @Output() summaryNavChangeEvent = new EventEmitter<SummaryNavEvent>();

  private state;

  constructor(
    private reportService: CreditReportService,
    private router: Router,
    private disputeService: DisputesService,
  ) {}

  /**
   * Initialize summary stats and fetch values
   */
  ngOnInit() {
    this.summaryStats = new SummaryStats();

    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.refreshStats();
      });

    this.disputeService
      .get()
      .subscribe(resp => {
        const disputes = Object.keys(resp).map(key=>resp[key]);

        this.openDisputes = disputes.filter(val => val['meta'].is_open);
      });

    this.reportService.shouldUpdate.subscribe( () => {
      this.reportService
        .get()
        .subscribe((creditReport: CreditReport) => {
          this.refreshStats();
        });
    });
  }


  /**
   * Refresh displayed stats
   */
  refreshStats() {
    this.summaryStats = this.reportService.getSummaryStats();
    this.hasValues = true;
  }


  /**
   * Programmatic Navigation
   * @param section The route child (e.g. { outlets: { 'summary': ['summary-credit-score'] } })
   */
  goto(section) {
    this.router.navigate(['/dashboard/home', section]);

  }

}
