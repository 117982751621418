import {Component, OnInit} from '@angular/core';
import {PasswordResetRequest} from 'src/app/shared//models/auth/password-reset-request';
import {AccountService} from 'src/app/shared//services/account.service';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductService} from 'src/app/shared//services/product.service';
import {ProductInterface} from 'src/app/shared//models/product.interface';
import {Observable} from 'rxjs';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';

/**
 * PasswordResetRequestComponent
 *
 * This is step 2 of the password reset process.  A user lands here after clicking the link provided via email
 */
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  public passwordResetRequest: PasswordResetRequest = {
    email: '',
    password: '',
    password_confirmation: '',
    token: ''
  };

  product$: Observable<ProductInterface>;

  public errors: Array<any> = [];

  constructor(
    private accountService: AccountService,
    private productService: ProductService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // Ensure state has product data.
    productService.fetch().subscribe(genericErrorObserver);
  }

  /**
   * Init component with url params
   */
  ngOnInit() {
    // Get token from the route
    this.route.paramMap.subscribe((map: Params) => {
      this.passwordResetRequest.token = map.params.token;
    });

    // Get the email from querystring
    this.passwordResetRequest.email = this.route.snapshot.queryParamMap.get('email');
    this.route.queryParamMap.subscribe(queryParams => {
      this.passwordResetRequest.email = queryParams.get('email');
      const productId = queryParams.get('prid');
      this.getProduct(productId);
    });
  }

  /**
   * Get product user is member of
   */
  private getProduct(productId): void {
    if (!productId) {
      productId = 1;
    }

    this.product$ = this.productService.getById(productId);
  }


  /**
   * Do the reset
   */
  resetPassword(): void {

    this.accountService.passwordReset(this.passwordResetRequest)
      .subscribe({
        next: (resp) => {
          if (resp.hasOwnProperty('msg')) {
            this.toastService.success(resp['msg']);
          } else {
            this.toastService.success(resp);
          }

          // Return to login
          this.router.navigateByUrl('auth/login');
        },
        error: (httpErrorResponse: HttpErrorResponse) => {
          this.handleValidationErrors(httpErrorResponse);
        }
      });
  }


  /**
   * Handle form validation errors
   */
  private handleValidationErrors(httpErrorResponse: HttpErrorResponse): void {

    if (httpErrorResponse.status === 422) {
      Object.keys(httpErrorResponse.error.errors).forEach(fieldname => {
        this.errors[fieldname] = httpErrorResponse.error.errors[fieldname];
        this.errors[fieldname].forEach(error => {
          this.toastService.error(error);
        });
      });

      this.errors = httpErrorResponse.error.errors;
      // TODO: highlight form fields
    }
  }
}
