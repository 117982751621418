import {Component, OnInit} from '@angular/core';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from 'src/app/core/services/history.service';
import {ToastService} from 'src/app/core/components/toasts/toast.service';

/**
 * This is the page that google redirects the customer to after they have logged in with their google account.  It
 * will have the auth code in the query params.
 */
@Component({
  selector: 'app-google-authenticate',
  templateUrl: './google-authenticate.component.html',
  styleUrls: ['./google-authenticate.component.scss']
})
export class GoogleAuthenticateComponent implements OnInit {

  constructor(
    private loadIndicatorService: LoadIndicatorService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private historyService: HistoryService,
    private notificationService: ToastService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.loadIndicatorService.push('google-auth');

    const googleCode = this.route.snapshot.queryParams['code'];
    const redirectUri = window.location.origin + window.location.pathname;

    this
      .authService
      .googleLogin(googleCode, redirectUri)
      .subscribe(
        resp => {
          this.accountService.fetch().subscribe(() => {
            this.gotoLandingPage();
            this.loadIndicatorService.pop('google-auth');
          });
        },
        err => {
          const queryParams = {};

          if (err.error.message === 'must_login_first') {
            queryParams['google-messaging'] = 1;
          } else if (err.status !== 429){
            this.notificationService.error('Invalid login.  Please try again.');
          }

          this.router.navigate(['/auth/login'], {queryParams});
          this.loadIndicatorService.pop('google-auth');
        }
      );
  }

  /**
   * Check if last visited route exists and go there, else default to dashboard.
   */
  private gotoLandingPage(): void {
    const lastVisitedRoute = this.historyService.consumeLastVisitedRoute();

    if (lastVisitedRoute) {
      this.router.navigateByUrl(lastVisitedRoute);
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }
}
