import {DarkwebScan} from '../models/darkweb-scan';
import {SearchCriterionResponse} from 'src/app/modules/dark-web/models/search-criterion-response';
import {DarkwebSummary} from 'src/app/modules/dark-web/models/darkweb-summary';


export namespace DarkwebActions {

  export class UpdateScan {
    static readonly type = '[Darkweb] Update Scan';
    constructor(public scan: DarkwebScan) {}
  }

  // export class UpdateEmails {
  //   static readonly type = '[Darkweb] Update Emails';
  //   constructor(public emails: EmailsResponse) {}
  // }

  export class UpdateSearchCriterion {
    static readonly type = '[Darkweb] Update Emails';
    constructor(public searchCriterion: SearchCriterionResponse) {}
  }

  export class UpdateSummary {
    static readonly type = '[Darkweb] Update Summary';
    constructor(public summary: DarkwebSummary) {}
  }
}
