import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-employment-form',
  templateUrl: './employment-form.component.html',
  styleUrls: ['./employment-form.component.scss']
})
export class EmploymentFormComponent implements OnInit {

  // Provides access to the parent FormGroup
  @Input() formGroup: UntypedFormGroup;

  // Provides access to the parent form errors
  @Input() errors: object;

  /**
   * Parent form submit state
   */
  @Input() isSubmitted: boolean;

  public employmentStatuses = [
    'Full-Time Employment',
    'Part-Time Employment',
    'Temporary/Contract',
    'Self-Employed',
    'Benefits',
    'Education',
    'Retired',
    'Other',
    'Homemaker',
    'Carer',
    'Armed Services'
  ];

  constructor(
    public formBuilder: UntypedFormBuilder,
  ) {

  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.addEmployer();
  }

  get employments(): UntypedFormArray {
    return this.formGroup.get('employments') as UntypedFormArray;
  }

  addEmployer(): void {
    this.employments.push(this.newEmployer());
  }

  /**
   * Dynamically add additional employer FormGroups
   */
  newEmployer(): UntypedFormGroup {
    return this.formBuilder.group({
      employer: ['', [
        Validators.required,
        // Validators.pattern('^[0-9]*$'),
        // Validators.minLength(6),
        // Validators.maxLength(6)
      ]],
      employmentStatus: ['', [
        Validators.required
      ]],
      postCode: ['', [
        Validators.required
      ]],
      jobTitle: ['', [
        Validators.required,
      ]],
      timeAtEmployerYears: ['', [
        Validators.required
      ]],
      timeAtEmployerMonths: ['', [
        Validators.required
      ]],
      netMonthlyIncome: ['', [
        Validators.required
      ]],
    });
  }

  removeEmployer(index) {
    this.employments.removeAt(index);
  }

  /**
   * Returns true if formcontrol has any errors
   */
  isInvalid(index, fieldName): boolean {
    return this.employments.at(index).get(fieldName).invalid;
  }

  /**
   * Return true if formcontrol has the specific error.
   */
  hasError(index, fieldName, errorName): boolean {
    return this.employments.at(index).get(fieldName).hasError(errorName);
  }
}
