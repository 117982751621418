import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-force-password-reset',
  templateUrl: './force-password-reset.component.html',
  styleUrls: ['./force-password-reset.component.scss']
})
export class ForcePasswordResetComponent {

  constructor(private router: Router) {
  }

  passwordUpdated(): void {
    this.router.navigateByUrl('/dashboard');
  }
}
