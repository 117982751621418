<div class="container">

  <div class="text-center">

      <div class="pt-5 pb-5">
        <a routerLink="/auth/login">
          <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
        </a>
      </div>

      <h4>You have logged out of the system</h4>


      <p class="pt-5 pb-5">Please click <a routerLink="/auth/login">here</a> if you wish to log in again.</p>
  </div>

</div>
