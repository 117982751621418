import {CalendlyEvent} from 'src/app/shared/models/calendly/event';

export namespace EventActions {

  export class UpdateEvent {
    static readonly type = '[Session] Update Events';
    constructor(public readonly event: CalendlyEvent) {
    }
  }

  export class ClearState {
    static readonly type = 'ClearState';

    constructor() {
    }
  }
}
