<div class="pad-resp-card py-5 mx-2 text-center">

  <div class="text-center pb-3">
    <img id="icon" src="assets/images/cta/ICN_DarkWeb_Color.svg" alt="Dark Web monitoring"/>
  </div>

  <h2 class="pb-3">Get Your Dark Web Report</h2>

  <p class="text-justify">
    <strong>To view the results of your Dark Web report, please click on the 'verify' link we've just sent to your by email.</strong>
  We ask you to do this to ensure that only the true email holder can access your DarkWeb report as it may contain sensitive information, including login's, passwords, card and account numbers.
  </p>

  <p>
    <strong>You can view your DarkWeb Report anytime via the MENU section of your account.</strong>
  </p>

  <div class="d-grid col-6 mx-auto pt-3">
    <button class="btn btn-primary px-4 rounded-pill btn-lg" (click)="close()" id="dark-web-iao-button">Continue</button>
  </div>


<!--  <div class="mt-2 d-grid">-->
<!--    <a class="font-small font-ukc-blue rounded-pill w-50" (click)="close()">No thanks</a>-->
<!--  </div>-->

</div>
