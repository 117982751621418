import {Injectable} from '@angular/core';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';
import {Chainable} from 'src/app/modules/iao-chain/models/chainable';
import {take} from 'rxjs/operators';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserStatus} from 'src/app/shared/models/user-status';
import {HowToComponent} from 'src/app/modules/iao-chain/intro/how-to/how-to.component';

@Injectable({
  providedIn: 'root',
})
export class HowToService implements Chainable {

  private account: IAccount;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
  ) {}

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService.getAccount()
        .pipe(take(1))
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return (!this.account.settings.hasSeenIntro
      && this.account.user.status !== UserStatus.Manual
      && this.account.user.status !== UserStatus.ThinFile
      && this.account.user.status !== UserStatus.BrokenEnrollment
      && window.location.pathname === '/dashboard/home');
  }

  run() {
    const modalRef = this.modalService.open(HowToComponent, {
      fullscreen: true,
      scrollable: true,
      keyboard: true
    });

    return modalRef.result;
  }

  onIaoClose() {
    this.accountService
      .updateSetting('hasSeenIntro', true)
      .subscribe(genericErrorObserver);
  }
}
