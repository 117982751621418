import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AccountService} from '../../../../shared/services/account.service';
import {UpdatePasswordRequest} from '../../../../shared/models/account/update-password-request';
import {ToastService} from '../../../../core/components/toasts/toast.service';
import {genericErrorObserver} from '../../../../shared/observables/generic-error.observer';
import {NgClass, NgIf} from '@angular/common';
import {GoogleIntegrationComponent} from '../google-integration/google-integration.component';


@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  imports: [
    NgClass,
    ReactiveFormsModule,
    NgIf,
    GoogleIntegrationComponent
  ],
  standalone: true
})
export class PasswordComponent {
  public passwordResetForm: UntypedFormGroup;
  public isSubmitted = false;

  @Output() passwordUpdated = new EventEmitter();
  @Input() showSocialLogins = true;

  // tslint:disable-next-line:variable-name
  private _showCurrentPassword = true;

  get showCurrentPassword(): boolean {
    return this._showCurrentPassword;
  }

  /**
   * This is only for display and front end validation purposes.  The server will still require the current password if we are updating a
   * password.  The only time it's NOT required is when we are creating the password for the first time.
   */
  @Input() set showCurrentPassword(value: boolean) {
    this._showCurrentPassword = value;

    if (this._showCurrentPassword && !this.passwordResetForm.contains('password')) {
      this.passwordResetForm.addControl('password', new UntypedFormControl('', [Validators.required]));
    } else if (!this._showCurrentPassword && this.passwordResetForm.contains('password')) {
      this.passwordResetForm.removeControl('password');
    }
  }

  constructor(
    private accountService: AccountService,
    formBuilder: UntypedFormBuilder,
    private toastService: ToastService
  ) {
    this.passwordResetForm = formBuilder.group({
      password: ['', [
        Validators.required
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(10)
      ]],
      newPassword_confirmation: ['', [
        Validators.required,
        this.checkPasswordsMatch('passwordsnotmatch')
      ]],
    });
  }

  update() {
    if (this.passwordResetForm.dirty && this.passwordResetForm.valid) {
      this.isSubmitted = true;

      const updatePasswordRequest: UpdatePasswordRequest = this.passwordResetForm.value;

      this.accountService
        .updatePassword(updatePasswordRequest)
        .subscribe({
          next: resp => {
            this.toastService.success('Password has been updated');
            this.isSubmitted = false;

            // TODO: not sure why this is here?
            this.accountService.fetchCommunicationPreferences().subscribe(genericErrorObserver);

            this.passwordUpdated.emit();
          },
          error: error => {
            this.toastService.error('Password update failed');
            this.isSubmitted = false;
          }
        });
    }
  }

  /**
   * getter for GUI access of validators
   */
  get formControls() {
    return this.passwordResetForm.controls;
  }

  /**
   * Custom form validation to check if passwords match
   */
  private checkPasswordsMatch(errorType: string): (input: UntypedFormControl) => null | object {
    return (input: UntypedFormControl): null | object => {
      if (!input.root || !input.root.get('newPassword')) {
        return null;
      }

      const isMatch = input.root.get('newPassword').value === input.value;

      return isMatch ? null : {[errorType]: true};
    };
  }

  /**
   * Detect if form values have changed
   */
  hasChanges(): boolean {
    return this.passwordResetForm.dirty;
  }
}
