<ng-template #accepted>
  <p class="pt-4 pb-2 px-2">
    Congratulations! You have
    now achieved the credit score
    necessary to qualify for car
    finance. Please click the button below to go through to the
    Cargiant page where you can
    start finding and financing
    the car you love.
  </p>

  <div class="d-grid gap-1 col-12-sm offset-sm-0 col-md-6 offset-md-3">
    <a class="btn btn-primary" href="https://www.cargiant.co.uk/search/" target="_blank">Continue to Cargiant</a>
    <button
      class="btn btn-secondary mt-3 mb-3 px-5"
      (click)="close()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #declined>
  <p class="pt-4 pb-2 px-2 text-justify">
    Currently, your credit score is
    not at the threshold needed
    to be accepted for car
    finance.
    <span *ngIf="(hasEvent$ | async) else template_schedule_review">
      To take steps steps to improve your score, please contact us via our live chat or discuss in your upcoming personalized
      credit review scheduled for:
      <div class="text-center fw-bolder pt-2">{{(event$ | async)?.start_time | dateTimeFormat:	'D MMM Y, HH:mm:ss a'}}</div>
    </span>
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>


<ng-template #template_schedule_review>
  Please contact us via our live chat or <a (click)="book()" class="clickable">click here</a> to book a meeting with one
  of our UK Credit Ratings Team to take steps to improve your score.
</ng-template>


<ng-template #noscoremv>
  <p class="pt-4 pb-2 px-2">
    Your credit score is currently unavailable so we are unable to determine eligibility until you verify your identity.  Please follow the instructions
    <a class="clickable" (click)="navigate('/info/manual-verify')">here</a>.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>

<ng-template #noscorebe>
  <p class="pt-4 pb-2 px-2">
    Your credit score is currently unavailable so we are unable to determine eligibility until you verify your identity.  Please follow the "Complete Your Registration" Link
    on the dashboard to verify your identity.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>

<ng-template #noscorethin>
  <p class="pt-4 pb-2 px-2">
    Your credit score is currently unavailable so we are unable to determine eligibility.  Please see
    <a class="clickable" (click)="navigate('/edu/thin')">Thin File Status</a> for more info.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>

<ng-template #noscoreother>
  <p class="pt-4 pb-2 px-2">
    Your credit score is currently unavailable so we are unable to determine eligibility until you verify your identity.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>



<div class="modal-body">

  <div class="py-2 text-center">
    <div class="icon-container">
      <img class="my-3 img-fluid justify-content-center align-self-center h-100" src='/assets/images/cta/LGO_Car_Giant.png' />
    </div>

    <div class="pt-2">
      <div [ngTemplateOutlet]="templateName"></div>
    </div>

  </div>
</div>
