<h3>Dark web Monitoring</h3>

<app-risk-header
  *ngIf="(hasSummaryData$ | async) else emptyData"
  [risks]="(summary$ | async)?.risks"
  [hideZeros]="true"
  [limit6]="true">
</app-risk-header>

<ng-template #emptyData>
  <p  class="py-3 pad-resp" *ngIf="(summary$ | async) === null || !(hasSummaryData$ | async)">We are working on pulling dark web data for you...</p>

  <p class="py-3 pad-resp" *ngIf="(summary$ | async) && (hasSummaryData$ | async)">A Dark Web search carried out on
    {{ (summary$ | async)?.lastScanDate | dateTimeFormat: config.dateFormat : config.timezone }} revealed that none of your personal
    information has been exposed online. </p>
</ng-template>

<a class="clickable" [routerLink]="['/dark-web']" *ngIf="hasSummaryData$ | async">View Now</a>
