import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {IAccount} from 'src/app/shared/models/account';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {take} from 'rxjs/operators';
import {SmartOfferService} from 'src/app/shared/services/smart-offer-service';
import {filterNilVal} from 'src/app/shared/utils/filters';

@Injectable({
  providedIn: 'root'
})
export class SmartOfferChainService implements Chainable {

  private account: IAccount;

  constructor(private store: Store,
              private smartOfferService: SmartOfferService) {
  }

  canDisplay(): boolean {
    return !this.account.settings.hasSeenSmartOffers;
  }

  onIaoClose(): void {
  }

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.store.select(SessionState.getAccount)
        .pipe(
          filterNilVal(),
          take(1)
        )
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  run(): Promise<any> {
    return this.smartOfferService.openOfferConsentModal();
  }
}
