import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable, Subject } from 'rxjs';

import {AuthService} from '../services/auth.service';


/**
 * Prevent back button to the login or auth processing pages when user is already authenticated
 *
 * To use:
 *    Add guard to your login related routes
 *    {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
 *    {path: 'google', component: GoogleComponent, canActivate: [LoginGuard]}
 */
@Injectable()
export class LoginGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isAuthenticated()) {
            this.router.navigateByUrl('/dashboard');
            return false;
        } else {
            return true;
        }
    }
}
