import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {PasswordResetRequestComponent} from './pages/password/password-reset-request/password-reset-request.component';
import {PasswordResetComponent} from './pages/password/password-reset/password-reset.component';
import {StoreTokenComponent} from './pages/store-token/store-token.component';
import {ForcePasswordResetComponent} from './pages/force-password-reset/force-password-reset.component';
import {MustFinishResetPasswordGuard} from './route-guards/must-finish-reset-password-guard.service';
import {AuthGuard} from '../../core/guards/auth.guard';
import {GoogleAuthenticateComponent} from './pages/google-authenticate/google-authenticate.component';
import {OneTimePasswordLoginComponent} from './pages/one-time-password-login/one-time-password-login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: 'password/reset',
        component: PasswordResetRequestComponent
      },
      {
        path: 'password/reset/:token',
        component: PasswordResetComponent
      },
      {
        path: 'store/:token',
        component: StoreTokenComponent,
      },
      {
        path: 'reset-password',
        component: ForcePasswordResetComponent,
        canDeactivate: [MustFinishResetPasswordGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'google',
        component: GoogleAuthenticateComponent,
      },
      {
        path: 'otp',
        component: OneTimePasswordLoginComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
