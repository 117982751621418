import {EventEmitter, Injectable, Output} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, share, tap, timeout} from 'rxjs/operators';
import {CreditReport} from '../models/credit-report';
import {CreditReportInterface} from '../models/credit-report.interface';
import {AccountType} from '../models/report/account-type.enum';
import {Report} from '../models/report/report';
import {ToastService} from '../../core/components/toasts/toast.service';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {UpdateCreditAlerts, UpdateReport} from 'src/app/shared/state/credit-report/credit-report.actions';
import {CreditReportState} from 'src/app/shared/state/credit-report/credit-report-state.service';
import {CreditAlertModel} from 'src/app/modules/alerts/models/credit-alert.model';

@Injectable({
  providedIn: 'root'
})
export class CreditReportService {

  public creditReport: CreditReport = null;

  @Output() shouldUpdate: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Track if we are fetching already to prevent repeat calls to backend
   * @type {boolean}
   */
  protected isFetching = false;

  /**
   * Observable for current fetch
   */
  protected reportObservable: Observable<CreditReport> = null;


  /**
   * Notification to any subscribers of report updates
   * Note: BehaviorSubject's start with a value, and emit immediately upon subscription.  This is
   * as opposed to a Subject, which would not emit to subscribers if they subscribe _after_ an emit
   */
  protected reportFetchEvent = new BehaviorSubject<CreditReport|null>(this.creditReport);
  public reportFetchEventObs: Observable<CreditReport|null> = this.reportFetchEvent;

  constructor(
    private http: HttpClient,
    private store: Store,
    private toastService: ToastService
  ) {
    // Clear out local cache when we logout
    this.store.select(SessionState.getToken)
      .subscribe(result => {
        if (!result) {
          this.creditReport = undefined;
        }
      });
  }


  /**
   * Get user from cache if available or fetch from server
   * @param force When TRUE, Force cache deletion and refetch from sever
   * @returns Observable<User>
   */
  public get(force: boolean = false): Observable<CreditReport> {

    if (force === true) {
      this.creditReport = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.creditReport) {
      return of<CreditReport>(this.creditReport);
    }

    return this.fetch();
  }


  /**
   * Fetch User Entity from server and cache locally
   */
  private fetch(): Observable<CreditReport> {

    if (this.isFetching === false) {
      this.isFetching = true;

      this.reportObservable = this.http
        .get('/api/report')
        .pipe(
          timeout(20000),
          catchError(() => {
            // catches timeout and returns an observable picked up by map()
            const cr = new CreditReport();
            cr.reportUnavailable = true;
            return of(cr);
          }),
          share(),
          map(
            resp => {
              const cr = new CreditReport();

              // Credit report could be null if they are manual verify or thin file.
              if (resp !== null) {
                cr.fromJson(resp as CreditReportInterface);
              }

              if (cr.reportUnavailable === true){
                this.toastService.error('We are unable to fetch your credit report at this time. Please try again later.', 'Credit Report Unavailable');
              }

              return cr;
            }
          ),
          tap(
            report => {
              this.isFetching = false;
              this.creditReport = report;
              this.reportFetchEvent.next(report);

              this.store.dispatch(new UpdateReport(report));
            }
          )
        );
    }

    return this.reportObservable;
  }

  getAlerts(): Observable<CreditAlertModel[]> {
    return this.store.select(CreditReportState.getAlerts);
  }

  fetchAlerts(): Observable<CreditAlertModel[]> {
    return this.http
        .get<CreditAlertModel[]>('/api/alerts')
        .pipe(
          share(),
          tap(
            alerts => {
              this.store.dispatch(new UpdateCreditAlerts(alerts));
            }
          )
        );
  }

  public emitShouldUpdate(){
    this.shouldUpdate.emit(true);
  }


  getSummaryStats() {
    return this.creditReport.summary;
  }

  getReport(): Report {
    return this.creditReport.report;
  }

  getAccount(accountType: AccountType, accountId: number) {
    return this.creditReport.getAccount(accountType, accountId);
  }

  getFinancialAccounts(){
    return this.creditReport.financialAccounts;
  }

  getNegativeMarks() {
    return this.creditReport.negativeMarks;
  }

  getInquiries() {
    return this.creditReport.inquiries;
  }
}
