/**
 * Maps paths within the Angular application into a pretty label for analytics tracking to use
 */
export class PageViewMap {
  private static pathMap = {
    '/dashboard/home': 'Dashboard',
    '/auth/login': 'Login',
    '/account/member-profile': 'Member Profile',
    '/dashboard/home/(summary:summary-negative-marks)': 'Dashboard Summary - Negative Marks',
    '/dashboard/home/(summary:summary-credit-score)': 'Dashboard Summary - Credit Score',
    '/dashboard/home/(summary:summary-payment-history)': 'Dashboard Summary - Payment History',
    '/dashboard/home/(summary:summary-hard-inquiries)': 'Dashboard Summary - Hard Inquiries',
    '/dashboard/home/(summary:summary-credit-card-utilization)': 'Dashboard Summary - Card Utilization',
    '/dashboard/home/(summary:summary-disputes)': 'Dashboard Summary - Disputes',
    '/alerts/list': 'Alerts',
    '/account/email-report': 'Email report',
    '/account/web-post-report': 'Web post report',
    '/edu/home': 'Credit Education',
    '/dark-web/summary': 'Darkweb Summary',
    '/public-private-data/home': 'Public/private data',
    '/financial-data/home': 'Financial Data',
    '/enquiries/list': 'Credit Enquiries',
    '/credit-utilization/home': 'Credit Utilization',
    '/disputes/list': 'Disputes',
    '/corrections/notices': 'Notices of Correction',
    '/customer-service/home': 'Customer Service',
    '/auth/logout': 'Logout',
  };

  static get(path: string): string {
    path = path.toLowerCase();

    if (this.pathMap.hasOwnProperty(path)) {
      return this.pathMap[path];
    }

    return '';
  }
}
