import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {filter, Observable} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';
import {Account, IAccount} from '../models/account';
import {UpdatePasswordRequest} from '../models/account/update-password-request';
import {UpdatePiiRequest} from '../models/account/update-pii-request';
import {GoCardlessRequest} from '../models/account/go-cardless-request';
import {UpdatePreferencesRequest} from '../models/account/update-preferences-request';
import {Preferences} from '../models/preferences';
import {AccountSettings} from '../models/account-settings.interface';
import {RegisterResultInterface} from '../models/register-result.interface';
import {UserStatus} from '../models/user-status';
import {genericErrorObserver} from '../observables/generic-error.observer';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {SessionActions} from '../state/session/session.actions';
import {filterNilVal} from 'src/app/shared/utils/filters';
import UpdatePreferences = SessionActions.UpdatePreferences;
import UpdateAccount = SessionActions.UpdateAccount;
import UpdateAccountSettings = SessionActions.UpdateAccountSettings;
import UpdateAccountStatus = SessionActions.UpdateAccountStatus;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
  }

  getAccount() {
    return this.store
      .select(SessionState.getAccount)
      .pipe(filter(account => account != null));
  }

  getPreferences() {
    return this.store
      .select(SessionState.getPreferences)
      .pipe(filter(preferences => preferences != null));
  }


  /**
   * Fetch User Entity from server and cache locally
   * @param hardRefresh Forces a pull from checkout, preventing use of the portal's user cache
   */
  fetch(hardRefresh = false): Observable<Account> {

    const options = {};
    if (hardRefresh) {
      options['params'] = {'hard-refresh': 1};
    }

    return this.http
      .get<Account>('/api/account', options)
      .pipe(
        share(),
        tap(
          acct => {
            this.store.dispatch(new UpdateAccount(acct));
          }
        )
      );
  }


  /**
   * Update Account Pii
   */
  updatePiiInfo(updatePiiRequest: UpdatePiiRequest): Observable<Account> {
    return this.http
      .put<Account>('/api/account/member', updatePiiRequest)
      .pipe(
        share(),
        tap(
          acct => this.store.dispatch(new UpdateAccount(acct))
          )
      );
  }

  /**
   * Update communication preferences
   */
  updateCommunicationPreferences(updatePreferencesRequest: UpdatePreferencesRequest): Observable<Preferences> {
    return this.http
      .put('/api/account/preferences', updatePreferencesRequest)
      .pipe(
        map(() => updatePreferencesRequest),  // Cheat, taking the request object and making it look like a response object
        tap(pref => this.store.dispatch(new UpdatePreferences(pref))), // Update session store with new preferences
        share()
      );
  }

  fetchCommunicationPreferences(): Observable<Preferences> {
    return this.http
      .get<Preferences>('/api/account/preferences')
      .pipe(
        share(),
        tap(resp => this.store.dispatch(new UpdatePreferences(resp)))
      );
  }

  /**
   * Update account password
   */
  updatePassword(updatePasswordRequest: UpdatePasswordRequest) {
    return this.http
      .post('/api/account/change_password', updatePasswordRequest)
      .pipe(
        tap(
          () => {
            this.store.dispatch(new UpdateAccountSettings({forcePasswordReset: false} ));
          },
        )
      );
  }


  signupGoCardless(goCardlessRequest: GoCardlessRequest) {
    return this.http
      .post<Account>('/api/account/signup-go-cardless', goCardlessRequest)
      .pipe(
        share(),
        tap(acct => this.store.dispatch(new UpdateAccount(acct)))
      );
  }


  /**
   * This is for broken enrollment - register with data provider (with this iovation blackbox)
   */
  registerAtTransunion(data = null): Observable<RegisterResultInterface> {
    const blackbox = (window as any).IGLOO.getBlackbox();
    let postData = {};

    // Where this was originally a string, it now appears to be coming as {blackbox: 'somehash', finished: true}
    if (typeof blackbox === 'object') {
      postData['blackbox'] = blackbox.blackbox;
    }

    if (data) {
      postData = Object.assign(postData, data);
    }

    return this.http.post<RegisterResultInterface>('/api/account/register', postData).pipe(
      share(),
      tap(
        resp => {
          this.fetch().subscribe(genericErrorObserver); // Account was refreshed server side.  Fetch the updated account.
        }
      )
    );
  }

  /**
   * This is for broken enrollment - return kba
   */
  public answerKba(answers: any): Observable<any> {
    return this.http.post('/api/account/register/kba', {answers}).pipe(
      share(),
      tap(
        resp => {
          this.fetch().subscribe(genericErrorObserver); // Account was refreshed server side.  Fetch the updated account.
        }
      )
    );
  }

  updateSettings(updateSettingsRequest: AccountSettings): Observable<AccountSettings> {
    return this.http
      .post<AccountSettings>('/api/account/update-settings', updateSettingsRequest)
      .pipe(
        share(),
        tap(
          resp => {
            this.store.dispatch(new UpdateAccountSettings(resp));
          }
        )
      );
  }

  updateSetting(key, value = null): Observable<AccountSettings> {

    let setting = {};

    // Allow objects to be passed in key variable
    if (typeof key === 'object' &&
      !Array.isArray(key) &&
      key !== null) {

      setting = key;
    } else {
      setting[key] = value;
    }

    return this.http
      .post<AccountSettings>('/api/account/update-settings', setting)
      .pipe(
        tap(
          resp => {
            this.store.dispatch(new UpdateAccountSettings(resp));
          }
        )
      );
  }

  public requestPasswordResetEmail(request) {
    return this.http.post('/api/password/email', request);
  }

  public passwordReset(request) {
    return this.http.post('/api/password/reset', request);
  }

  /**
   * Naming this setUserStatus because it is setting the client side only and not calling for an update operation to the server.  Replaces
   * this.account.user.status = xxx statements that stopped working due to readonly status of account object.
   */
  setUserStatus(userStatus: UserStatus): void {
    this.store.dispatch(new UpdateAccountStatus(userStatus));
  }

  /**
   * Checks Account's scope to see if they have access
   */
  hasAccessScope(scope: string): Observable<boolean> {

    return this.store.select(SessionState.getAccount)
      .pipe(
        filterNilVal(),
        map((account: IAccount) => {
          return account.user.access_scopes.indexOf('*') > -1 || account.user.access_scopes.indexOf(scope) > -1;
        })
    );
  }
}
