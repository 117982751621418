import {EventEmitter, Injectable} from '@angular/core';
import {IaoEventTypeEnum} from './iao-event-type.enum';

@Injectable({
  providedIn: 'root'
})
export class IaoEventService {

  iaoCloseEvent = new EventEmitter<IaoEventTypeEnum>();

  constructor() { }

  private notify(e: IaoEventTypeEnum): void {
    this.iaoCloseEvent.emit(e);
  }

  /**
   * If IAO chain has another provider in line, run it
   */
  showNext(): void {
    this.notify(IaoEventTypeEnum.ShowNext);
  }

  /**
   * Start/resume
   */
  start(): void {
    this.notify(IaoEventTypeEnum.Start);
  }

  /**
   * Don't show the next modal in the chain
   */
  stop(): void {
    this.notify(IaoEventTypeEnum.Stop);
  }
}
