import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepickerConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateUkParserFormatter} from '../../../services/ngb-date-uk-parser-formatter.adapter';
import {DateTime} from './date-time';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';


@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [{
    provide: NgbDateParserFormatter,
    useClass: NgbDateUkParserFormatter
  }]
})
export class DateTimePickerComponent implements OnInit {

  public dateTime: DateTime = {
    date: {
      year: 2020,
      month: 1,
      day: 1
    },
    time: {
      hour: 13,
      minute: 30,
      second: 0
    }
  };

  public minuteStep = 5;

  /* TODO: We should add an output for validation status to better integrate with parent forms.  Note, I had
  trouble with getting status as a template driven form with NgBootstraps Date and Time pickers.  Making this
  a Reactive Form Control might make it easier.
   */
  @Output() dateTimeChange = new EventEmitter<DateTime>();


  constructor(private calendar: NgbCalendar,
              public config: NgbInputDatepickerConfig)
  {
    this.initContactDateTime();
  }

  ngOnInit(): void {
  }

  initContactDateTime() {

    // Disallow past dates
    this.config.minDate = this.calendar.getToday();
    this.dateTime.date = this.calendar.getToday();

    const now = new Date();

    // Set maximum datepicker date
    this.config.maxDate = {
      year:  now.getFullYear() + 1,
      month: now.getMonth(),
      day: 1
    };

    const minute = Math.ceil((now.getMinutes() + this.minuteStep) / 10) * 10;

    // Set default contact time nearest 10 minute interval in the future
    this.dateTime.time = {
      hour: (minute >= 60) ? now.getHours() + 1 : now.getHours(),
      minute: (minute >= 60) ? 0 : minute,
      second: 0
    };
  }

  /**
   * Notify of value changes on our date/time model
   */
  changed(e: NgbDateStruct | NgbTimeStruct) {
    this.dateTimeChange.emit(this.dateTime);
  }
}
