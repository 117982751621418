import {CaspianInsuranceComponent} from '../components/caspian-insurance/caspian-insurance.component';
import {CarMoneyComponent} from '../components/car-money/car-money.component';
import {FastPerksComponent} from '../components/fast-perks/fast-perks.component';
import {CreditLadderComponent} from '../components/credit-ladder/credit-ladder.component';
import {MoneyAdvisorComponent} from '../components/money-advisor/money-advisor.component';
import {MotorlyComponent} from '../components/motorly/motorly.component';
import {CarGiantComponent} from '../components/car-giant/car-giant.component';

/**
 * Offer Component Dictionary for dynamic loading of CTA Offer Modals.
 */
export const offerComponentMap = {
  CaspianInsuranceComponent,
  CarGiantComponent,
  CarMoneyComponent,
  CreditLadderComponent,
  FastPerksComponent,
  MoneyAdvisorComponent,
  MotorlyComponent
};
