import {Component} from '@angular/core';
import {IaoModalResultEnum} from 'src/app/modules/iao-chain/iao-modal-result.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductInterface} from 'src/app/shared/models/product.interface';
import {filter, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-fast-perks',
  templateUrl: './fast-perks.component.html',
  styleUrls: ['./fast-perks.component.scss'],
})
export class FastPerksComponent {

  public product: ProductInterface;
  public fastPerksUrl = null;

  constructor(
    private activeModal: NgbActiveModal,
  ) {

  }

  close(): void {
    this.activeModal.close(IaoModalResultEnum.ContinueChain);
  }
}
