import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {HistoryService} from 'src/app/core/services/history.service';
import {LoginRequest} from 'src/app/core/models/login-request';
import {AuthService} from 'src/app/core/services/auth.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {BrandService} from 'src/app/shared/services/brand.service';
import {GoogleIntegrationService} from '../../../account/components/google-integration/google-integration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('passwordInput') private passwordInput: ElementRef;

  loginRequest: LoginRequest = {
    email: '',
    password: ''
  };
  googleAuthUrl: string;
  googleMessaging = false;
  private pwResetUrl = '';
  submitting = false;

  constructor(
    private loadIndicatorService: LoadIndicatorService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private historyService: HistoryService,
    private notificationService: ToastService,
    private brandService: BrandService,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.pwResetUrl = this.brandService.getBackendUrl() + '/password/reset';

    this.googleAuthUrl = GoogleIntegrationService.generateGoogleUrl('/auth/google');

    this.activeRoute.queryParamMap.subscribe(parms => {
      if (parms.get('google-messaging')) {
        this.googleMessaging = true;
      }
    });
  }

  /**
   * Local Login
   */
  login() {
    if (this.submitting){
      return; // prevent repeated/stacked login attempts
    }
    this.loadIndicatorService.push('local-auth');
    this.submitting = true;

    this
      .authService.localLogin(this.loginRequest)
      .subscribe({
        next: resp => {
          this.accountService.fetch().subscribe(() => {
            this.gotoLandingPage();
            this.loadIndicatorService.pop('local-auth');
            this.submitting = false;
          });
        },
        error: err => {
          if (err.status !== 429) {
            this.notificationService.error('Invalid login.  Please try again.');
            this.loginRequest.password = '';
            this.passwordInput.nativeElement.focus();
          }

          this.loadIndicatorService.pop('local-auth');
          this.submitting = false;
        }
      });
  }

  /**
   * Check if last visited route exists and go there, else default to dashboard.
   */
  private gotoLandingPage(): void {
    const lastVisitedRoute = this.historyService.consumeLastVisitedRoute();

    if (lastVisitedRoute) {
      this.router.navigateByUrl(lastVisitedRoute);
    } else {
      this.router.navigateByUrl('/dashboard');
    }
  }
}
