import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {BackButtonDirective} from './directives/back-button.directive';
import {BrandImageDirective} from './directives/brand-image.directive';
import {EcceLayoutComponent} from './layouts/ecce/ecce-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout/auth-layout.component';
import {FooterComponent} from './layouts/ecce/footer/footer.component';
import {OfferNavLinks} from './layouts/ecce/category-nav/offer-nav-links.component';
import {DetailPageComponent} from './layouts/ecce/detail-page/detail-page.component';
import {APP_CONFIG, AppConfig} from './config/app.config';
import {CoreModule} from '../core/core.module';
import {RouterModule} from '@angular/router';
import {LineChartComponent} from '../core/components/line-chart/line-chart.component';
import {PieChartComponent} from '../core/components/pie-chart/pie-chart.component';
import {NavComponent} from './layouts/ecce/nav/nav.component';
import {CcDisputeButtonComponent} from './disputes/cc-dispute-button/cc-dispute-button.component';
import {CcDisputeModalComponent} from './disputes/cc-dispute-modal/cc-dispute-modal.component';
import {CcDisputeArrowComponent} from './disputes/cc-dispute-arrow/cc-dispute-arrow.component';
import {InfoLayoutComponent} from './layouts/info/info-layout.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SmartOffersButtonComponent} from './layouts/ecce/smart-offers-button/smart-offers-button.component';
import {SecondaryNavComponent} from './layouts/ecce/secondary-nav/secondary-nav.component';
import {TrialCountdownComponent} from './components/trial-countdown/trial-countdown.component';
import {NavCreditLadderComponent} from '../shared/layouts/ecce/nav/nav-items/nav-credit-ladder/nav-credit-ladder.component';
import { NavFastPerksComponent } from './layouts/ecce/nav/nav-items/nav-fast-perks/nav-fast-perks.component';

@NgModule({
    declarations: [
        BackButtonDirective,
        BrandImageDirective,
        EcceLayoutComponent,
        InfoLayoutComponent,
        AuthLayoutComponent,
        FooterComponent,
        OfferNavLinks,
        DetailPageComponent,
        NavComponent,
        CcDisputeButtonComponent,
        CcDisputeArrowComponent,
        CcDisputeModalComponent,
        SmartOffersButtonComponent,
        SecondaryNavComponent,
        TrialCountdownComponent,
        NavCreditLadderComponent,
        NavFastPerksComponent
    ],
    imports: [
        ClipboardModule,
        CommonModule,
        CoreModule,
        RouterModule,
    ],
    providers: [
        {
            provide: APP_CONFIG,
            useValue: AppConfig,
        },
        CurrencyPipe,
        // Needs test to be sure global formatter doesn't break existing uses of NgbDatePicker
        // {
        //   provide: NgbDateParserFormatter,
        //   useClass: NgbDateUkParserFormatter
        // }
    ],
    exports: [
        BrandImageDirective,
        BackButtonDirective,
        OfferNavLinks,
        FooterComponent,
        EcceLayoutComponent,
        InfoLayoutComponent,
        AuthLayoutComponent,
        DetailPageComponent,
        LineChartComponent,
        PieChartComponent,
        CcDisputeButtonComponent,
        CcDisputeArrowComponent,
        SmartOffersButtonComponent,
        TrialCountdownComponent
    ]
})
export class SharedModule { }
