import {Component, DoCheck, OnInit} from '@angular/core';
import { LoadIndicatorService } from './loadindicator.service';
import {LoadIndicatorStatus} from './load-indicator-status.enum';

@Component({
  selector: 'app-loadindicator',
  templateUrl: 'loadindicator.component.html',
  styleUrls: ['loadindicator.component.css']
})
export class LoadindicatorComponent implements OnInit {

  public isVisible = false;
  private watchdogTimer = null;
  private watchdogTimeout = 30000;

  constructor(private loadIndicatorService: LoadIndicatorService) {
    this.loadIndicatorService
        .pushPopEvent
        .subscribe(event => this.checkIndicatorStatus(event));
  }

  /**
   * Event driven show/hide for the load indicator
   * @param event
   */
  private checkIndicatorStatus(event) {

    if (event === LoadIndicatorStatus.SHOW) {
      this.show();
    } else {
      this.hide();
    }
  }

  ngOnInit() {
  }

  private show() {

    // This setTimeout hack has no delay and prevents the Angular ExpressionChangedAfterItHasBeenCheckedError bug
    setTimeout(() => {
      this.isVisible = true;
    });

    // cancel any previous watchdog timer and set a new one
    if (this.watchdogTimer) {
      clearTimeout(this.watchdogTimer);
    }

    this.watchdogTimer = setTimeout(() => {
      this.hide();
    }, this.watchdogTimeout);
  }


  private hide() {

    if (this.watchdogTimer) {
      clearTimeout(this.watchdogTimer);
    }

    this.isVisible = false;

    setTimeout(() => {
        this.isVisible = false;
      }, 500);
  }
}
