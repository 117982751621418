import {Component} from '@angular/core';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
  standalone: true,
})
export class SurveyModalComponent {

  constructor(public activeModal: NgbActiveModal) {
  }

  clickButton() {
    MixpanelService.track('Click exit survey button');

    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdt1G7Ltnd7mLSoeIMKcUAtjK-r4m-jMiV8NRTsxGMJiOqX-w/viewform?usp=sf_link', '_blank');

    this.activeModal.close();
  }
}
