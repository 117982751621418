import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Account, IAccount} from '../../models/account';
import {Preferences} from '../../models/preferences';
import {SessionActions} from './session.actions';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {patch} from '@ngxs/store/operators';
import {ProductInterface} from 'src/app/shared/models/product.interface';
import UpdateToken = SessionActions.UpdateToken;
import UpdatePreferences = SessionActions.UpdatePreferences;
import UpdateAccount = SessionActions.UpdateAccount;
import ClearState = SessionActions.ClearState;
import UpdateAccountSettings = SessionActions.UpdateAccountSettings;
import UpdateBillingInfo = SessionActions.UpdateBillingInfo;
import UpdateAccountStatus = SessionActions.UpdateAccountStatus;
import UpdateProducts = SessionActions.UpdateProducts;


export interface SessionStateModel {
  token: string;
  account: IAccount;
  preferences: Preferences;
  products: ProductInterface[];
}


const sessionStateDefaults: SessionStateModel = {
  token: '',
  account: undefined,
  preferences: undefined,
  products: [],
};


@State<SessionStateModel>({
  name: 'session',
  defaults: sessionStateDefaults
})


@Injectable()
export class SessionState {

  constructor(private http: HttpClient) {
  }

  @Selector()
  static get(state: SessionStateModel) {
    return state;
  }

  @Selector()
  static getAccount(state: SessionStateModel) {
    return state.account;
  }

  @Selector()
  static getPreferences(state: SessionStateModel) {
    return state.preferences;
  }

  @Selector()
  static getProducts(state: SessionStateModel) {
    return state.products;
  }

  @Selector()
  static getToken(state: SessionStateModel) {
    return state.token;
  }

  @Selector()
  static isAuthenticated(state: SessionStateModel): boolean {
    return !!state.token;
  }

  @Action(UpdateAccount)
  updateAccount(ctx: StateContext<SessionStateModel>, action: UpdateAccount) {
    ctx.patchState({account: action.account});
  }

  @Action(UpdateProducts)
  updateProducts(ctx: StateContext<SessionStateModel>, action: UpdateProducts) {
    ctx.patchState({products: action.products});
  }

  @Action(UpdateAccountStatus)
  updateAccountStatus(ctx: StateContext<SessionStateModel>, action: UpdateAccountStatus) {
    ctx.setState(
      patch<SessionStateModel>({
        account: patch<SessionStateModel['account']>({
          user: patch<SessionStateModel['account']['user']>({
            status: action.status,
          }),
        }),
      }),
    );
  }

  @Action(UpdateAccountSettings)
  updateAccountSettings(ctx: StateContext<SessionStateModel>, action: UpdateAccountSettings) {
    // https://www.ngxs.io/advanced/operators#typing-operators
    ctx.setState(
      patch<SessionStateModel>({
        account: patch<SessionStateModel['account']>({
          settings: action.accountSettings,
        }),
      }),
    );
  }

  @Action(UpdateToken)
  updateToken(ctx: StateContext<SessionStateModel>, action: UpdateToken) {
    ctx.patchState({token: action.token});
  }

  @Action(UpdatePreferences)
  updatePreferences(ctx: StateContext<SessionStateModel>, action: UpdatePreferences) {
    ctx.patchState({preferences: action.preferences});
  }

  @Action(UpdateBillingInfo)
  updateBillingInfo(ctx: StateContext<SessionStateModel>, action: UpdateBillingInfo) {
    return this.http
      .post<Account>('/api/account/update-billing-info', action.billInfo)
      .pipe(
        tap(acct => ctx.patchState({account: acct}))
      );
  }

  @Action(ClearState)
  clearState(ctx: StateContext<SessionStateModel>) {
    ctx.setState(sessionStateDefaults);
  }
}
