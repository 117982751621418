<div *ngIf="isConnected" class="text-center">
  <button class="btn btn-secondary" (click)="disconnect()" id="disconnect-from-google">Disconnect from Google</button>
</div>

<div *ngIf="!isConnected">
  <a class="mx-auto d-block" href="{{ googleAuthUrl }}" id="google-oauth-link">
    <img class="mx-auto d-block" src="/assets/images/Red-signin_Long_base_20dp.png"/>
  </a>
</div>

