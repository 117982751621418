import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {UpdateCreditAlerts, UpdateReport} from 'src/app/shared/state/credit-report/credit-report.actions';
import {CreditAlertModel} from 'src/app/modules/alerts/models/credit-alert.model';
import ClearState = SessionActions.ClearState;


export interface CreditReportStateModel {
  report: CreditReport | null;
  alerts: CreditAlertModel[];
}


const stateDefaults: CreditReportStateModel = {
  report: null,
  alerts: [],
};


@State<CreditReportStateModel>({
  name: 'creditreport',
  defaults: stateDefaults,
})


@Injectable()
export class CreditReportState {

  @Selector([CreditReportState])
  static getReport(state: CreditReportStateModel): CreditReport {
    return state.report;
  }

  @Selector([CreditReportState])
  static getAlerts(state: CreditReportStateModel): CreditAlertModel[] {
    return state.alerts;
  }

  @Action(UpdateReport)
  updateReport(ctx: StateContext<CreditReportStateModel>, action: UpdateReport) {
    ctx.patchState({report: action.report});
  }

  @Action(UpdateCreditAlerts)
  updateAlerts(ctx: StateContext<CreditReportStateModel>, action: UpdateCreditAlerts) {
    ctx.patchState({alerts: action.alerts});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<CreditReportStateModel>) {
    ctx.setState(stateDefaults);
  }
}
