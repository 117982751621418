import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';

@Component({
  selector: 'app-motorly',
  templateUrl: './motorly.component.html',
  styleUrls: ['./motorly.component.scss']
})
export class MotorlyComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  btnClick(): void {
    //window.open('https://applynow.motorly.co.uk/?sid=4080', '_blank');
    window.open('http://prftltste.com/click.track?CID=444868&AFID=466409', '_blank');
    this.close();
    MixpanelService.track('Open Motorly offer');
  }

  close() {
    this.activeModal.close();
  }
}
