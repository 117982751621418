<div class="iao-create-password px-3 py-5 text-center">

  <div class="icon-container pt-2 my-2">
    <img class="img-fluid justify-content-center align-self-center h-100" src='/assets/images/cta/ICN_Handshake.svg'/>
  </div>

  <h1 class="mb-4">Welcome {{fname$ | async}}.</h1>

  <p class="mb-4">Access to your credit report and score has been approved. Activate your Personalised Smart Offers Match so we can analyse
    your
    credit profile and match you with financial services and products tailored to your credit score.</p>

  <h4>Activate Smart Offer Match?</h4>

  <div class="butts my-4">
    <div class="w-75">
      <button class="btn btn-primary btn-lg rounded-pill w-75" (click)="consent()">
        Yes
      </button>
    </div>

    <div class="w-75">
      <button class="btn btn-secondary btn-lg rounded-pill w-75" (click)="reject()">
        No
      </button>
    </div>
  </div>

  <div class="disclaimer">
    <a [href]="termsUrl" target="_blank">Terms of Use</a> &nbsp;&nbsp;&nbsp; <a [href]="privacyPolicyUrl" target="_blank">Privacy Policy</a>
  </div>

</div>
