
<div class="modal-body">
  <div class="text-center">

    <div class="text-center logo-cont">
      <div class="logo mx-auto">
        <img class="justify-content-center align-self-center" src='/assets/images/cta/LGO_Motorly_.png' />
      </div>
    </div>

      <h5>Car Finance Made Easy</h5>

      <div class="bullets text-dark">
        <i class="fa-regular fa-circle-check font-ukc-blue pe-2" aria-hidden="true"></i> Get a Decision in Minutes<br>
        <i class="fa-regular fa-circle-check font-ukc-blue pe-2" aria-hidden="true"></i> Buy from Any Approved Dealer<br>
      </div>

    <p class="py-2 text-center text-dark">At Motorly, we take the hassle out of car finance. With no broker fees and a range of options to suit you, Motorly makes car finance simple.</p>

    <button
      class="btn btn-lg btn-primary mt-2 mb-5 px-5"
      (click)="btnClick()">
      Apply Now
    </button>

    <p class="small text-start">4MM Ltd trading as Motorly acts as a credit broker and not a lender. Representative APR 22.9%. Rates start from 8.9% APR</p>

    <p class="small text-start">We can introduce you to a limited number of lenders who may be able to offer you finance facilities for your purchase. We may receive a commission payment from these providers if you decide to enter into an agreement with them. This will not impact the rate or amount you are provided. All commission received is fixed but may vary by lender. We do not charge a fee for arranging the finance, however, some of our lenders may charge a fee. The exact rate you will be offered will be based on your circumstances, subject to status.</p>

    <p class="small">4MM Ltd t/a Motorly, 1st Floor Offices, The ACC, Altrincham, Cheshire, WA14 1PF</p>

  </div>
</div>
