import {Component, OnDestroy} from '@angular/core';
import {DarkWebService} from '../../services/dark-web.service';
import {ActivatedRoute} from '@angular/router';
import {EmailVerificationStatus} from '../../models/email-verification-statuses.enum';
import {Subscription} from 'rxjs';

/*
 * This is the public page that the verification email points back to.  The routing exists in the
 * main app-routing
 */

@Component({
  selector: 'app-verify-search-criterion',
  templateUrl: './verify-search-criterion.component.html',
  styleUrls: ['./verify-search-criterion.component.scss'],
})

export class VerifySearchCriterionComponent implements OnDestroy {

  public status: EmailVerificationStatus = EmailVerificationStatus.VERIFYING;
  public emailVerificationStatus = EmailVerificationStatus; // Access enum from template
  public type = '';

  private querySub: Subscription;

  constructor(darkWebService: DarkWebService,
              activatedRoute: ActivatedRoute) {
    this.querySub = activatedRoute
      .queryParams
      .subscribe(params => {
        const criterion = params['criterion'];
        const code = params['code'];
        this.type = params['type'];

        darkWebService
          .verifySearchCriterionAndCode(criterion, this.type, code)
          .subscribe({
            next: () => {
              this.status = EmailVerificationStatus.VERIFIED;
            },
            error: () => {
              this.status = EmailVerificationStatus.VERIFIED;
            },
          });
      });
  }

  ngOnDestroy(): void {
    this.querySub.unsubscribe();
  }
}
