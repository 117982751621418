import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular';
import {SentryErrorHandler} from './app/shared/errors/sentry-error-handler';
import {BrowserTracing} from '@sentry/tracing';


if (environment.production) {
  enableProdMode();
}

if (environment.production || environment.staging) {
  Sentry.init({
    dsn: 'https://08e7e8f8caf94b539cbcb327a5193c81@o401842.ingest.sentry.io/5267372',
    release: environment.release,

    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint?: Sentry.BreadcrumbHint): Sentry.Breadcrumb | null {
      // If this is a navigation breadcrumb, apply our own filters to make sure sensitive data doesn't escape
      if (breadcrumb.hasOwnProperty('category') && breadcrumb.category === 'navigation') {
        Object.keys(SentryErrorHandler.navigationDataFilters).forEach((key => {
          if (breadcrumb.data['from'].indexOf(key) > -1) {
            breadcrumb.data['from'] = SentryErrorHandler.navigationDataFilters[key];
          }

          if (breadcrumb.data['to'].indexOf(key) > -1) {
            breadcrumb.data['to'] = SentryErrorHandler.navigationDataFilters[key];
          }
        }));
      }

      return breadcrumb;
    },

    beforeSend(event) {
      // Not sure if this if statement is necessary, but I really don't want to break error reporting.
      if (event && event.exception && event.exception.values && event.exception.values[0].stacktrace && event.exception?.values[0]?.stacktrace?.frames) {
        for (const frame of event.exception?.values[0]?.stacktrace?.frames) {
          if (frame.filename.includes('rokoInstabot')) {
            return null;
          }
        }
      }

      // Discard 401 and 403 events
      if (event && event.message && (
        event.message.includes('401 Unauthorized')
        || event.message.includes('403 Forbidden')
        || event.message.includes('401 OK')
      )) {

        return null;
      }

      return event;
    },

    ignoreErrors: [
      'undefined is not an object (evaluating \'r._current.agent\')',  // from rokoInstabot
      'AbortController is not defined', // from rokoInstabot
      '0 Unknown Error',  // Seems to happen to a lot of calls at once on mobile users.  network issue?
      'ChunkLoadError',   // happens to active sessions when we deploy a new version.  very hard to fix.
      'closeLiveChat', // from rokoInstabot
    ],

    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
      // new Sentry.Integrations.TryCatch({
      //   XMLHttpRequest: false,
      // }),
      // new Sentry.Integrations.GlobalHandlers({
      //   /*
      //   In an effort to remove bugs from third party libraries, as well as errors in the form
      //   of "non-error exception captured", this was set to false.
      //    */
      //   onunhandledrejection: false,
      //   // Assuming true is default.
      //   onerror: true,
      // }),
      new BrowserTracing({
        tracingOrigins: ['https://member.ukcreditratings.com', 'https://member.myukcreditreport.co.uk', 'https://member.studentcreditchecker.com', 'https://member.creditscorepro.co.uk', 'https://local.member.ukcreditratings.com:4300', 'https://stage.member.ukcreditratings.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
        traceXHR: true,
        traceFetch: true,
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

