import {animate, animation, group, query, style} from '@angular/animations';

export const fadeAnimation = animation([
  query(':enter', style({ position: 'absolute',  opacity: 0 }), { optional: true }),
  query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),

  group([
    query(':leave', [
      animate('0.5s cubic-bezier(.35,0,.25,1)', style({ opacity:0 })),
    ], { optional: true }),
    query(':enter', [
      animate('0.7s cubic-bezier(.55,0,.25,1)', style({ opacity:1 }))
    ], { optional: true }),
  ]),
]);

export const slideDownAnimation = animation([
  query(':enter', style({ position: 'absolute',  transform: 'translateY(-100%)' }), { optional: true }),
  query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 }), { optional: true }),

  query(':enter', [
    animate('0.01s cubic-bezier(.55,0,.25,1)', style({ transform: 'translateY(0)' }))
  ], { optional: true }),
]);
