<div class="container">
  <div class="row">

    <div class="col-md-6">
      <div class="login-sec">

        <div class="pt-5 pb-5 login-logo">
          <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
        </div>

        <div class="werds pb-2 text-center mb-3">
          Login to see your updated credit score today.
        </div>

        <div *ngIf="googleMessaging">
          <div class="alert alert-primary" role="alert">
            The Google account you logged in to is not bound to any local account.  You must first log in to your account using
            your username and password.
          </div>
        </div>

        <form class="login-form" (submit)="login()">

          <div class="">
            <label for="email" class="col-form-label">Email</label>
            <div class="">
              <input type="email" class="form-control" name="email" id="email" placeholder="Email" [(ngModel)]="loginRequest.email">
            </div>
          </div>

          <div class="">
            <label for="password" class="col-form-label">Password</label>
            <div class="">
              <input type="password" class="form-control" name="password" id="password" placeholder="Password" [(ngModel)]="loginRequest.password" #passwordInput>
            </div>
          </div>

          <div class="mt-3 form-button">

            <button type="submit" class="btn btn-primary rounded-pill" [disabled]="submitting">Submit</button>
          </div>
          <div class="text-end copy-text small forgot">

            <a routerLink="/auth/password/reset">Forgot your password?</a>
          </div>
        </form>
        <div class="mt-4">
          <div class="line">
            <span>or</span>
          </div>
        </div>
        <div class="mt-2">
          <a class="mx-auto d-block" href="{{ googleAuthUrl }}" id="google-oauth-link">
            <img class="mx-auto d-block" src="/assets/images/Red-signin_Long_base_20dp.png"/>
          </a>
        </div>


      </div>
    </div>
    <div class="col-md-6 right">
      <div class="box">
        <div class="icon">
          <img src="assets/images/cta/ICN_Announcement.png">
        </div>
        <!--
        <div class="title">
          Don't forget, you can now check your Dark Web Monitoring report in our portal for FREE too.
        </div>-->
        <div class="werds">
          <p>Check your weekly credit report update to get your finances in great shape.</p>
          <p>See how your recent financial activity has affected your credit score.</p>
          <p>Establish and build your credit file to give you a sound financial future</p>
        </div>
      </div>
    </div>
  </div>
</div>
