import {EventEmitter, Injectable, Output} from '@angular/core';
import PCAAddress from './PCAAddress';
/*
For more info, visit https://www.loqate.com/resources/support/setup-guides/quick-address-validation/#browser_autofill
 */


@Injectable({
  providedIn: 'root'
})
export default class PCAService {

  @Output() pcaChangeEvent = new EventEmitter<PCAAddress>();

  protected realPcaService;

  constructor() {
    (function(n,t,i,r){let u,f;n[i]=n[i]||{},n[i].initial={accountCode:'RATES11112',host:'RATES11112.pcapredict.com'},n[i].on=n[i].on||function(){(n[i].onq=n[i].onq||[]).push(arguments)},u=t.createElement('script'),u.async=!0,u.src=r,f=t.getElementsByTagName('script')[0],f.parentNode.insertBefore(u,f)})(window,document,'pca','//RATES11112.pcapredict.com/js/sensor.js');
    // This global service will be registered by the services.postcodeanywhere.co.uk javascript include, but
    // only for versions 2 or 3 (also sensor method).  There is a function in PCAService to determine what
    // version we're running.
    this.realPcaService = (window as any).pca;

    this.registerSensorCallback();
  }

  /**
   * This must be called AFTER the input control that Loqate needs to work with is written to the page.
   */
  load(): void {
    this.realPcaService.load();
  }

  private registerSensorCallback() {
    if (this.isSensorUsed()) {
      this.realPcaService.on('options', (type, key, options) => {
        options.suppressAutocomplete = false;
      });

      this.realPcaService.on('load', (type, id, control) => {
        // https://www.loqate.com/resources/support/setup-guides/advanced-setup-guide/#events_listeners
        // The address fields have been populated. This is the most common event to listen for, and will allow you to populate
        // your own fields with your own logic using the address object (parameter 1) and the list of address variations
        // (parameter 2).
        control.listen('populate', (address, variations) => {
          this.pcaChangeEvent.emit(address);
        });
      });
    }
  }

  /**
   * Loqate has a "sensor" loader library.  This detects if that is used.
   */
  isSensorUsed(): boolean {
    return this.realPcaService && typeof(this.realPcaService.on) !== 'undefined';
  }
}
