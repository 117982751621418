import {Component, OnInit, ViewChild} from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import {HomeComponent} from '../../../modules/dashboard/pages/home/home.component';


@Component({
  selector: 'app-ecce-layout',
  templateUrl: './info-layout.component.html',
  styleUrls: ['./info-layout.component.scss']
})
export class InfoLayoutComponent implements OnInit {

  @ViewChild(MatSidenavContent, {static: true}) sidenavContent: MatSidenavContent;
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;

  // @Output() onToggleSideNavEvent = new EventEmitter<any>();
  public isSideNavCollapsed = true;

  constructor() { }

  ngOnInit() {
  }

  closeSideNav() {
    this.sidenav.close();
  }

  toggleSideNav() {

    this.sidenav.toggle().then(event => {
      if(event.toString() == 'close'){
        this.isSideNavCollapsed = true;
      } else {
        this.isSideNavCollapsed = false;
      }
    });
  }

  /**
   * Make sure all pages are scrolled to top after navigation.  We exclude Dashboard Home because it
   * has a different use case and handles its own scrolling
   *
   * @param event
   */
  onRouterOutletActivate(event) {
    if (event instanceof HomeComponent === false) {
      this.scrollToTop();
    }
  }

  /**
   * Scroll to top of the page.  Our scroll bar is actually on the material sidenav content, so
   * we have to hook into that via a ViewChild Reference to gain control.
   */
  scrollToTop() {
    this.sidenavContent.scrollTo({ top: 0});
  }

  /**
   * Logout with confirmation modal
   */
  // logout() {
  //   const modalRef = this.modalService.open(ConfirmationModalComponent);
  //   modalRef.componentInstance.title = 'Logout';
  //   modalRef.componentInstance.message = 'Are you sure you want to log out?';
  //   modalRef.result.then((shouldLogout: boolean) => {
  //
  //     console.log({shouldLogout: shouldLogout});
  //
  //     if (shouldLogout) {
  //       this.userAclService.update(this.user.id, {id: this.user.id, is_active: false}).subscribe(
  //         result => {
  //           this.alertService.success('The user has been disabled');
  //         }
  //       );
  //     }
  //   });
  // }

}
