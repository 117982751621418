<!-- Contact Date/Time -->
<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-center align-items-center">
    <div class="input-group">
      <input class="form-control"
             placeholder="dd/mm/yyyy"
             name="datePicker"
             [(ngModel)]="dateTime.date"
             (ngModelChange)="changed($event)"
             ngbDatepicker
             #datepicker="ngbDatepicker"
             [minDate]="config.minDate" readonly>
      <span class="input-group-text" (click)="datepicker.toggle()"><i class="fa fa-lg fa-calendar"></i></span>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center">
    <ngb-timepicker
      name="timePicker"
      [(ngModel)]="dateTime.time"
      (ngModelChange)="changed($event)"
      meridian="true"
      [minuteStep]="minuteStep"></ngb-timepicker>
  </div>
</div>
