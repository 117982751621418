import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SurveyModalComponent} from './survey-modal/survey-modal.component';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {filter, take} from 'rxjs/operators';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';
import {IaoEventService} from 'src/app/modules/iao-chain/iao-event.service';
import {IaoEventTypeEnum} from 'src/app/modules/iao-chain/iao-event-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ExitSurveyService {
  private timeoutHandle: ReturnType<typeof setTimeout> = null;
  /**
   * Marked once registerTimeout() is called, this prevents the survey from showing twice.  You can always manually show it with show()
   */
  private timeoutWasRegistered = false;

  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private iaoEventService: IaoEventService,
    ) {
  }

  listenForIaoFinish(): void {
    this.iaoEventService.iaoCloseEvent.subscribe(val => {
      if (val === IaoEventTypeEnum.Stop) {
        this.registerTimeout();
      }
    });
  }

  /**
   * Set a timer to show the exit survey question after x seconds.
   */
  registerTimeout(seconds: number = 300): void {
    if (this.timeoutWasRegistered) {
      console.log('Exit survey timeout register skipped due to it being done before');
      return;
    }

    // Only set the time if we are logged in (read: have an account object).
    this.accountService.getAccount()
      .pipe(
        filter((acct: IAccount) => acct && acct.settings && !acct.settings.shownExitSurvey),  // Don't show if we've already seen it
        take(1),
      )
      .subscribe(() => {
        this.timeoutHandle = setTimeout(this.show.bind(this), seconds * 1000);

        console.log('Exit survey timer started');
      });
  }

  show(): void {
    // This is only for if show() is manually called before the timeout actually happens.
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }

    // TODO: We need to prevent or delay this from firing until Intro or IAO's are complete
    this.modalService.open(SurveyModalComponent);

    this.accountService.updateSetting('shownExitSurvey', true).subscribe();

    MixpanelService.track('ShowModal exit survey');
  }

  clearTimeout(): void {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
}
