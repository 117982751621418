import {Observer} from 'rxjs';

/**
 * Use to prevent errors on empty subscribers due to 401 from bubbling up to Sentry
 *
 * e.g.
 * Use this:
 *     this.ctaService
 *        .updateStatus(changeCtaStatusRequest)
 *         .subscribe(genericErrorObserver);
 *
 * Instead of this:
 *      this.ctaService
 *        .updateStatus(changeCtaStatusRequest)
 *         .subscribe();
 *
 */
export const genericErrorObserver: Observer<any> = {
  next(value) {
  },
  error(err) {
    if (err.status !== 401) {
      console.log(err);
    }
  },
  complete() {
  }
};



