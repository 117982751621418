import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(date: any, format: string, tz: string): any {
    // return format;
    if (date) {
      return moment.tz(date, tz).format(format);
    }
  }
}
