import { Component, OnInit } from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {CreditReport} from 'src/app/shared//models/credit-report';
import {LineChartComponent} from '../../../../../core/components/line-chart/line-chart.component';
import {ReportHistoryService} from 'src/app/shared//services/report-history.service';
import {ReportHistory} from 'src/app/shared//models/report-history';
import {ReportHistroyInterface} from 'src/app/shared//models/report-history.interface';
import {checkDateInRange} from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-tools';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-summary-credit-score',
  templateUrl: './summary-credit-score.component.html',
  styleUrls: ['./summary-credit-score.component.scss'],
})
export class SummaryCreditScoreComponent implements OnInit {

  public history: Array<ReportHistroyInterface>;
  protected data: Array<any>;
  protected labels: Array<any>;
  public months = 3;
  public hasData = false;


  constructor(
    private reportHistoryService: ReportHistoryService
  ) {
    this.data = new Array<any>({y:700,x:new Date()});
    this.labels = new Array<any>({label:''});
  }

  public getData() {
    const data = this.data;
    return [{data}];
  }

  public getLabels(){
    return this.labels;
  }

  public getOptions(){
    return {
      responsive: true,
      legend : {
        display : false
      },
      elements: {
        line: {
          tension: 0 // disables bezier curves
        }
      },
      hover: {
        animationDuration: 0 // duration of animations when hovering an item
      },
      scales: {
        yAxes: [{
          reverse: true,
          ticks: {
            min: 100,
            max: 700,
            step: 50
          }
        }],
        xAxes: [{
          reverse: false,
          type: 'time',
          time: {
            unit: 'month',
            unitStepSize: 1,
            displayFormats: {
              millisecond: 'MMM',
              second: 'MMM',
              minute: 'MMM',
              hour: 'MMM',
              day: 'MMM',
              week: 'MMM',
              month: 'MMM',
              quarter: 'MMM',
              year: 'MMM',
            },
          },
          distribution: 'linear'
        }]
      }

    };
  }

  ngOnInit() {
    this.reportHistoryService
      .get()
      .subscribe((reportHistory: Array<ReportHistroyInterface>) => {
        if (reportHistory.length > 0) {
          this.hasData = true;
          this.refreshStats(3);
        }
      });
  }

  refreshStats(months: number) {
    this.data = new Array<any>();
    this.labels = new Array<any>();
    this.months = months;

    this.history = this.reportHistoryService.getReportHistory();

    const limit = new Date();
    limit.setMonth(limit.getMonth() - months);

    this.history.forEach(value => {

      const label = value.created_at.split(' ')[0];
      const dt: Date = new Date(label);
      if (dt >= limit){
        this.data.push({y: value.score, x: dt});
        this.labels.push(dt);
        // this.labels.push(dt.getMonth()+'/'+dt.getFullYear());
      }

    });
  }

}
