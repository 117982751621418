<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>
        Open Disputes
      </h3>
      <div class="flex-row">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Open Disputes</h5>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>REFERENCE#</th>
                  <th>TITLE</th>
                  <th>STATUS</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let dispute of disputes">
                  <td>
                    {{dispute.entries[0].refnum}}
                  </td>
                  <td>
                    {{dispute.entries[0].item}}
                  </td>
                  <td>
                    {{dispute.entries[0].status}}
                  </td>
                </tr>
                </tbody>
              </table>
              <div class="text-center">
                <a [routerLink]="['/disputes']">View Details</a>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                Errors Do Happen
              </h5>
              <p class="card-text">
                The credit bureaux receive millions of data points per day from institutions, lenders, and all kinds of data sources.
                There are times that information is incorrectly attributed to the wrong people or information is reported in error to the bureaux.
                Reporting an error can help the bureaux maintain their information, providing you and other institutions the most accurate score and report.
                This can be a meaningful impact on approvals and rates.
                However please note that a dispute does not necessarily mean the information will be removed,
                it is only a request to verify the accuracy of the information, and if it is found to be inaccurate it will then be removed.
              </p>
              <a [routerLink]="['/edu/content/disputing-items']">Read More &gt;&gt;</a>
            </div>
          </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">
              Always Make Sure Your Information is Correct
            </h5>
            <p class="card-text">
              It is important to make sure that your credit report is free from errors as this may negatively impact your ability to borrow or the rates you may be offered.
              Review your credit report and financial accounts to make sure that the payment history and information is accurately being reflected.
            </p>
            <a [routerLink]="['/financial-data/home']">Review Financial Accounts For Errors &gt;&gt;</a>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
