<div *ngIf="toastService.toasts.length > 0" aria-live="polite" aria-atomic="true" class="toast-container d-flex flex-column justify-content-start align-items-right">

  <ng-container *ngFor="let toast of toastService.toasts">

    <!-- Toast with Header -->
    <ngb-toast
      *ngIf="toast.hasHeader === true"
      [autohide]="toast.autohide"
      [delay]="toast.delay || 8000"
      (hidden)="toastService.remove(toast)">

      <ng-template ngbToastHeader="toast">
        <div class="heading" [ngSwitch]="toast.type">
          <i *ngSwitchCase="toastType.success" class="fas fa-check-circle fa-lg text-primary"></i>
          <i *ngSwitchCase="toastType.info" class="fas fa-lg fa-bell text-secondary"></i>
          <i *ngSwitchCase="toastType.error" class="fas fa-lg fa-exclamation-triangle text-dark"></i>
          <i *ngSwitchCase="toastType.warning" class="fas fa-lg fa-exclamation-circle text-primary"></i>
          <span class="title">{{toast.title}}</span>
        </div>
      </ng-template>

        {{toast.message}}
    </ngb-toast>

    <!-- Toast without Header -->
    <ngb-toast
      *ngIf="toast.hasHeader === false"
      [autohide]="toast.autohide"
      [delay]="toast.delay || 10000"
      (hidden)="toastService.remove(toast)">

      <i class="fa-lg fa-regular fa-circle-xmark float-end ms-2 mt-1 mb-1 me-0 close" data-dismiss="toast" aria-label="Close" (click)="toastService.remove(toast)"></i>

        <span [ngSwitch]="toast.type">
          <i *ngSwitchCase="toastType.success" class="fas fa-check-circle fa-lg text-primary"></i>
          <i *ngSwitchCase="toastType.info" class="fas fa-lg fa-bell text-secondary"></i>
          <i *ngSwitchCase="toastType.error" class="fas fa-lg fa-exclamation-triangle text-dark"></i>
          <i *ngSwitchCase="toastType.warning" class="fas fa-lg fa-exclamation-circle text-primary"></i>
        </span>
        {{toast.message}}
    </ngb-toast>
  </ng-container>

</div>
