<div class="py-5 px-5">

  <div class="text-center p-3">
    <img id="icon" src="assets/images/cta/ICN_PaperPlane_MultiColor.svg" alt="Send report"/>
  </div>

  <h2 class="text-center pb-3">Complete Your Signup<br>with

    <ng-container [ngSwitch]="afid$ | async">
      <span *ngSwitchCase="488367">Sanderson Drake</span>
      <span *ngSwitchCase="561717">Sanderson Drake</span>
      <span *ngSwitchCase="514271">Allison Allcott</span>
      <span *ngSwitchCase="514270">Ingram Toft</span>
      <span *ngSwitchCase="515415">Allegiant Finance</span>
    </ng-container>
  </h2>

  <div class="text-center">
    <button class="btn btn-primary px-4" (click)="gotoWebPostPage()" id="web-post-partner-iao-button"> Go to "Send Your Report" Page</button>
  </div>

</div>
