import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {AccountService} from 'src/app/shared/services/account.service';
import {Subscription} from 'rxjs';
import {IAccount} from 'src/app/shared/models/account';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {DatePipe, NgIf, NgTemplateOutlet} from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CalendlyService} from 'src/app/shared/services/calendly.service';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {CoreModule} from 'src/app/core/core.module';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';

/*
https://help.calendly.com/hc/en-us/articles/223147027?tab=advanced#4
 */

declare global {
  // Add Calendly definition to window object, so typescript knows about it
  interface Window {
    Calendly: {
      initInlineWidget: (options: CalendlyOptions) => void,
      parseOptions: () => void,
      getSource: () => void,
    };
  }
}

// Options that can be passed to Calendly on init of inline widget
interface CalendlyOptions {
  url: string;
  parentElement: any;
  inlineStyles?: any;
  embedType?: any;
  prefill?: { name?: string, firstName?: string, lastName?: string, email?: string };
  utm?: object;
}

export interface CreditReviewEvent {
  id: number;
  uid: number;
  uri: string;
  created_at: Date;
  updated_at: Date;
}

@Component({
  selector: 'app-credit-consultation',
  templateUrl: './credit-consultation.component.html',
  styleUrls: ['./credit-consultation.component.scss'],
  providers: [{provide: Window, useValue: window}],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    CoreModule,
    DatePipe,
  ],
})
export class CreditConsultationComponent implements AfterViewInit, OnDestroy {

  @ViewChild('explainStep', {static: true}) explainStep: TemplateRef<any>;
  @ViewChild('calendarStep') calendarStep: TemplateRef<any>;
  @ViewChild('eventExistsStep') eventExistsStep: TemplateRef<any>;
  @ViewChild('calendly') calendly: ElementRef;

  private accountSubscription: Subscription;
  private existingEventSub: Subscription;
  private account: IAccount;
  private readonly eventHandler: (ev: MessageEvent) => void;

  public booked = false;
  public currentTemplate: any = null;
  public existingEvent: CalendlyEvent = null;

  constructor(
    private window: Window,
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
    private calendlyService: CalendlyService,
    private loadIndicatorService: LoadIndicatorService
  ) {
    this.accountSubscription = this.accountService.getAccount()
      .subscribe(acct => this.account = acct);

    // Listen for messages from Calendly iframe
    this.eventHandler = (ev) => this.onWindowEvent(ev);
    window.addEventListener('message', this.eventHandler);
  }

  ngAfterViewInit(): void {
    this.existingEventSub = this.calendlyService.get().subscribe(ce => {
      this.existingEvent = ce;

      if (ce) {
        this.currentTemplate = this.eventExistsStep;
      } else {
        this.currentTemplate = this.explainStep;
      }
    });
  }

  showCalendar(): void {
    this.loadIndicatorService.push('calendly-widgit');

    this.currentTemplate = this.calendarStep;

    MixpanelService.track('Click Credit consultation show calendar');

    // Kick the widget initialization to the next cycle to make sure the DOM is prepared.
    setTimeout(() => {
      // If we want to show the widget on component load, this would need to come during or after ngAfterViewInit
      this.window.Calendly.initInlineWidget({
        url: 'https://calendly.com/ukcr/financial-monitoring-review?hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1',
        parentElement: this.calendly.nativeElement,
        prefill: {
          name: this.account.user.fname + ' ' + this.account.user.lname,
          firstName: this.account.user.fname,
          lastName: this.account.user.lname,
          email: this.account.user.email,
        },
      });
    }, 10);
  }

  close(): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.accountSubscription.unsubscribe();
    this.existingEventSub.unsubscribe();
    this.window.removeEventListener('message', this.eventHandler);
  }

  private onWindowEvent(ev: MessageEvent): void {

    if (ev.origin === 'https://calendly.com') {

      if (ev.data?.event === 'calendly.event_type_viewed') {
         this.loadIndicatorService.pop('calendly-widgit');
      }

      if (ev.origin === 'https://calendly.com' && ev.data?.event === 'calendly.event_scheduled') {
        MixpanelService.track('Credit consultation booked');

        if (ev.data.payload?.event) {
          this.calendlyService.addEvent(ev);
        }

        this.booked = true;
      }
    }
  }
}
