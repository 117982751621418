import { Component, OnInit } from '@angular/core';
import {SummaryStats} from 'src/app/shared//models/summary-stats';
import {CreditReport} from 'src/app/shared//models/credit-report';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';

@Component({
  selector: 'app-summary-credit-card-utilization',
  templateUrl: './summary-credit-card-utilization.component.html',
  styleUrls: ['./summary-credit-card-utilization.component.scss'],
})
export class SummaryCreditCardUtilizationComponent implements OnInit {

  public summaryStats: SummaryStats;

  constructor(
    private reportService: CreditReportService
  ) { }

  /**
   * Initialize summary stats and fetch values
   */
  ngOnInit() {
    this.summaryStats = new SummaryStats();

    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.refreshStats();
      });
  }

  /**
   * Refresh displayed stats
   */
  refreshStats() {
    this.summaryStats = this.reportService.getSummaryStats();
  }

  public getData() {
    // this.summaryStats.creditLimit = 2501;
    if(this.summaryStats.creditCardUtilization > 100){
      return  [{data: [this.summaryStats.revolvingDebtAmount]}];
    }
    return  [{data: [this.summaryStats.revolvingDebtAmount, this.summaryStats.creditLimit - this.summaryStats.revolvingDebtAmount]}];
  }

  public getPercent(){
    return this.summaryStats.creditCardUtilization;
    // if(this.summaryStats.creditLimit == 0){
    //   return 0;
    // } else {
    //   return Math.round(this.summaryStats.revolvingDebtAmount / this.summaryStats.creditLimit * 100);
    // }
  }

  /**
   * somehow this will not work, any which way,
   */
  public getLabels() {
    return new Array<any>('Available', 'Utilised');
  }
}
