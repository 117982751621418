import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CreditLadderResponse} from '../models/credit-ladder-response';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {FormsModule} from '@angular/forms';
import {NgIf, NgTemplateOutlet} from '@angular/common';


@Component({
  selector: 'app-credit-ladder',
  templateUrl: './credit-ladder.component.html',
  styleUrls: ['./credit-ladder.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class CreditLadderComponent implements OnInit {

  @ViewChild('leadpost_form', {static: true}) leadPostFormTemplate: TemplateRef<any>;
  @ViewChild('leadpost_success', {static: true}) leadPostSuccessTemplate: TemplateRef<any>;
  @ViewChild('leadpost_error', {static: true}) leadPostErrorTemplate: TemplateRef<any>;

  public template: TemplateRef<any> = null;
  public creditLadderResponse: CreditLadderResponse = null;

  public form = {
    hasRentPayments: null,
  };

  constructor(private http: HttpClient,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.template = this.leadPostFormTemplate;
  }

  onSubmit(): void {
    if (this.form.hasRentPayments) {
      this.postLead();
    }
  }

  postLead(): void {
    this.http.post<CreditLadderResponse>('/api/offers/credit-ladder', this.form)
      .subscribe({
        next: (creditLadderResponse: CreditLadderResponse) => {
          if (creditLadderResponse.success === '1') {
            MixpanelService.track('SuccessfulSubmit Credit Ladder offer');
            this.creditLadderResponse = creditLadderResponse;
            window.open(creditLadderResponse.link, '_blank');
            this.template = this.leadPostSuccessTemplate;
          } else {
            this.template = this.leadPostErrorTemplate;
          }
        },
        error: error => {
          // Display not qualified message
          this.template = this.leadPostErrorTemplate;
        },
      });
  }

  close(): void {
    this.activeModal.close(false);
  }
}
