import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleIntegrationService {

  constructor(private http: HttpClient) {
  }

  static generateGoogleUrl(returnPath: string): string {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');

    url.searchParams.append('client_id', '1002567930703-sdp4l9o4tn97lofc350t9aucje765a72.apps.googleusercontent.com');
    // This means that the user MUST be coming from a known, approved domain.  If we add some doofus cobrand or think it's
    // cute to park creditscorepro.com here when it should be creditscorepro.co.uk, this WILL NOT work.
    url.searchParams.append('redirect_uri', GoogleIntegrationService.getRedirectUri(returnPath));
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('scope', 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');

    return url.toString();
  }

  static getRedirectUri(returnPath: string): string {
    return window.location.origin + returnPath;
  }

  /**
   * Disassociates the user's account from a Google account.
   */
  disconnect() {
    return this.http.post('/api/auth/google/disconnect', {});
  }

  /**
   * Bind a Google account to the current logged in user.  Must be logged in.
   */
  connect(authCode: string, redirectUri: string) {
    return this.http.post('/api/auth/google/connect', {code: authCode, redirectUri});
  }
}
