<ng-template #leadpost_confirmation_view>

  <h5>Write Off Up To 89%* of Debts</h5>

  <table class="bullet-table mx-auto text-start">
    <tr>
      <td>
        <i class="fa-regular fa-square-check font-ukc-blue pe-2"></i> <span class="font-weight-bold">Find Out more about Debt Help</span><br/>
        Simply click the button below to send your info.
      </td>
    <tr>
    <tr>
      <td>
        <i class="fa-regular fa-comment font-ukc-blue pe-2"></i>  <span class="font-weight-bold">Consultation</span><br/>
        You will be contacted to discuss your circumstances
      </td>
    <tr>
  </table>


  <div class="pt-1 mx-auto text-start">

<!--    <div>-->
<!--        <p class="text-center">The following details will be sent to Money Advisor:</p>-->
<!--    </div>-->

<!--    <table class="info-table mx-auto">-->
<!--      <tr>-->
<!--        <th>First Name:</th><td>{{account.user.fname}}</td>-->
<!--      <tr>-->
<!--      <tr>-->
<!--        <th>Last Name:</th><td>{{account.user.lname}}</td>-->
<!--      <tr>-->
<!--      <tr>-->
<!--        <th>Email:</th><td>{{account.user.email}}</td>-->
<!--      <tr>-->
<!--      <tr>-->
<!--        <th>Phone:</th><td>{{account.user.phone}}</td>-->
<!--      <tr>-->
<!--      <tr>-->
<!--        <th>Total Debt:</th><td>{{summaryStats.totalDebt}}</td>-->
<!--      <tr>-->
<!--      <tr>-->
<!--        <th>Number of Accounts:</th><td>{{summaryStats.totalAccounts}}</td>-->
<!--      <tr>-->
<!--    </table>-->


    <form name="form" #moneyAdvisorForm="ngForm" (ngSubmit)="moneyAdvisorForm.form.valid && onSubmit()" id="tcg-offer-form" class="">

      <div class="mb-3 text-center">
        <label class="pt-3 mb-2" for="contactPreference">What is your preferred contact date and time?</label>
        <div class="dt-pick">
          <app-date-time-picker id="contactPreference" (dateTimeChange)="form.contact_preference=$event"></app-date-time-picker>
        </div>
      </div>

      <div class="form-row">
        <div class="d-grid col-10 m-auto">
          <button class="btn btn-lg btn-primary">Start My Debt Consultation Now</button>
        </div>
      </div>

    <div class="mt-3 mb-3 mx-lg-5 clearfix">

      <p class="small pt-2 text-start">
        By clicking Start My Debt Consultation Now, you are agreeing to share your personal information with Money Advisor https://www.moneyadvisor.co.uk/ who are powered by The Insolvency Group Limited. Registered in England and Wales, Registered office: Crescent House, Lever Street, Bolton, BL3 6NN. Co Reg No 12559281. You also agree to be contacted by Money Advisor so they can assist you with your debt related enquiry.  The Money Advisor privacy policy can be viewed <a target="_blank" href="https://www.moneyadvisor.co.uk/privacy-policy/">here</a>.
      </p>

      <p>*<span class="small">The debt write-off example provided of 89% has been achieved by over 10% of Money Advisor customers in the last 12 months. (Dated 22.06.2022)</span>
      </p>

      <p class="small">
        May not be suitable in all circumstances. Fees apply. Your credit rating may be affected
      </p>
    </div>

    </form>

  </div>
</ng-template>


<ng-template #leadpost_response_view>
  <p class="pt-4 pb-2 px-2">
    Thank you for your enquiry, we have now passed your information to Money Advisor.
  </p>
  <p>
    Shortly you will receive a call to continue your debt consultation, please be ready for that call, or if you wish to speed the process up, call their customer service number on: 0800 056 6820
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    OK
  </button>
</ng-template>


<ng-template #in_progress_view>
  <p class="pt-4 pb-2 px-2">
    Please wait...
  </p>
</ng-template>


<ng-template #error_response_view>
  <p class="pt-4 pb-2 px-2">
    Sorry, an error occurred while transmitting your info to Money Advisor.  Please try again later.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3"
    (click)="close()">
    Close
  </button>
</ng-template>


<div class="modal-body">
  <div class="text-center">
    <div class="py-2 text-center logo-cont">
      <div class="logo mx-auto">
        <img class="my-3 justify-content-center align-self-center" src='/assets/images/cta/LGO_MoneyAdvisor_TIG.png' />
      </div>
    </div>

    <div class="form-container">
      <div [ngTemplateOutlet]="templateName"></div>
    </div>
  </div>
</div>
