import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {IAccount} from 'src/app/shared/models/account';
import {TrustpilotService} from '../../../trustpilot/trustpilot.service';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';


export interface CaspianResponse {
  status: number;
  success: boolean;
  contact_id: number;
}


@Component({
  selector: 'app-caspian-insurance',
  templateUrl: './caspian-insurance.component.html',
  styleUrls: ['./caspian-insurance.component.scss']
})
export class CaspianInsuranceComponent implements OnInit, OnDestroy {

  @ViewChild('leadpost_confirmation_view', {static: true}) leadPostTemplate: TemplateRef<any>;
  @ViewChild('leadpost_response_view') responseTemplate: TemplateRef<any>;
  @ViewChild('error_response_view') errorResponseTemplate: TemplateRef<any>;
  @ViewChild('in_progress_view') inProgressTemplate: TemplateRef<any>;

  /**
   * View state
   */
  public templateName;

  private account: IAccount;
  private selectAccount: Subscription;

  constructor(
    private http: HttpClient,
    private store: Store,
    public activeModal: NgbActiveModal,
    private router: Router,
    private trustpilot: TrustpilotService
  ) { }

  ngOnInit(): void {
    this.templateName = this.leadPostTemplate;

    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((account: IAccount) => {
      this.account = account;
    });
  }

  btnClick() {
    MixpanelService.track('Submit caspian insurance offer');
    this.postLead();
  }

  private postLead(): void {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('guid', this.account.user.guid);

    this.templateName = this.inProgressTemplate;

    this.http.get('/api/offers/caspian/leadpost', {params: httpParams})
      .subscribe((resp: CaspianResponse) => {
          if (resp.success) {
            this.templateName = this.responseTemplate;
            MixpanelService.track('SuccessfulSubmit caspian insurance offer');
            this.trustpilot.open();
          } else {
            this.templateName = this.errorResponseTemplate;
          }
        },
        error => {
          this.templateName = this.errorResponseTemplate;
        });
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
