<div class="container">

    <div class="pt-5 pb-5" *ngIf="(product$ | async)">
      <a routerLink="/auth/login">
        <img class="mx-auto d-block" [src]="(product$ | async).logo" />
      </a>
    </div>

    <div class="pt-5 pb-5" *ngIf="!(product$ | async)">
      <a routerLink="/auth/login">
        <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
      </a>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header border-0">Reset Password</div>
                <div class="card-body">
                  <!-- action="http://local.member2.ukcreditratings.com/password/reset" -->
                    <form method="POST"  (submit)="resetPassword()">

                      <!-- email -->
                        <div class="row mb-2"><label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                            <div class="col-md-6">
                              <input id="email" type="email" name="email" value="" required="required"
                                     [(ngModel)]="passwordResetRequest.email" autocomplete="email"
                                     autofocus="autofocus" class="form-control " />
                            </div>
                        </div>

                      <!-- password -->
                        <div class="row mb-2">
                          <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>
                            <div class="col-md-6">
                              <input id="password" type="password" name="password" required="required"
                                     [(ngModel)]="passwordResetRequest.password" autocomplete="new-password" class="form-control ">
                            </div>
                        </div>

                      <!-- password confirmation -->
                        <div class="row mb-2">
                          <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Confirm Password</label>
                          <div class="col-md-6">
                            <input id="password-confirm" type="password" name="password_confirmation"
                                   required="required" autocomplete="new-password" class="form-control"
                                   [(ngModel)]="passwordResetRequest.password_confirmation">
                          </div>
                        </div>

                        <div class="row mb-2 mb-0">
                            <div class="col-md-6 offset-md-4">
                                <button type="submit" class="btn btn-primary rounded-pill" [disabled]="!(product$ | async)">
                                    Reset Password
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
