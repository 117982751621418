import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {DarkwebScan} from '../models/darkweb-scan';
import {EmailsResponse} from '../models/emails-response';
import {DarkwebActions} from './darkweb.actions';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import {SearchCriterionResponse} from 'src/app/modules/dark-web/models/search-criterion-response';
import {DarkwebSummary} from 'src/app/modules/dark-web/models/darkweb-summary';
import UpdateScan = DarkwebActions.UpdateScan;
import ClearState = SessionActions.ClearState;
import UpdateSummary = DarkwebActions.UpdateSummary;
import UpdateSearchCriterion = DarkwebActions.UpdateSearchCriterion;


export interface DarkwebStateModel {
  scan: DarkwebScan | null;
  emails: EmailsResponse | null;
  searchCriterion: SearchCriterionResponse | null;
  /**
   * Summary of risks, including unverified vectors.
   */
  summary: DarkwebSummary | null;
}


export const darkwebStateDefaults: DarkwebStateModel = {
  scan: {
    criteria: null,
    uid: null,
    lastScan: null,
    risks: null,
    risk_sources: null,
    hits: [],
    errors: [],
    breach_catalog: [],
    dataset: {
      account: [],
      company: [],
      emails: [],
      financial_info: [],
      geolocation: [],
      infected_user: [],
      ips: [],
      passwords: [],
      phone_numbers: [],
      pii: [],
      social: [],
      usernames: [],
    }
  },
  emails: null,
  searchCriterion: null,
  summary: null,
};


@State<DarkwebStateModel>({
  name: 'darkweb',
  defaults: darkwebStateDefaults
})


@Injectable()
export class DarkwebState {

  constructor() {}

  @Selector([DarkwebState])
  static getScan(state: DarkwebStateModel) {
    return state.scan;
  }

  @Selector([DarkwebState])
  static hasScan(state: DarkwebStateModel): boolean {
    return !!(state.scan.lastScan);
  }

  @Selector([DarkwebState])
  static getEmails(state: DarkwebStateModel) {
    return state.emails;
  }

  @Selector([DarkwebState])
  static getCriterion(state: DarkwebStateModel) {
    return state.searchCriterion;
  }

  @Selector([DarkwebState])
  static getHits(state: DarkwebStateModel) {
    return state.scan.hits;
  }

  @Selector([DarkwebState])
  static getBreaches(state: DarkwebStateModel) {
    return state.scan.breach_catalog;
  }

  @Selector([DarkwebState])
  static getLastScanDate(state: DarkwebStateModel) {
    return state.scan.lastScan;
  }

  @Selector([DarkwebState])
  static getSummary(state: DarkwebStateModel) {
    return state.summary;
  }

  @Action(UpdateScan)
  updateScan(ctx: StateContext<DarkwebStateModel>, action: UpdateScan) {
    ctx.patchState({scan: action.scan});
  }

  // @Action(UpdateEmails)
  // updateEmails(ctx: StateContext<DarkwebStateModel>, action: UpdateEmails) {
  //   ctx.patchState({emails: action.emails});
  // }

  @Action(UpdateSearchCriterion)
  updateSearchCriterion(ctx: StateContext<DarkwebStateModel>, action: UpdateSearchCriterion) {
    ctx.patchState({searchCriterion: action.searchCriterion});
  }

  @Action(UpdateSummary)
  updateSummary(ctx: StateContext<DarkwebStateModel>, action: UpdateSummary) {
    ctx.patchState({summary: action.summary});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<DarkwebStateModel>) {
    ctx.setState(darkwebStateDefaults);
  }
}
