import {Component, OnDestroy} from '@angular/core';
import {GoogleIntegrationService} from './google-integration.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {ConfirmationModalComponent} from 'src/app/core/components/confirmation-modal/confirmation-modal.component';
import {AccountService} from 'src/app/shared/services/account.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IAccount} from 'src/app/shared/models/account';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-google-integration',
  templateUrl: './google-integration.component.html',
  styleUrls: ['./google-integration.component.scss'],
  imports: [
    NgIf
  ],
  standalone: true
})
export class GoogleIntegrationComponent implements OnDestroy {

  googleAuthUrl: string;
  /**
   * If we are connected to a Google account or not
   */
  isConnected = false;
  private selectAccount: Subscription;

  constructor(
              private route: ActivatedRoute,
              private googleService: GoogleIntegrationService,
              private toastService: ToastService,
              private modalService: NgbModal,
              private accountService: AccountService) {
    this.googleAuthUrl = GoogleIntegrationService.generateGoogleUrl('/account/member-profile');

    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {
        this.isConnected = !!account.user.google_id;
      });

    this.checkForGoogleReturn();
  }

  /**
   * If the code query param is found on the URL, we know we were just redirect back here from google.
   * @private
   */
  private checkForGoogleReturn(): void {
    const googleCode = this.route.snapshot.queryParams['code'];
    const redirectUri = GoogleIntegrationService.getRedirectUri('/account/member-profile');
    const lastGoogleAuthCode = sessionStorage.getItem('last-google-auth-code');

    // Make sure we don't try the same code param twice.  This could happen if they use the back button or refresh.
    // it will always result in an error as Google only lets you try a code once.  Session storage will clear this
    // on tab close.
    if (googleCode && googleCode !== lastGoogleAuthCode) {
      this.googleService
        .connect(googleCode, redirectUri)
        .subscribe(() => {
          this.toastService.success('Connected Google account');

          // This will pull a new account object with the google_id field
          this.accountService.fetch(true).subscribe(genericErrorObserver);
        });

      sessionStorage.setItem('last-google-auth-code', googleCode);
    }
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }

  disconnect(): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.title = 'Disconnect Google';
    modalRef.componentInstance.message = 'Are you sure you want to disconnect your Google account?';

    modalRef.result
      .then(() => {
        this.googleService.disconnect().subscribe(() => {
          this.toastService.success('Disconnected Google account');

          // This will pull a new account object with the blanked out google_id field
          this.accountService.fetch(true).subscribe(genericErrorObserver);
        });
      })
      .catch(() => {
      });
  }
}
