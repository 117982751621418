import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {Chainable} from 'src/app/modules/iao-chain/models/chainable';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {IaoModalResultEnum} from 'src/app/modules/iao-chain/iao-modal-result.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {firstValueFrom} from 'rxjs';
import {CreditConsultationComponent} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation.component';

@Injectable({
  providedIn: 'root'
})
export class CreditConsultationIaoModalService implements Chainable {

  private account: IAccount;

  constructor(private accountService: AccountService,
              private modalService: NgbModal) {
  }

  canDisplay(): boolean {
    return !this.account.settings.hasSeenCreditConsultationModal;
  }

  onIaoClose(): void {
    this.accountService
      .updateSetting('hasSeenCreditConsultationModal', true)
      .subscribe(genericErrorObserver);
  }

  resolveDependencies(): Promise<IAccount> {
    const obs = this.accountService
      .getAccount()
      .pipe(take(1));
    obs.subscribe((account: IAccount) => this.account = account);
    return firstValueFrom(obs);
  }

  run(): Promise<IaoModalResultEnum> {
    const modalRef = this.modalService.open(CreditConsultationComponent, {size: 'lg'});

    return modalRef.result;
  }
}
