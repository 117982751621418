<div class="score-bar-widgit">
  <div class="row justify-content-center align-items-center">

    <div class="col-4">
      <p class="font-color-alt small text-end py-0 my-0">Credit Score</p>
    </div>

    <div class="col-4">
        <div class="progress">
          <div
            class="progress-bar bg-ukc-blue"
            role="progressbar"
            [style.width]="ratingBar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100">
          </div>
        </div>
    </div>

    <div class="col-4 text-start">
      <p class="small font-color-alt text-start out-of py-0 my-0">Out of 710
<!--        <span class="small text-muted d-none d-md-inline">as of {{reportDate}}</span>-->
      </p>
    </div>

  </div>
</div>
