<div class="iao-create-password px-3 py-5">
  <div class="row">

    <div class="offset-md-1 col-md-10">
      <h2 class="text-center mb-3">To access your account, <br> please create a password</h2>

      <app-password [showSocialLogins]="false" [showCurrentPassword]="false" (passwordUpdated)="passwordSet()"></app-password>
    </div>

  </div>
</div>
