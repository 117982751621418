import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-fast-perks',
  templateUrl: './fast-perks.component.html',
  styleUrls: ['./fast-perks.component.scss']
})
export class FastPerksComponent implements OnInit, OnDestroy {

  private selectAccount: Subscription;
  public fastPerksUrl = null;

  constructor(
    private accountService: AccountService,
    public activeModal: NgbActiveModal,
  ) {
    MixpanelService.track('ShowModal fast perks');
  }


  ngOnInit(): void {
    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {
        this.setFastPerksPortal(account.user.product_id);
      });
  }

  private setFastPerksPortal(productId: number) {
    switch (productId) {
      case 1:
        this.fastPerksUrl = 'https://ukcreditratings.fast-perks.com';
        break;
      case 2:
        this.fastPerksUrl = 'https://myukcreditreport.fast-perks.com/';
        break;
      case 3:
        this.fastPerksUrl = 'https://studentcreditchecker.fast-perks.com';
        break;
      case 4:
        this.fastPerksUrl = 'https://creditscorepro.fast-perks.com';
        break;
      default:
        this.fastPerksUrl = 'https://ukcreditratings.fast-perks.com';
    }
  }

  openSite(): void {
    MixpanelService.track('Fast Perks Modal - Click link');

    window.open(this.fastPerksUrl, '_blank');
    this.close();
  }

  close() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}
