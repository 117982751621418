import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-credit-score-progress-bar',
  templateUrl: './credit-score-progress-bar.component.html',
  styleUrls: ['./credit-score-progress-bar.component.scss']
})
export class CreditScoreProgressBarComponent{

  /**
   * Expects rating as percent value with %
   */
  @Input() public ratingBar: string;

  @Input()
  public reportDate: string;

  constructor() {
  }
}
