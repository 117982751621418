import {Injectable, SecurityContext} from '@angular/core';
import {Alert} from './alert';
import {ApiError} from '../../models/error/api-error.model';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class AlertService {

    private alertId = 0;
    private alerts: Alert[] = [];
    private timeout = 0;

    /**
     * Message Replacement Map for replacing Vendor Error Messages with more user friendly messaging
     * @type {{token_not_provided: string}}
     */
    private messageMap = {
        token_not_provided: 'Your session has expired.  Please log in.',
        token_expired: 'Your session has expired.  Please log in.',
        token_invalid: 'Your session has expired.  Please log in.'
    };


    constructor(private sanitizer: DomSanitizer) {
    }

    clear() {
        this.alerts.splice(0, this.alerts.length);
    }

    get(): Alert[] {
        return this.alerts;
    }

    success(msg: string, params?: any, position?: string) {
        this.addAlert({
            type: 'success',
            msg,
            params,
            timeout: this.timeout,
            position
        }, []);
    }

    error(msg: string, params?: any, position?: string) {

        this.addAlert({
            type: 'danger',
            msg,
            params,
            timeout: this.timeout,
            position
        }, []);
    }

    warning(msg: string, params?: any, position?: string) {
        this.addAlert({
            type: 'warning',
            msg,
            params,
            timeout: this.timeout,
            position
        }, []);
    }

    info(msg: string, params?: any, position?: string) {
        this.addAlert({
            type: 'info',
            msg,
            params,
            timeout: this.timeout,
            position
        }, []);
    }

    addAlert(alertOptions: Alert, extAlerts: Alert[]) {

        if (this.messageMap.hasOwnProperty(alertOptions.msg)) {
            alertOptions.msg = this.messageMap[alertOptions.msg];
        }

        if (this.isDuplicate(alertOptions) === false) {
            alertOptions.id = this.alertId++;

            this.factory(alertOptions);

            if (alertOptions.timeout && alertOptions.timeout > 0) {
                setTimeout(() => {
                    this.closeAlert(alertOptions.id, extAlerts);
                }, alertOptions.timeout);
            }
        }
    }

    private factory(alertOptions) {

        const alert: Alert = {
            type: alertOptions.type,
            msg: this.sanitizer.sanitize(SecurityContext.HTML, alertOptions.msg),
            id: alertOptions.id,
            timeout: alertOptions.timeout,
            position: alertOptions.position ? alertOptions.position : 'top right',
            scoped: alertOptions.scoped,
            close: (alerts) => {
                return this.closeAlert(alertOptions.id, alerts);
            }
        };

        if (!alert.scoped) {
            this.alerts.push(alert);
        }
    }

    /**
     * Check for duplicate messages
     * @param alertOptions
     * @returns {boolean}
     */
    private isDuplicate(alertOptions) {

        let isDuplicate = false;

        this.alerts.map(alert => {
            if (alertOptions.msg === alert.msg) {
                isDuplicate = true;
            }
        });

        return isDuplicate;
    }


    addApiError(apiError: ApiError) {
        const error = '<strong>' + apiError.code + ' ' + apiError.title + '</strong>: ' + apiError.detail;
        this.error(error);
    }

    handleApiErrors(err) {
        if (err.error.hasOwnProperty('errors')) {
            err.error.errors.map(error => {
                // Are the errors an array of API Error Objects or an array of strings
                if (typeof error === 'string') {
                    this.error(error);
                } else {
                    this.addApiError(error);
                }
            });
        } else if (typeof err.error === 'object') {
            this.addApiError(err.error);
        } else {
            this.error(err.error);
        }
    }

    closeAlert(id: number, extAlerts?: Alert[]): any {
        const thisAlerts = (extAlerts && extAlerts.length > 0) ? extAlerts : this.alerts;
        return this.closeAlertByIndex(thisAlerts.map(function (e) {
            return e.id;
        }).indexOf(id), thisAlerts);
    }

    closeAlertByIndex(index: number, thisAlerts: Alert[]): Alert[] {
        return thisAlerts.splice(index, 1);
    }




    // notify(alert) {
    //     this.onNewAlertEvent.emit(alert);
    // }

}
