<ng-template #leadpost_confirmation_view>
  <p class="pt-4 pb-2 px-2">
    By clicking you agree UKCreditRatings.com may share your information to be contacted by our trusted partner,
    Caspian to find you the best available life insurance on the market
  </p>

  <button
    class="btn btn-lg btn-primary mt-3 mb-3 px-5"
    (click)="btnClick()">
    Get a Quote Today
  </button>

  <p class="pt-4 pb-2 px-2 small text-justify">
    <a href="https://caspianinsurance.co.uk/" target="_blank">Caspian Insurance Services</a> is a trading style of Caspian Assured Ltd, registered address Tower 12, Bridge St, Spinningfields, Manchester, M3 3BZ. Caspian Assured Ltd is directly authorised and regulated by the Financial Conduct Authority under firm reference number 788964. Your policy is covered by the Financial Services Compensation Scheme. Their privacy policy is available <a href="https://caspianinsurance.co.uk/privacy-policy" target="_blank">here</a>.  Data Protection Reference Number: ZA192229

  </p>

</ng-template>


<ng-template #leadpost_response_view>
  <p class="pt-4 pb-2 px-2">
    One of our partner's advisers will call you shortly. They offer a range of products from Life insurance to Critical
    Illness Cover and Income Protection Insurance, they will compare the market to find the best price with free advice.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    OK
  </button>
</ng-template>


<ng-template #in_progress_view>
  <p class="pt-4 pb-2 px-2">
    Please wait...
  </p>
</ng-template>


<ng-template #error_response_view>
  <p class="pt-4 pb-2 px-2">
    Sorry, an error occurred while transmitting your info to Caspian Insurance.  Please try again later.
  </p>

  <button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">
    Close
  </button>
</ng-template>


<div class="modal-body">

  <div class="py-2 text-center">
    <div class="icon-container">
      <img class="my-3 img-fluid justify-content-center align-self-center h-100" src='/assets/images/cta/LGO_CaspianInsurance.svg' />
    </div>

    <div class="form-container">
      <div [ngTemplateOutlet]="templateName"></div>
    </div>

  </div>

</div>
