import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {map} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {filterNilVal} from 'src/app/shared/utils/filters';
import {IAccount} from 'src/app/shared/models/account';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-partner-web-post',
  templateUrl: './partner-web-post.component.html',
  styleUrls: ['./partner-web-post.component.scss']
})
export class PartnerWebPostComponent {

  afid$: Observable<number>;

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private store: Store
  ) {
    this.afid$ = this.store.select(SessionState.getAccount)
      .pipe(
        filterNilVal(),
        map((account: IAccount) => account.user.afid)
      );
  }

  gotoWebPostPage() {
    this.router.navigateByUrl('/account/web-post-report');
    this.activeModal.close(IaoModalResultEnum.StopChain);
  }

  close() {
    this.activeModal.close(IaoModalResultEnum.ContinueChain);
  }
}
