import {AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Rating} from 'src/app/shared/models/rating';
import {CreditReport} from 'src/app/shared/models/credit-report';
import {UserStatus} from 'src/app/shared/models/user-status';
import {BrokenEnrollService} from '../../../iao-chain/broken-enroll/broken-enroll.service';
import {Observable, Subscription} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';

@Component({
  selector: 'app-credit-score',
  templateUrl: './credit-score.component.html',
  styleUrls: ['./credit-score.component.scss']
})
export class CreditScoreComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('dashPlaceholder', {static: true}) dashPlaceholder: TemplateRef<any>;
  @ViewChild('scoredPlaceholder', {static: true}) scoredPlaceholder: TemplateRef<any>;
  @ViewChild('noReportManualVerify', {static: true}) noReportManualVerify: TemplateRef<any>;
  @ViewChild('noReportThinFile', {static: true}) noReportThinFile: TemplateRef<any>;
  @ViewChild('noReportActive', {static: true}) noReportActive: TemplateRef<any>;
  @ViewChild('noReportBrokenEnrollment', {static: true}) noReportBrokenEnrollment: TemplateRef<any>;

  public rating: Rating = {
    RatingValue: 0,
    Score: 0
  };

  public ratingBar = 0 + '%';

  public scoreViewTemplate: TemplateRef<any>;

  public today = '';

  @Input() public userStatus: string;
  @Input() public disableWarningAction = false;

  private reportSvcUpdateEvent: Subscription;
  account$: Observable<IAccount>;

  constructor(
    private accountService: AccountService,
    private reportService: CreditReportService,
    private brokenEnrollService: BrokenEnrollService,
  ) {
    this.setTodayDate();
    this.account$ = this.accountService.getAccount();
  }

  ngOnInit(): void {
    // Place holder needs to be set in ngOnInit(), otherwise we get an ExpressionChangedAfterItHasBeenCheckedError
    // saying that scoreViewTemplate used to be undefined, then changed to dashPlaceholder.
    this.scoreViewTemplate = this.dashPlaceholder;
  }

  ngAfterViewInit() {
    // Delay 10ms to kick it into the next Angular loop.  Otherwise we get an ExpressionChangedAfterItHasBeenCheckedError
    // saying that ratingBar changed from 0% to nn%.  This delay helps Angular stabilize with 0% first.
    setTimeout(() => { this.refresh(); }, 10);

    this.reportSvcUpdateEvent = this.reportService.shouldUpdate.subscribe( () => {
      this.refresh();
    });
  }

  private refresh(): void {
    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        if (!creditReport.isEmpty()) {
          this.rating = creditReport.report.Rating;
          this.ratingBar = (this.rating.Score / 710 * 100) + '%';
          this.scoreViewTemplate = this.scoredPlaceholder;
         } else {
          this.setNoReportTemplate(creditReport);
        }
      });
  }

  private setNoReportTemplate(creditReport) {

    if (creditReport.reportUnavailable && this.userStatus === UserStatus.Active ) {
      this.scoreViewTemplate = this.noReportActive;

    } else if (this.userStatus === UserStatus.BrokenEnrollment) {
      this.scoreViewTemplate = this.noReportBrokenEnrollment;

    } else if (this.userStatus === UserStatus.Manual) {
      this.scoreViewTemplate = this.noReportManualVerify;

    } else if (this.userStatus === UserStatus.ThinFile) {
      this.scoreViewTemplate = this.noReportThinFile;
    }
  }

  private setTodayDate(){
    const date = new Date();
    this.today = date.getDate() + '/' + ((date.getMonth() + 1)) + '/' + date.getFullYear();
  }

  completeRegistration() {
    this.brokenEnrollService.start();
  }

  ngOnDestroy(): void {
    this.reportSvcUpdateEvent.unsubscribe();
  }
}
