import {Address} from './address.model';
import {Employment} from './employment.model';

export class Applicant {
  public Forename: string;
  public Surname: string;
  public Email: string;
  public Mobile: string;
  public Nationality: string;
  public MaritalStatus: string;
  public DrivingLicenseType: string;
  public Addresses: Array<Address>;
  public Employment: Array<Employment>;
}
