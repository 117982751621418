import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from './alert.service';
import {Alert} from './alert';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  alerts: Alert[];

  constructor(private alertService: AlertService) { }

  ngOnInit() {
      this.alerts = this.alertService.get();
  }

  refresh(alert) {
      this.alerts = this.alertService.get();
  }

  ngOnDestroy() {
    this.alerts = [];
  }

}
