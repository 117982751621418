import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable, Subscription, timer} from 'rxjs';
import {concatMap, filter, map, share, take, tap} from 'rxjs/operators';
import {CTA} from '../models/cta/cta.interface';
import {CtaSet} from '../models/cta/cta-set.interface';
import {ChangeCtaStatusRequest} from '../models/cta/change-cta-status-request.interface';
import {CreditReportService} from './credit-report.service';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {CtaStatus} from '../models/cta/cta-status.enum';
import {AccountService} from './account.service';
import {CtaCategoryService} from 'src/app/shared/services/cta-category.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {CtaState, CTAStateModel} from 'src/app/shared/state/cta/cta.state';
import {UpdateCta, UpdateCtas} from 'src/app/shared/state/cta/cta.actions';


@Injectable({
  providedIn: 'root',
})
export class CtaService {

  private isCtaTest = false;

  private resources = {
    url: '/api/cta',
    testUrl: '/api/cta/all'
  };

  private resource = this.resources.url;

  /**
   * How many times we polled for CTAs while waiting for them to be ready
   */
  private pollCounter = 0;
  /**
   * Maximum number of times we will poll for CTAs. This is to prevent it polling
   * infinitely if something went wrong.
   * 100 = 5 minutes, if polling remains @ 3 seconds
   */
  private MAX_POLL_ATTEMPTS = 10;
  private pollSubscription: Subscription;
  private hasOfferAccess = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private store: Store,
    private creditReportService: CreditReportService,
    private accountService: AccountService,
    private categoryService: CtaCategoryService,
  ) {
    // Clear out local cache when we logout.  This probably isn't necessary, since logging out fires ClearState
    this.store.select(SessionState.getToken)
      .subscribe(token => {
        if (!token) {
          this.store.dispatch(new UpdateCtas([]));

          if (this.pollSubscription) {
            this.pollSubscription.unsubscribe();
          }
        }
      });

    // Determine if this account has access to offers.  Store this in a member variable, because I can't figure out how to do this
    // in an rxjs way that isn't incredibly confusing.
    this.accountService
      .hasAccessScope('use-offers')
      .subscribe(access => this.hasOfferAccess = access);

    // This will immediately begin waiting for the credit report to finish loading.  Once that's done,
    // we begin polling for CTAs
    this.creditReportService
      .reportFetchEventObs
      .subscribe(report => {
        this.route.queryParams.subscribe(params => {
          // If test CTA qs arg, then change resource to get all CTA
          this.isCtaTest = params['isCtaTest'] ? params['isCtaTest'] : false;
          this.resource = (this.isCtaTest) ? this.resources.testUrl : this.resources.url;
          if (report) {
            this.pollToCompletion();
          }
        });
      });
  }


  hasData(): boolean {
    const ctas = this.store.selectSnapshot<CTA[]>((state: CTAStateModel) => state.ctas);
    return (ctas && ctas.length > 0);
  }


  /**
   * Return all CTAs
   */
  get(): Observable<CTA[]> {
    return this.store.select(CtaState.getCtas);
  }

  /**
   * Ask the server for the latest CTAs, and update the state
   */
  fetch(): Observable<CtaSet> {

    return this.http
      .get<CtaSet>(this.resource)
      .pipe(
        share(),
        map(ctaSet => {
          if (!this.hasOfferAccess && ctaSet.hasData) {
            ctaSet.data = ctaSet.data.filter(cta => !cta.is_offer);
          }

          return ctaSet;
        }),
        tap(
          ctaSet => {
            if (ctaSet.data.length > 0) {
              this.store.dispatch(new UpdateCtas(ctaSet.data));
              // Update category list.
              this.categoryService.fetch().subscribe(genericErrorObserver);
            }
          },
        ),
      );
  }


  /**
   * Poll backend for CTAs until we receive data
   * TODO: stop polling if we log out
   */
  private pollToCompletion(): void {
    this.pollCounter = 0;

    this.pollSubscription = timer(0, 3000)
      .pipe(
        concatMap(() => from(this.fetch())),
      )
      .pipe(
        tap(() => {
          ++this.pollCounter;

          if (this.pollCounter > this.MAX_POLL_ATTEMPTS) {
            this.pollSubscription.unsubscribe();
          }
        }),
        filter((ctaSet: CtaSet) => ctaSet.hasData === true))
      .pipe(
        take(1)
      )
      .subscribe(() => {
        // Nothing since fetch already handles setting data
      });
  }


  /**
   * Change a CTA status for the current user
   */
  updateStatus(changeCtaStatusRequest: ChangeCtaStatusRequest) {
    return this.http.post<CTA>('/api/cta/status', changeCtaStatusRequest)
      .pipe(
        tap(
          (cta: CTA) => {
            if (changeCtaStatusRequest.status !== CtaStatus.CLICKED) {
              this.store.dispatch(new UpdateCta(cta));
            }
          },
        ),
      );
  }

  public getCta(id: number): Observable<CTA> {
    return this.store.select(CtaState.getCta(id));
  }
}
