import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LoginRequest} from 'src/app/core/models/login-request';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {Router} from '@angular/router';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {LoginResponse} from 'src/app/core/models/login-response';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-one-time-password-login',
  templateUrl: './one-time-password-login.component.html',
  styleUrls: ['./one-time-password-login.component.scss']
})
export class OneTimePasswordLoginComponent implements OnInit {

  @ViewChild('passwordInput') private passwordInput: ElementRef;

  loginRequest: LoginRequest = {
    email: '',
    password: ''
  };

  submitting = false;

  constructor(
    private loadIndicatorService: LoadIndicatorService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private notificationService: ToastService,
  ) {
  }

  ngOnInit(): void {
  }

  login(): void {
    if (this.submitting) {
      return; // prevent repeated/stacked login attempts
    }

    this.loadIndicatorService.push('otp-auth');
    this.submitting = true;

    this
      .authService.otpLogin(this.loginRequest)
      .subscribe({
        next: (resp: LoginResponse): void => {
          this.handleLoginResponse(resp);
        },
        error: (err: any): void => {
          this.handleErrorResponse(err);
        },
      });
  }

  private handleLoginResponse(resp: LoginResponse) {
      this.accountService.fetch().subscribe(() => {
        this.gotoLandingPage();
        this.loadIndicatorService.pop('otp-auth');
        this.submitting = false;
      });
  }

  private handleErrorResponse(err: HttpErrorResponse) {
    if (err.status !== 429) {
      this.notificationService.error('Invalid login.  Please try again.');
      this.loginRequest.password = '';
      this.passwordInput.nativeElement.focus();
    }
    this.loadIndicatorService.pop('otp-auth');
    this.submitting = false;
  }


  /**
   * Check if last visited route exists and go there, else default to dashboard.
   */
  private gotoLandingPage(): void {
    this.router.navigateByUrl('/dashboard');
  }
}
