<div class="detail-page">

  <div class="container">

    <div class="row">
      <div class="offset-md-1 col-md-10">

        <div class="pt-5 pb-3">
          <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
        </div>

        <section [ngSwitch]="status">

          <!-- Success -->
          <div class="text-center" *ngSwitchCase="emailVerificationStatus.VERIFIED">
            <h4>Congratulations, your {{type}} is now verified!</h4>
            <p>Your 1 step away from protecting yourself from potential fraud on the Dark Web. Click the button below to review if any of your personal data is currently at risk of being breached </p>
            <button class="btn btn-primary mt-5" routerLink="/dark-web"> See My Dark Web Results </button>
          </div>

          <!-- Failed -->
          <div class="text-center" *ngSwitchCase="emailVerificationStatus.FAILED">
            <p>Failed to verify email. <br/>You may return to Dark Web Email Activation and try resending the verification email to generate a new verification token.</p>
            <button class="btn btn-primary mt-5" routerLink="/dark-web">Return to Dark Web Email Activation</button>
          </div>

          <!-- Verifying -->
          <div class="text-center" *ngSwitchDefault>
            <p>Verifying... Please wait</p>
          </div>

        </section>

      </div>
    </div>

  </div>
</div>
