<div class="pad-resp-card py-5 mx-2 text-center">

  <div class="text-center pb-3">
    <img id="icon" src="assets/images/ICN_CallCenter.svg" alt="Consultation call"/>
  </div>

  <h2 class="pb-3">
    <span *ngIf="existingEvent">We're Ready For You</span>
    <span *ngIf="!existingEvent">Book Your One on One Call</span>
  </h2>

  <ng-template #explainStep>
  <p>
    <strong>For a truly unique service, our membership includes a regular, personalised, one to one credit consultation call with one of our
      credit expert team.</strong>
    They will review your credit report with you, helping you to check for potential errors, raising disputes where necessary, and making
    sure that you're doing everything possible to ensure your credit report and score presents you in the best light possible.
  </p>
  <p>
    They will also review the findings of your DarkWeb report, giving you an action plan of how to best protect your online identity and
    personal data. Book your call now through our calendar system.
  </p>

    <div class="d-flex justify-content-evenly mt-3">
      <button class="btn btn-secondary px-4 rounded-pill btn-lg" (click)="close()">Skip Call</button>
      <button class="btn btn-primary px-4 rounded-pill btn-lg" (click)="showCalendar()">Book Call</button>
    </div>
  </ng-template>

  <ng-template #calendarStep>
  <div class="calendar-wrap">
    <div #calendly class="calendly-inline-widget"></div>

    <div class="d-flex justify-content-evenly mt-3">
      <button class="btn btn-secondary px-4 rounded-pill btn-lg" (click)="close()" *ngIf="!booked">Skip Call</button>
      <button class="btn btn-primary px-4 rounded-pill btn-lg" (click)="close()" *ngIf="booked">Book Call</button>
    </div>
  </div>
  </ng-template>

  <ng-template #eventExistsStep>
    <p>Your review has been scheduled for:</p>
    <p class="pt-2 fw-bold">{{existingEvent.start_time | date:	'd MMM Y, HH:mm:ss a'}}</p>
  </ng-template>

  <div [ngTemplateOutlet]="currentTemplate"></div>

</div>
