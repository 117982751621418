import {Injectable} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import {OfferConsentModalComponent} from '../../modules/offers/components/offer-consent-modal/offer-consent-modal.component';
import {AccountService} from './account.service';
import {OffersLightboxComponent} from '../../modules/offers/components/offers-lightbox/offers-lightbox.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MixpanelService} from '../mixpanel/mixpanel.service';
import {genericErrorObserver} from '../observables/generic-error.observer';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {CtaCategory} from 'src/app/shared/models/cta/cta-category.enum';
import {IAccount} from 'src/app/shared/models/account';


@Injectable({
  providedIn: 'root'
})
export class SmartOfferService {

  private account: IAccount;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
  ) {
    accountService.getAccount().subscribe(acct => this.account = acct);
  }

  hasConsentedSmartOffers(): boolean {
    return (this.account && this.account.settings && this.account.settings.hasConsentedSmartOffers === true);
  }

  /**
   * Open either the consent modal or the offer lightbox depending on if the customer consented yet.
   * YOU SHOULD ALMOST ALWAYS USE THIS; it will call out to the consent/offer modal as necessary.
   */
  async open(category: CtaCategory = null) {
    if (this.hasConsentedSmartOffers()) {
      this.openOfferLightBox(category);
    } else {
      const hasConsented = await this.openOfferConsentModal();

      if (hasConsented) {
        this.openOfferLightBox(category);
      }
    }
  }

  /**
   * If this customer hasn't given consent, this should be opened first.
   */
  openOfferConsentModal(): Promise<boolean> {
    const modalOptions: NgbModalOptions = {
      size: 'md'
    };

    return new Promise((resolve) => {
      MixpanelService.track('ShowModal smart offers consent');

      this.accountService
        .updateSetting('hasSeenSmartOffers', true)
        .subscribe(genericErrorObserver);

      this.modalService
        .open(OfferConsentModalComponent, modalOptions)
        .result
        .then(
          result => {
            if (result.hasConsented === true) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        )
        .catch(() => {
          resolve(false);
        });
    });
  }

  /**
   * This should only be called if we have consent.
   */
  openOfferLightBox(ctaCategory: CtaCategory = null): NgbModalRef {
    MixpanelService.track('ShowModal smart offers');

    const modalOptions: NgbModalOptions = {
      size: 'md'
    };

    const modalRef = this.modalService.open(OffersLightboxComponent, modalOptions);

    // Filter by category, or not if null is passed.
    modalRef.componentInstance.filterByCategory = ctaCategory;

    // Ensure we catch rejects
    modalRef.result.then().catch(() => {});

    return modalRef;
  }
}
