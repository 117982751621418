<form [formGroup]="passwordResetForm" (ngSubmit)="update()">
  <div class="row mb-2" *ngIf="showCurrentPassword">
    <div class="col-12">
      <label for="change-pw-current-pw" class="sr-only">Current Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="password"
        id="change-pw-current-pw"
        placeholder="Current Password"
        [ngClass]="{ 'is-invalid': formControls.password.touched && formControls.password.errors }">

      <div class="invalid-feedback" *ngIf="formControls.password.touched && formControls.password.hasError('required')">Required</div>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12">
      <label for="change-pw-newPassword" class="sr-only">New Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="newPassword"
        id="change-pw-newPassword"
        placeholder="New Password"
        [ngClass]="{ 'is-invalid': formControls.newPassword.touched && formControls.newPassword.errors }">

      <div class="invalid-feedback" *ngIf="formControls.newPassword.touched && formControls.newPassword.hasError('required')">Required</div>
      <div class="invalid-feedback">Please enter a password with at least 10 characters</div>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12">
      <label for="change-pw-confirm-pw" class="sr-only">Confirm New Password</label>
      <input
        type="password"
        class="form-control"
        formControlName="newPassword_confirmation"
        id="change-pw-confirm-pw"
        placeholder="Confirm Password"
        [ngClass]="{ 'is-invalid': formControls.newPassword_confirmation.touched && formControls.newPassword_confirmation.errors }">

      <div class="invalid-feedback"
           *ngIf="formControls.newPassword_confirmation.touched && formControls.newPassword_confirmation.errors && formControls.newPassword_confirmation.errors.hasOwnProperty('required')">
        Please confirm your
        password entry
      </div>
      <div class="invalid-feedback"
           *ngIf="formControls.newPassword_confirmation.touched && formControls.newPassword_confirmation.errors && formControls.newPassword_confirmation.errors.hasOwnProperty('passwordsnotmatch')">
        Passwords
        do not match
      </div>
    </div>
  </div>

  <div class="row m-2">
    <div class="col-10 offset-1 col-lg-8 offset-lg-2 d-grid pt-2">
      <button type="submit" class="btn btn-block rounded-pill btn-primary" [disabled]="!hasChanges() || isSubmitted">Submit</button>
    </div>
  </div>

  <ng-container *ngIf="showSocialLogins">
    <hr>

    <app-google-integration></app-google-integration>
  </ng-container>
</form>
