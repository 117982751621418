<ng-template #leadpost_form>
  <p class="pt-4 pb-2 px-2 text-start">
  Historically rent payments were never reported to Credit Reference Agencies. The great news is CreditLadder has helped thousands of tenants improve their credit score, and you could be next.
  </p>

  <div class="py-2 px-2">
    <form name="form" #creditLadderForm="ngForm" (ngSubmit)="creditLadderForm.form.valid && onSubmit()" id="credit-ladder-form">

      <!-- (yes/no) -->
      <div class="form-group text-center pb-4">
        <label>Are you currently paying rent?</label>
        <div class="">
          <div class="form-check form-check-inline">
            <label class="form-check-label" for="hasRentPaymentsYes">
              <input class="form-check-input" type="radio" name="hasRentPayments" id="hasRentPaymentsYes" [value]="true" [(ngModel)]="form.hasRentPayments" #hasRentPayments="ngModel">
              <span>Yes</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label" for="hasRentPaymentsNo">
              <input class="form-check-input" type="radio" name="hasRentPayments" id="hasRentPaymentsNo" [value]="false" [(ngModel)]="form.hasRentPayments" #hasRentPayments="ngModel">
              <span>No</span>
            </label>
          </div>
          <div class="invalid-feedback" *ngIf="(hasRentPayments || hasRentPayments.touched || creditLadderForm.submitted) && hasRentPayments.invalid">Required</div>
        </div>
      </div>


      <div class="text-center" *ngIf="form.hasRentPayments === false">
        <p>Unfortunately, CreditLadder is unable to provide you services. Please contact our call center if you have any questions.</p>
        <button
          class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
          (click)="close()">
          Close
        </button>
      </div>


      <div *ngIf="form.hasRentPayments" class="my-4 mx-5">
        <button class="btn btn-lg btn-block btn-primary">Start Reporting</button>
        <p class="small pt-1">
          By clicking, you agree we may share your information with our trusted partner, Credit Ladder, to start the reporting process.
        </p>
      </div>

    </form>
  </div>
</ng-template>


<ng-template #leadpost_success>
  <p class="pt-4 pb-2 px-2">
    A new browser tab or window to Credit Ladder should have opened to complete the process. If not, please click
    <a [href]="creditLadderResponse.link" target="_blank">here</a>
  </p>

  <button type=button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">OK</button>
</ng-template>


<ng-template #leadpost_error>
  <p class="pt-4 pb-2 px-2">
    Sorry, an error occurred while transmitting your info to Credit Ladder.  Please try again later.
  </p>

  <button type=button
    class="btn btn-lg btn-secondary mt-3 mb-3 px-5"
    (click)="close()">Close</button>
</ng-template>


<div class="modal-body">

  <div class="py-2 text-center">
    <div class="icon-container">
      <img class="my-3 img-fluid justify-content-center align-self-center" src='/assets/images/cta/LGO_CreditLadder.svg' />
    </div>

    <div class="form-container">
      <div [ngTemplateOutlet]="template"></div>
    </div>

  </div>

</div>
