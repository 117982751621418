import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BrandService} from 'src/app/shared/services/brand.service';
import {map} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'app-offer-consent-modal',
  templateUrl: './offer-consent-modal.component.html',
  styleUrls: ['./offer-consent-modal.component.scss'],
  standalone: true,
  imports: [AsyncPipe],
})
export class OfferConsentModalComponent {

  fname$: Observable<string>;
  termsUrl = BrandService.getTermsOfUseUrl();
  privacyPolicyUrl = BrandService.getPrivacyPolicyUrl();

  constructor(
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
  ) {
    this.fname$ = accountService
      .getAccount()
      .pipe(
        map(account => account.user.fname),
      );
  }

  consent(): void {
    const d = new Date();

    this.accountService
      .updateSetting({hasConsentedSmartOffers: true, consentedSmartOffersTimestamp: d.toISOString()})
      .subscribe(genericErrorObserver);

    this.activeModal.close({hasConsented: true});
  }

  reject(): void {
    this.activeModal.close({hasConsented: false});
  }
}
