<div *ngIf="addressFormArray.length > 0">

  <!-- -->
  <div [formGroup]="formGroup">

    <!-- FormArrayName tells Parent what Child Form this is -->
    <div formArrayName="addresses">

      <!-- Loop over Forms Array of Addresses -->
      <div *ngFor="let addCont of addressFormArray.controls; let i=index">
        <div [formGroupName]="i" class="address-row py-1 mb-1">

          <div class="row mb-1" *ngIf="i>0">
            <div class="col-12 p-1 d-flex justify-content-end">
              <div><button type="button" class="btn-sm btn-close clickable" aria-label="Close" ngbTooltip="Remove Address" (click)="removeAddress(i)"></button></div>
<!--              <div><i class="fa-lg fa-regular fa-circle-xmark close clickable"  ngbTooltip="Remove Address" (click)="removeAddress(i)"></i></div>-->
            </div>
          </div>

          <!-- ROW 1 -->
          <div class="row">

            <div class="col-sm-2 mb-2">
              <!-- BuildingNumber -->
              <input
                aria-label="BuildingNumber"
                type="text"
                class="form-control form-control-sm position-static"
                formControlName="buildingNumber"
                id="buildingNumber"
                placeholder="Bldg Num"
                [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'buildingNumber') || errors.hasOwnProperty('Addresses.' + i + '.buildingName')}">
              <div class="invalid-feedback" *ngIf="hasError(i,'buildingNumber', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.buildingNumber')">{{errors['Addresses.' + i + '.buildingNumber']}}</div>
            </div>

            <div class="col-sm-3 mb-2">
              <!-- BuildingName -->
              <input
                  aria-label="Building Name"
                  type="text"
                  class="form-control form-control-sm position-static"
                  formControlName="buildingName"
                  id="buildingName"
                  placeholder="Bldg Name"
                  [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'buildingName') || errors.hasOwnProperty('Addresses.' + i + '.buildingName')}">
                <div class="invalid-feedback" *ngIf="hasError(i,'buildingName', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.buildingName')">{{errors['Addresses.' + i + '.buildingNumber']}}</div>
            </div>

              <div class="col-sm-4 mb-2">
                <!-- Street -->
                <input
                  aria-label="Street"
                  type="text"
                  class="form-control form-control-sm position-static"
                  formControlName="street"
                  id="street"
                  placeholder="Street"
                  [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'street') || errors.hasOwnProperty('Addresses.' + i + '.street')}">
                <div class="invalid-feedback" *ngIf="hasError(i,'street', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.street')">{{errors['Addresses.' + i + '.street']}}</div>
              </div>

            <div class="col-sm-2 mb-2">
              <!-- SubBuildingName -->
              <input
                aria-label="subBuildingName"
                type="text"
                class="form-control form-control-sm position-static"
                formControlName="subBuildingName"
                id="subBuildingName"
                placeholder="Abode">
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="row">
            <div class="col-sm-2 mb-2">
                <!-- County -->
                <input
                  aria-label="County"
                  type="text"
                  class="form-control form-control-sm position-static"
                  formControlName="county"
                  id="county"
                  placeholder="County"
                  [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'county') || errors.hasOwnProperty('Addresses.' + i + '.county')}">
                <div class="invalid-feedback" *ngIf="hasError(i,'county', 'required')">Required</div>
                <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.county')">{{errors['Addresses.' + i + '.county']}}</div>
            </div>

            <div class="col-sm-4 mb-2">
              <!-- Town -->
              <input
                aria-label="Town"
                type="text"
                class="form-control form-control-sm position-static"
                formControlName="town"
                id="town"
                placeholder="Town"
                [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'town') || errors.hasOwnProperty('Addresses.' + i + '.town')}">
              <div class="invalid-feedback" *ngIf="hasError(i,'town', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.town')">{{errors['Addresses.' + i + '.town']}}</div>
            </div>

            <div class="col-sm-2 mb-2">
                <!-- Postcode -->
                <input
                  aria-label="Postcode"
                  type="text"
                  class="form-control form-control-sm position-static"
                  formControlName="postcode"
                  id="postcode"
                  placeholder="Postcode"
                  [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'postcode') || errors.hasOwnProperty('Addresses.' + i + '.postcode')}">
                <div class="invalid-feedback" *ngIf="hasError(i,'postcode', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('Addresses.' + i + '.postcode')">{{errors['Addresses.' + i + '.postcode']}}</div>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="row">
            <div class="col-sm-4 mb-2">
              <!-- Residential Status -->
              <select id="residentialStatus" class="form-control form-control-sm" formControlName="residentialStatus"
                      [ngClass]="{ 'is-invalid':  isSubmitted && isInvalid(i, 'residentialStatus') || errors.hasOwnProperty('Addresses.' + i + '.residentialStatus')}">
                <option value="" disabled selected>Residential Status</option>
                <option *ngFor="let residentialStatus of residentialStatuses; let i = index" [ngValue]="residentialStatus">
                  {{residentialStatus}}
                </option>
              </select>
              <div class="invalid-feedback" *ngIf="hasError(i,'residentialStatus', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('residentialStatus')">{{errors['Addresses.' + i + '.residentialStatus}}</div>
            </div>

            <div class="col-sm-3 mb-2">
              <!-- TimeAtAddressYears -->
              <input
                aria-label="TimeAtAddressYears"
                type="text"
                class="form-control form-control-sm position-static"
                formControlName="timeAtAddressYears"
                id="timeAtAddressYears"
                placeholder="Years"
                [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'timeAtAddressYears') || errors.hasOwnProperty('Addresses.' + i + '.timeAtAddressYears')}">
              <div class="invalid-feedback" *ngIf="hasError(i,'timeAtAddressYears', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('timeAtAddressYears')">{{errors['Addresses.' + i + '.timeAtAddressYears}}</div>
            </div>

            <div class="col-sm-3 mb-2">
              <!-- TimeAtAddressMonths -->
              <input
                aria-label="TimeAtAddressMonths"
                type="text"
                class="form-control form-control-sm position-static"
                formControlName="timeAtAddressMonths"
                id="timeAtAddressMonths"
                placeholder="Months"
                [ngClass]="{ 'is-invalid': isSubmitted && isInvalid(i, 'timeAtAddressMonths') || errors.hasOwnProperty('Addresses.' + i + '.timeAtAddressMonths')}">
              <div class="invalid-feedback" *ngIf="hasError(i,'timeAtAddressMonths', 'required')">Required</div>
              <div class="invalid-feedback" *ngIf="errors.hasOwnProperty('timeAtAddressMonths')">{{errors['Addresses.' + i + '.timeAtAddressMonths}}</div>
            </div>
          </div>

        </div>
      </div>

      <!-- Add Address -->
        <div class="row">
          <div class="col-12">
            <p class="small clickable float-end" (click)="addAddress()"><i class="far fa-plus-square"></i> Add Address</p>
          </div>
        </div>

    </div>
  </div>
</div>
