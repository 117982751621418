import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-broken-welcome',
  templateUrl: './broken-enroll-kba-intro.component.html',
  styleUrls: ['./broken-enroll-kba-intro.component.scss']
})
export class BrokenEnrollKbaIntroComponent {

  constructor(public activeModal: NgbActiveModal) {
  }
}
