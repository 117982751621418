<app-alert></app-alert>

<app-loadindicator></app-loadindicator>

<!-- Header Top Bar -->
<div class="top-bar" id="top-bar">
  <a id="navbar-brand" class="navbar-brand ms-3 mt-2 float-start" [routerLink]="['/dashboard/home']" >
    <img class="mx-auto d-inline-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" alt="Portal Home" />
  </a>


</div>

<mat-sidenav-container class="ukc-sidenav">

  <!-- MENU -->
<!--  <mat-sidenav #sidenav mode="side" position="end" (click)="toggleSideNav()" [fixedInViewport]="true" role="navigation">-->
<!--    <app-nav></app-nav>-->
<!--  </mat-sidenav>-->

  <!-- CONTENT -->
  <mat-sidenav-content  role="main">

    <!-- Category Nav
    <div class="category-nav-container">
      <app-offer-nav-links></app-offer-nav-links>
    </div>
-->
    <!--<router-outlet name="header"></router-outlet>-->
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    <!--<router-outlet name="footer"></router-outlet>-->

    <app-footer></app-footer>



    <!--<app-toast-container></app-toast-container>-->

  </mat-sidenav-content>

</mat-sidenav-container>

<app-toast></app-toast>
