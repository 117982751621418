import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ResidentialStatusEnum} from '../models/residential-status.enum';
import {Account} from 'src/app/shared//models/account';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  /**
   * Provides access to the parent FormGroup
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Parent form submit state
   */
  @Input() isSubmitted: boolean;

  /**
   * Provides access to the parent form errors
   */
  @Input() errors: object;

  @Input() account: Account;

  public residentialStatuses = Object.keys(ResidentialStatusEnum);


  constructor(
    public formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.addAddress();

    // Pre-pop
    const addressFormGroup = this.addressFormGroup(0);
    addressFormGroup.get('buildingNumber').setValue(this.account.user.straddr);
    addressFormGroup.get('buildingName').setValue(this.account.user.building_name);
    addressFormGroup.get('subBuildingName').setValue(this.account.user.abodenumber);
    addressFormGroup.get('street').setValue(this.account.user.street);
    addressFormGroup.get('town').setValue(this.account.user.city);
    addressFormGroup.get('postcode').setValue(this.account.user.zip);
  }

  addressFormGroup(index) {
    return (this.addressFormArray.controls[index]) ? this.addressFormArray.controls[index] : null;
  }

  get addressFormArray(): UntypedFormArray {
    return this.formGroup.get('addresses') as UntypedFormArray;
  }

  addAddress(): void {
    this.addressFormArray.push(this.newAddress());
  }

  newAddress(): UntypedFormGroup {
    return this.formBuilder.group({
      buildingName: ['', [
        //Validators.required
      ]],
      buildingNumber: ['', [
        //Validators.required
      ]],
      subBuildingName: ['', [
        //Validators.required
      ]],
      street: ['', [
        Validators.required
      ]],
      county: ['', [
        //Validators.required
      ]],
      town: ['', [
        Validators.required
      ]],
      postcode: ['', [
        Validators.required
      ]],
      timeAtAddressMonths: ['', [
        Validators.required
      ]],
      timeAtAddressYears: ['', [
        Validators.required
      ]],
      residentialStatus: ['', [
        Validators.required
      ]],
    });
  }

  removeAddress(index) {
    this.addressFormArray.removeAt(index);
  }

  /**
   * Returns true if formcontrol has any errors
   */
  isInvalid(index, fieldName): boolean {
    return this.addressFormArray.at(index).get(fieldName).invalid;
  }

  /**
   * Return true if formcontrol has the specific error.
   */
  hasError(index, fieldName, errorName): boolean {
    return this.addressFormArray.at(index).get(fieldName).hasError(errorName);
  }
}
