import {Component, OnDestroy, OnInit} from '@angular/core';
import {CtaService} from 'src/app/shared/services/cta.service';
import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {Observable, Subscription} from 'rxjs';
import {CtaActionService} from '../../../dashboard/services/cta-action.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IAccount} from 'src/app/shared/models/account';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {CtaCategory} from 'src/app/shared/models/cta/cta-category.enum';
import {AccountService} from 'src/app/shared/services/account.service';
import {map} from 'rxjs/operators';
import {ChangeCtaStatusRequest} from 'src/app/shared/models/cta/change-cta-status-request.interface';
import {CtaStatus} from 'src/app/shared/models/cta/cta-status.enum';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';

@Component({
  selector: 'app-offers-lightbox',
  templateUrl: './offers-lightbox.component.html',
  styleUrls: ['./offers-lightbox.component.scss'],
})
export class OffersLightboxComponent implements OnInit, OnDestroy {

  ctaItem$: Observable<Array<CTA>>;

  private selectAccount: Subscription;
  private account: IAccount;
  /**
   * The caller of this modal can supply a category to filter the CTAs by.
   */
  public filterByCategory: CtaCategory = null;

  constructor(
    private ctaService: CtaService,
    private ctaActionService: CtaActionService,
    accountService: AccountService,
    public activeModal: NgbActiveModal,
  ) {
    this.selectAccount = accountService.getAccount()
      .subscribe((account: IAccount) => {
        this.account = account;
      });
  }

  ngOnInit(): void {
    // This needs to stay in ngOnInit, because it uses the filterByCategory input which is set by the opener of this modal.
    this.ctaItem$ = this.ctaService.get().pipe(
      map(ctas => {
        if (!ctas) {
          return [];
        }

        return ctas.filter((cta: CTA) => {
          // This is only for offers
          if (!cta.is_offer) {
            return false;
          }

          // If we aren't filtering by a category, include everything.
          if (!this.filterByCategory) {
            return true;
          }

          return cta.category_id === this.filterByCategory;
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }

  doAction(cta: CTA) {
    this.close();

    MixpanelService.track('Click smart offer', {cta_id: cta.id, cta_title: cta.title});

    // Record a CLICKED event on the CTA
    const changeCtaStatusRequest: ChangeCtaStatusRequest = {
      cta_users_id: cta.cta_users_id,
      status: CtaStatus.CLICKED
    };

    this.ctaService
      .updateStatus(changeCtaStatusRequest)
      .subscribe(genericErrorObserver);

    this.ctaActionService
      .handleAction(cta.btn_action, this.account);
  }

  close() {
    this.activeModal.close();
  }
}
