import {Injectable} from '@angular/core';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';
import {Chainable} from '../models/chainable';
import {map, take} from 'rxjs/operators';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FastPerksComponent} from 'src/app/modules/iao-chain/fast-perks/fast-perks.component';
import {CtaActionService} from 'src/app/modules/dashboard/services/cta-action.service';


@Injectable({
  providedIn: 'root',
})
export class FastPerksService implements Chainable {

  private account: IAccount;
  public fastPerksUrl$ = null;

  constructor(
    private accountService: AccountService,
    private ctaActionService: CtaActionService,
    private modalService: NgbModal,
  ) {}

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService.getAccount()
        .pipe(take(1))
        .subscribe((account: IAccount) => {
          this.account = account;
          this.fastPerksUrl$ = this.ctaActionService.getAffiliateLink('https://<product_name>.fast-perks.com/signin-auto?emailaddress=<email>', account);
          resolve();
        });
    });
  }

  canDisplay() {
    // For some reason, this service gets called during logout sometimes.
    if (!this.account) {
      return false;
    }

    return (this.account.settings.hasFastPerksMembership === true
      && this.account.settings.hasSeenOfferFastPerks !== true);
  }

  run() {
    const modalRef = this.modalService.open(FastPerksComponent, { size: 'xl'});

    const fastPerksUrl = this.ctaActionService
      .getAffiliateLink('https://<product_name>.fast-perks.com/signin-auto?emailaddress=<email>', this.account);

    if (modalRef) {
      modalRef.componentInstance.product = this.account.product;
      modalRef.componentInstance.fastPerksUrl = fastPerksUrl;
    }

    return modalRef.result;
  }

  onIaoClose() {
    this.accountService
      .updateSetting('hasSeenOfferFastPerks', true)
      .subscribe(genericErrorObserver);
  }
}
