<div class="cta-grid">
  <div class="row" [@listAnimation]="(ctaItem$ | async)?.length" (@listAnimation.done)="animationDone($event)">
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 py-3" *ngFor="let cta of (ctaItem$ | async); let i = index"
         [attr.data-index]="i"
         [ngClass]="{'second-cta-item': i == 1}">

      <app-cta-item [cta]="cta"></app-cta-item>

    </div>
  </div>
</div>
