/**
 * Should be returned from the Promise returned by Chainable.run().
 * @see Chainable
 */
export enum IaoModalResultEnum {
  /**
   * The modal finished, and we can continue to show the next in the chain
   */
  ContinueChain,

  /**
   * The modal finished, and is telling us we should NOT show the next IAO modal
   */
  StopChain,
}
