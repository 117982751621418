import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {IAccount} from 'src/app/shared/models/account';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';

import {take} from 'rxjs/operators';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filterNilVal} from 'src/app/shared/utils/filters';
import {CarGiantComponent} from 'src/app/modules/offers/components/car-giant/car-giant.component';
import {AccountService} from 'src/app/shared/services/account.service';


@Injectable({
  providedIn: 'root'
})
export class CarGiantPopService implements Chainable {

  private account: IAccount;

  constructor(
    private store: Store,
    private modalService: NgbModal,
    private accountService: AccountService
  ) {}

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.store.select(SessionState.getAccount)
        .pipe(
          filterNilVal(),
          take(1)
        )
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return (this.account.user.afid === 562888 && !this.account.settings.hasSeenOfferCarGiant);
  }

  run(): Promise<IaoModalResultEnum> {
    const modalRef = this.modalService.open(CarGiantComponent, {size: 'md'});

    if (modalRef) {
      modalRef.componentInstance.guid = this.account.user.guid;
      modalRef.componentInstance.site = this.account.product.site;
    }

    return modalRef.result;
  }

  onIaoClose() {
    this.accountService
      .updateSetting('hasSeenOfferCarGiant', true)
      .subscribe(() => {});
  }
}
