import {Component, OnInit} from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {CreditReport} from 'src/app/shared//models/credit-report';
import {RouterOutlet} from '@angular/router';

@Component({
  selector: 'app-summary-payment-history',
  templateUrl: './summary-payment-history.component.html',
  styleUrls: ['./summary-payment-history.component.scss'],
})
export class SummaryPaymentHistoryComponent implements OnInit {

  constructor(
    private reportService: CreditReportService,
    private routerOutlet: RouterOutlet,
  ) { }

  public financialAccounts : Array<any>;

  /**
   * Sort missed payments on top, non-"up to date" statuses below it, with healthy
   * accounts last.
   * @param a
   * @param b
   */
  private static sortAccounts(a, b): number {
    if (a.missedPayments > b.missedPayments) {
      return -1;
    } else if (a.missedPayments < b.missedPayments) {
      return 1;
    } else if (a.status != 'Up to date' && b.status == 'Up to date') {
      return 1;
    } else if (a.status == 'Up to date' && b.status != 'Up to date') {
      return -1;
    }
    return 0;
  }

  ngOnInit() {
    this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.refreshStats();
      });
  }

  /**
   * Refresh displayed stats
   */
  private refreshStats() {
    let obj = this.reportService.getFinancialAccounts();
    obj = Object.keys(obj).map(key => obj[key]);
    obj = obj.sort(SummaryPaymentHistoryComponent.sortAccounts);
    obj = obj.slice(0, 3);

    this.financialAccounts = obj;
  }
}
