import { Component, OnInit } from '@angular/core';
import {DisputesService} from 'src/app/shared//services/disputes.service';

@Component({
  selector: 'app-summary-disputes',
  templateUrl: './summary-disputes.component.html',
  styleUrls: ['./summary-disputes.component.scss'],

})
export class SummaryDisputesComponent implements OnInit {

  public disputes : Array<any>;

  private LIST_LIMIT = 5;

  constructor(
    private disputesService : DisputesService
  ) { }

  ngOnInit() {
    this.disputesService
      .get()
      .subscribe((resp: Array<any>) => {
        const disputes = Object.keys(resp).map(key => resp[key]);

        this.disputes = disputes
          .filter(val => val['meta'].is_open)
          .slice(0, this.LIST_LIMIT);
      });
  }
}
