export enum RegisterResult {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  KBA = 'KBA',
  EXCEPTION = 'EXCEPTION',
  UNKNOWN = 'UNKNOWN',
  THIN_FILE = 'THIN FILE',

  KBA_FAILED = 'KBA FAILED'
}
