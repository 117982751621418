<div class="container">

  <div class="pt-5 pb-5">
    <a routerLink="/auth/login">
      <img class="mx-auto d-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" />
    </a>
  </div>

  <div class="d-flex justify-content-center ">

      <div class="card flex-grow-1 mw-500">
        <div class="card-header border-0">
          <h6>Reset Password</h6>
        </div>

        <div class="card-body">
          <form (ngSubmit)="sendPasswordResetRequest()" [formGroup]="passwordResetForm">

            <input type="hidden" name="_token" value="Nkvjwf3a41PxKZoDypkS91JYJZKg4g4UB6CrQ7RP">

            <div class="row mb-3">
              <label for="email" class="col-sm-5 col-form-label text-md-right">E-Mail Address</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" formControlName="email" id="email"
                       [ngClass]="{ 'is-invalid': isSubmitted && formControls.email.errors }">
                <div class="invalid-feedback" *ngIf="formControls.email.touched && formControls.email.hasError('required')">Required</div>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-sm-7 offset-sm-5 text-center">
                <button type="submit" class="btn btn-primary rounded-pill">
                  Send Password Reset Link
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>

</div>
