export enum Confidence {
    HIGH_CONFIDENCE = 1,
    CONFIDENT = 2,
    NOT_VERIFIED = 3
}

export interface BreachConfidence {
  name: string;
  value: number;
}

export interface RiskCategoryFlags {
  account: boolean;
  company: boolean;
  emails: boolean;
  financial_info: boolean;
  geolocation: boolean;
  infected_user: boolean;
  ips: boolean;
  passwords: boolean;
  phone_numbers: boolean;
  pii: boolean;
  social: boolean;
  usernames: boolean;
}

export interface DarkwebBreach {
  breach_date: string;
  confidence: BreachConfidence;
  description: string;
  media_urls: Array<string>;
  site: string;
  site_description: string;
  source_id: number;
  title: string;
  type: string;
  risk_category: RiskCategoryFlags;
}
