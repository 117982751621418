<div class="modal-body">

  <div>
    <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="py-2 text-center">
    <div class="icon-container">
      <img class="my-3 img-fluid justify-content-center align-self-center h-100" src='/assets/images/cta/LGO_Fastperks.png' />
    </div>

    <div class="form-container">

      <h4 class="py-3">Free Welcome Bonus</h4>

      <p>
        We've partnered with Fast-Perks.com to bring you discounts at market leading brands and attractions, saving you money at checkout today.
      </p>

      <img class="my-3 img-fluid justify-content-center align-self-center h-100" src='/assets/images/fastperks/IMG_HeartMan.png' />

      <p>
        For a limited time only, activate your perk portal at zero cost to claim the best discounts and offers at high street brands,
        national attractions, monthly subscriptions and much more.
      </p>

      <div>
        <div class="px-lg-3 px-md-2 py-3" *ngIf="fastPerksUrl">
          <button
            class="btn btn-primary btn-lg btn-block cta-button" (click)="openSite()" id="fast-perks-linkout">
            Activate Perk Portal
          </button>
        </div>
    </div>

  </div>
</div>
