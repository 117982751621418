import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';
import {CtaActionService} from 'src/app/modules/dashboard/services/cta-action.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-nav-fast-perks',
  templateUrl: './nav-fast-perks.component.html',
  styleUrls: ['../../nav.component.scss'],
})
export class NavFastPerksComponent {

  public fastPerksUrl$: Observable<string>;

  constructor(
    accountService: AccountService,
    ctaActionService: CtaActionService,
  ) {
    this.fastPerksUrl$ = accountService.getAccount()
      .pipe(
        map(acct => ctaActionService.getAffiliateLink('https://<product_name>.fast-perks.com/signin-auto?emailaddress=<email>', acct))
      );
  }
}
