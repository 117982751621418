import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrandService} from './shared/services/brand.service';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {AccountService} from './shared/services/account.service';
import {AuthService} from './core/services/auth.service';
import {MixpanelService} from './shared/mixpanel/mixpanel.service';
import {ExitSurveyService} from './modules/exit-survey/exit-survey.service';
import {DarkWebService} from 'src/app/modules/dark-web/services/dark-web.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private selectAccount: Subscription;

  constructor(
    private brandService: BrandService,
    private titleService: Title,
    private accountService: AccountService,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
    exitSurveyService: ExitSurveyService,
    darkwebService: DarkWebService,
    creditReportService: CreditReportService,
  ) {
    exitSurveyService.listenForIaoFinish();

    // Detect when we log in/out.  When we login, fetch core data down.
    authService.loginStateChanges().subscribe(val => {
      if (val) {
        // The dashboard needs this data for the darkweb summary box
        darkwebService.fetchSummary().subscribe(genericErrorObserver);
        creditReportService.fetchAlerts().subscribe(genericErrorObserver);
      }
    });
  }

  /**
   * Try and fetch the account object into the state.  This will either succeed and populate
   * the state with the account object, updating all query subscriptions, or it will fail
   * and do nothing.
   * @private
   */
  private loadAccount(): void {
    if (this.authService.isAuthenticated()) {
      this.accountService.fetch().subscribe({error: () => console.log('Not logged in on initial account load')});
    }
  }

  ngOnInit(): void {
    this.setTitleTagByDomain();
    this.loadAccount();
    this.mixpanelService.initMixpanel();
    this.mixpanelService.watchRoutingEvents();
  }

  private setTitleTagByDomain(): void {
    // Something like ukcreditratings
    let brand = this.brandService.get();

    // Into Ukcreditratings
    brand = brand.charAt(0).toUpperCase() + brand.slice(1);

    // Change <title> tag
    this.titleService.setTitle(brand);
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }

  // private watchRoutingEvents() {
  //   this.router.events.subscribe((event: Event) => {
  //     switch (true) {
  //       case event instanceof NavigationStart: {
  //         this.loadIndicatorService.push(event.composedPath());
  //         break;
  //       }
  //
  //       case event instanceof NavigationEnd:
  //       case event instanceof NavigationCancel:
  //       case event instanceof NavigationError: {
  //         this.loadIndicatorService.push(event.composedPath());
  //         break;
  //       }
  //       default: {
  //         break;
  //       }
  //     }
  //   });
  // }
}
